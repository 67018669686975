import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import TopLine from '../../../components/TopLine/topLine'
import { getDescription } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import Header from '../components/Header'
import { Text } from '../components/style'
import Filters from '../Resource/Filters'
import { Wrap } from '../Resource/style'
import { Container } from './style'
import Table from './Table'

const ResourceTable = observer(() => {
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const [active, setActive] = useState(0)

  const isGuides = params.type === 'guides'

  const jurs = store.user?.[0]?.resource_jurisdictions.map((item) => ({
    id: item,
    name: getDescription(store.jurs, item),
  }))

  const resources = store.resources?.filter((item) =>
    store.user?.[0]?.resource_guides.includes(item.guide_name_id)
  )

  useEffect(() => {
    if (!store.resourceTariffInfo) store.getResourceTariffInfo()
  }, [])

  useEffect(() => {
    if (!store.company?.[0].have_access_to_resource)
      history.push('/edit-profile')
  }, [store.resourceTariffInfo])

  useEffect(() => {
    if (!store.jurs?.length) store.loadSelect('companyOptions')
    if (!store.resources?.length) store.getResourceTopic()
    if (!store.resourceFilters) store.getResourceFilters()
  }, [])

  return (
    <>
      <TopLine />
      <Wrap>
        <Container>
          <Header onTabChange={(key) => setActive(key)} active={active} />
          {jurs && resources && (
            <Filters tabKey={active} jurs={jurs} resources={resources} />
          )}
          {isGuides && <Text>Select a row to view more details</Text>}
          {isGuides
            ? store.resourcesCurrentTopic && <Table active={active} />
            : store.resourcesTable?.length > 0 && <Table active={active} />}
        </Container>
      </Wrap>
    </>
  )
})

export default ResourceTable
