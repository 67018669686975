import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getDescription } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import Title from '../../components/Title'
import Item from './Item'

const Right = observer(({ item }) => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState(null)

  const isGuides = params.type === 'guides'

  const items = store.resourcesCurrentTopic?.rows.filter(
    (item_curr) =>
      item_curr.jurisdiction ===
      (isGuides ? item.jurisdiction : +params.selectId)
  )

  const isMoreThanOne = items?.length > 1

  useEffect(() => {
    setActive(null)
    if (items?.length > 0) setActive(items[0].id)
  }, [item, store.resourceFiltered])

  useEffect(() => {
    if (
      isGuides &&
      !store.resourcesCurrentTopic?.rows.find((item) => item.id === +params.id)
    )
      history.push('/resource/table/jurisdictions')
  }, [])

  const row = isGuides ? item : item.rows[0]

  if (!store.resourceFilters) return null

  return (
    <div>
      <Title
        title={item.name || store.resourcesCurrentTopic?.name}
        jurisdiction={getDescription(store.jurs, row.jurisdiction)}
        showButton
      />
      {items?.map((currItem) => (
        <Item
          item={currItem}
          topic
          active={active === currItem.id}
          setActive={(id) => setActive(id)}
          isMoreThanOne={isMoreThanOne}
        />
      ))}
    </div>
  )
})

export default Right
