import PropTypes from 'prop-types'
import React from 'react'
import Item from './item'
import { Top, Total, Wrap } from './summary.style'

const Summary = ({ data, isResource }) => {
  return (
    <Wrap>
      <Top>Order Summary</Top>
      {data?.summary?.map((item, index) => (
        <Item key={index} item={item} isResource={isResource} />
      ))}

      <Total>
        <div>Order Total</div>
        <div>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(data?.total)}
        </div>
      </Total>
    </Wrap>
  )
}

Summary.propTypes = {
  data: PropTypes.object,
  paymentPeriod: PropTypes.string,
}

export default Summary
