import React, { useState } from 'react'
import {
  BackButton,
  Container,
  Description,
  Wrap,
} from '../Agreement/agreement.style'
import Logo from '../../../components/common/Logo/logo'
import { Title } from '../authForm.style'
import { useForm } from 'react-hook-form'
import { Grid } from '../AddMember/Form/form.style'
import Button from '../../../components/common/Button/button'
import { Link, useLocation } from 'react-router-dom'
import { api } from '../../../api'
import { message } from 'antd'
import {
  getError,
  resetPasswordValidationBlock,
} from '../../../helpers/helpers'
import { useCookies } from 'react-cookie'
import Input from '../../../components/common/Input'

const Step3 = () => {
  const location = useLocation()

  const {
    control,
    handleSubmit,
    errors,
    getValues,
    watch,
    clearErrors,
  } = useForm({
    mode: 'onChange',
  })
  const [success, setSuccess] = useState(false)
  const [cookies] = useCookies()

  const token = location.search?.split('=')[1]

  const watchAllFields = watch()

  const onSubmit = (values) => {
    api('/password_reset/validate_token/', { token }, 'POST', true).then(
      (data) => {
        if (!data.errors) {
          api(
            '/password_reset/confirm/',
            {
              token,
              password: values.password,
            },
            'POST',
            true,
          ).then((data) => {
            if (!data.errors) {
              setSuccess(true)
            } else {
              message.error(getError(data))
            }
          })
        } else {
          message.error(getError(data))
        }
      },
    )
  }

  return (
    <Container>
      <Wrap>
        <Logo large/>
        <Title
          style={{
            borderBottom: '1px solid rgba(196, 202, 204, 0.6)',
            paddingBottom: '.7em',
          }}
        >
          Reset Your Password
        </Title>
        <Description
          style={{
            fontFamily: 'var(--regular)',
            fontSize: '.875em',
            textAlign: 'left',
            marginBottom: '2em',
          }}
        >
          Thank you for confirming your account. Please reset your password
          using the fields below. You must fulfill the password criteria to
          successfully reset your password.
        </Description>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            {watchAllFields && (
              <>
                <Input
                  name={'password'}
                  type={'password'}
                  label={'New Password'}
                  control={control}
                  error={errors && errors.password && 'Enter valid password'}
                  handleChange={(value) => {
                    if (
                      value &&
                      getValues('password_confirm') &&
                      value === getValues('password_confirm')
                    ) {
                      clearErrors(['password_confirm'])
                    }
                  }}
                  validation={{
                    required: true,
                    validate: (value) => value.length >= 8,
                  }}
                  showErrorText={false}
                  success={getValues('password')?.length >= 8}
                  smallLabel
                  transparent
                />
                <Input
                  name={'password_confirm'}
                  type={'password'}
                  label={'Confirm New Password'}
                  control={control}
                  error={
                    (getValues('password_confirm') &&
                      getValues('password') &&
                      getValues('password') !==
                      getValues('password_confirm')) ||
                    errors.password_confirm && 'Password doesn\'t match'
                  }
                  validation={{
                    validate: (value) => value === getValues('password'),
                    required: true,
                  }}
                  showErrorText={false}
                  success={
                    getValues('password') &&
                    getValues('password_confirm') &&
                    !errors.password_confirm &&
                    getValues('password') === getValues('password_confirm')
                  }
                  smallLabel
                  transparent
                />
              </>
            )}
          </Grid>
          {resetPasswordValidationBlock(getValues, success, errors)}
          <Button
            text={success ? 'Sign In' : 'Update Password'}
            to={success && '/login'}
            style={{ margin: '0 auto' }}
          />
        </form>
        <BackButton
          to={cookies.pbm_token ? '/password-and-security' : '/login'}
          as={Link}
        >
          &lt;&lt; {cookies.pbm_token
          ? 'Return to Settings'
          : 'Return to Sign In'}
        </BackButton>
      </Wrap>
    </Container>
  )
}

export default Step3
