import styled from 'styled-components'
import {
  Wrap as ButtonWrap,
  WrapWhite as ButtonWrapWhite,
} from '../../../components/common/Button/button.style'

export const Wrap = styled('div')`
  padding-left: 2em;
  padding-top: 50px;
  @media (max-width: 1350px) {
    padding-left: 0;
    padding-top: 2em;
  }
`

export const Reference = styled('div')`
  font-family: var(--semiBold);
  font-size: 1.5em;
  color: var(--blue);
  display: flex;
  align-items: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #c4cacc;
  justify-content: space-between;

  span {
    width: 1em;
    height: 1em;
    border-radius: 100%;
    background-color: var(--yellow);
  }

  ${ButtonWrapWhite} {
    height: 34px;
    font-size: 14px;
    padding: 0 15px;

    img {
      margin-left: 10px;
      margin-right: 0;
      height: 50%;
    }
  }
`

export const Label = styled('div')`
  font-family: var(--semiBold);
  font-size: 0.875em;
  color: var(--text);
  margin-bottom: 0.5em;
`

export const Text = styled('div')`
  font-family: var(--regular);
  font-size: 0.875em;
  color: var(--text);
  letter-spacing: 0.3px;

  pre {
    font-family: var(--regular);
  }

  b {
    font-family: var(--bold);
  }

  p {
    margin: 0;
  }

  a {
    color: var(--green);
    text-decoration: underline;
  }

  .file-deleted {
    text-decoration: underline;
    color: #c4c4c4;
  }

  mention {
    color: var(--orange);
    font-family: var(--bold);
  }
`

export const Row = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const InputsRow = styled('div')`
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) 11em;
  gap: 1.438em;
  margin-top: 2em;
  align-items: end;
`

export const Activity = styled('div')`
  margin-top: 0;
  background-color: #f4f6fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.625em;
  padding: 0 1em;
`

export const ActivityName = styled('span')`
  font-family: var(--semiBold);
  color: var(--text);
`

export const ActivityItem = styled('span')`
  color: var(--green);
  text-decoration: underline;
  font-family: var(--medium);
`

export const Block = styled('div')`
  border-bottom: 1px solid ${(props) => (props.bordered ? '#C4CACC' : '#fff')};
`

export const DocumentWrap = styled('div')`
  font-family: var(--open-sans-semiBold);
  font-size: 0.875em;
  color: var(--text);
  padding: 1em;
  background-color: ${(props) => (props.grey ? '#f4f6f6' : '#fff')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DocumentRight = styled('div')`
  display: flex;

  div,
  a {
    display: flex;
    align-items: center;
    color: var(--text);
    font-style: italic;
    cursor: pointer;
    font-family: var(--regular);

    &:first-child {
      margin-right: 2em;
    }

    img {
      margin-right: 0.5em;
      height: 1em;
    }
  }
`

export const NotChoosen = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--light);
  color: #000;
  font-size: 2em;
  text-align: center;
  line-height: 1.188em;
  margin-top: 40px;

  img {
    height: 457px;
    margin-bottom: 20px;
  }
`

export const DocumentsPlaceholder = styled('div')`
  color: var(--text);
  font-size: 1em;
  font-family: var(--regular);
  opacity: 0.6;
`

export const Read = styled(ButtonWrap)``

export const LineOfBusiness = styled('div')`
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    margin-left: 7px;
  }
`

export const DueDate = styled('div')`
  position: relative;
  width: 361px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 50px;
  border-radius: 5px;
`

export const Inputs = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 15px;
`
