import React, { useEffect, useState } from 'react'
import {
  Activity,
  Block,
  DocumentsPlaceholder,
  Label,
  Text,
} from './right.style'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../mobx-store/context'
import { Spin } from 'antd'
import Moment from 'react-moment'
import CustomPagination from '../../../components/common/Pagination/pagination'
import parse from 'html-react-parser'

const Activities = observer(({ legislative }) => {
  const store = useStore()
  const [current, setCurrent] = useState(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    store.loadActivity(legislative, 1, () => setLoading(false))
  }, [legislative])

  if (loading) return <Spin/>

  if (!store.activity.results) return null

  const activity = store.activity.results

  return (
    <Block style={{ marginTop: '1em' }}>
      <Label>Activity</Label>
      {!activity.length &&
      <DocumentsPlaceholder
        style={{
          marginTop: 15,
          borderBottom: '1px solid #bbb',
          paddingBottom: 15,
        }}
      >
        There are no activities associated with this item yet.
      </DocumentsPlaceholder>}

      {activity.map(({ created, activity_type, member, html_text }, index) => (
        <Activity
          key={created}
          style={{ backgroundColor: index % 2 !== 0 && '#fff' }}
        >
          <Text>{parse(html_text)}</Text>
          <Text style={{ fontSize: '.75em', color: '#7D8587' }}>
            <Moment format="MMM D, hh:mm A" withTitle>
              {created}
            </Moment>
          </Text>
        </Activity>
      ))}
      <CustomPagination
        total={store.activity.count}
        pageSize={'24'}
        current={current}
        arrows
        onChange={(page) => {
          store.loadActivity(legislative, page)
          setCurrent(page)
        }}
      />
    </Block>
  )
})

export default Activities
