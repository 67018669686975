import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import TopLine from '../../../components/TopLine/topLine'
import { useStore } from '../../../mobx-store/context'
import Header from '../components/Header'
import { Layout } from '../components/style'
import { Container, Wrap } from '../Resource/style'
import Left from './Left'
import Right from './Right'

const TopicsJursList = observer(() => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState(null)
  const [loading, setLoading] = useState(false)

  const isGuides = params.type === 'guides'

  useEffect(() => {
    if (store.resourcesCurrent) setActive(store.resourcesCurrent)
  }, [])

  useEffect(() => {
    if (!store.jurs) store.loadSelect('companyOptions')
    if (!store.resourceFiltered)
      store.getJurisdiction(
        +params.jurisdictionID || +params.selectId,
        (data) => {
          if (!data.length) history.push('/resource/table/jurisdictions')
        }
      )
    if (!store.resourceFilters) store.getResourceFilters()
    if (!store.resources) store.getResourceTopic()
    if (!store.resourcesCurrentTopic)
      store.getResourceTopic(
        isGuides ? +params.selectId : +params.id,
        null,
        () => {
          history.push('/resource/table/jurisdictions')
        }
      )
  }, [])

  const handleSetLoading = (value) => setLoading(value)

  const handleSetActive = (value) => setActive(value)

  return (
    <>
      <TopLine />
      <Wrap>
        <Container>
          <Header goBack />
          <Layout>
            {store.resourceFiltered && store.resourcesCurrentTopic && (
              <Left
                active={active}
                setActive={handleSetActive}
                setLoading={handleSetLoading}
                loading={loading}
              />
            )}

            {active && <Right item={active} loading={loading} />}
          </Layout>
        </Container>
      </Wrap>
    </>
  )
})

export default TopicsJursList
