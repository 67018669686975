import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import { useStore } from '../../../../mobx-store/context'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container'
import NotificationOptions from './notificationOptions'
import NotificationsTime from './notificationsTime'
import { Row, UnderTitle } from './style'

const EmailSettings = observer(() => {
  const store = useStore()
  const { control, handleSubmit, reset } = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!store.user.length) {
      store.getUser()
    } else {
      reset(store.user[0])
    }
  }, [store.user])

  if (!store.user.length) return null

  const onSubmit = (values) => {
    setLoading(true)

    api(`/users/${store.user[0].id}/`, values, 'PATCH').then((data) => {
      setLoading(false)
      if (data.error) {
        console.log('error')
      } else {
        store.setSingleValue('user', [
          {
            ...store.user[0],
            notifications_compliance_source:
              data.notifications_compliance_source,
            notifications_when: data.notifications_when,
          },
        ])
      }
    })
  }

  return (
    <PageWrap>
      <PageTitle>Email Notification Settings</PageTitle>
      <UnderTitle>
        PBMSource can send email notifications so you don’t miss a beat. You can
        also decide if there are any additional updates you’d like to receive.
      </UnderTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <NotificationsTime control={control} />

        <NotificationOptions control={control} />

        <Row style={{ paddingBottom: 50 }}>
          <Button text={'Save Preferences'} type={'green'} loading={loading} />
        </Row>
      </form>
    </PageWrap>
  )
})

export default EmailSettings
