import React, { useEffect, useState } from 'react'
import PageWrap from '../../components/Container'
import { PageTitle, Row } from '../../components/common/style'
import { useForm } from 'react-hook-form'
import { Form } from './style'
import Button from '../../../../components/common/Button/button'
import { getDirtyFields, getInputError } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { api } from '../../../../api'
import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import Input from '../../../../components/common/Input'

const BusinessInfoProfile = observer(() => {
  const store = useStore()
  const [loading, setLoading] = useState(false)
  const {
    control,
    handleSubmit,
    errors,
    reset,
    formState: { dirtyFields },
  } = useForm()

  const companyId = store.company[0].id

  const getUserBody = (values) => {
    let result = {}
    const keysToUse = ['first_name', 'last_name']

    for (let key in values) {
      if (keysToUse.includes(key)) {
        result[key] = values[key]
      }
    }

    return result
  }

  const getCompanyBody = (values) => {
    if (!values) return {}

    delete values.first_name
    delete values.last_name

    return values
  }

  const onSubmit = (values) => {
    setLoading(true)

    const dirtyValues = getDirtyFields(dirtyFields, values)

    api(`/users/${store.user?.[0]?.id}/`, getUserBody(dirtyValues), 'PATCH').
      then((data) => {

        store.setSingleValue('user', [data])

        return api(
          `/companies/${companyId}/`,
          getCompanyBody(dirtyValues),
          'PATCH',
        )
      }).
      then((data) => {
        setLoading(false)
        if (data) {
          if (!data.errors) {
            store.setSingleValue('company', [data])
            message.success('Data saved')
          } else {
            message.error(getInputError(data))
          }
        }
      }).
      catch(() => {
        setLoading(false)
      })
  }

  const config = {
    control,
    smallLabel: true,
    validation: { required: true },
  }

  useEffect(() => {
    if (!store.user.length) {
      store.getUser()
    }
    if (!store.states.length) {
      store.loadSelect('states')
    }
  }, [])

  useEffect(() => {
    reset({
      ...store.company[0],
      first_name: store.user[0]?.first_name,
      last_name: store.user[0]?.last_name,
    })
  }, [store.user, store.company])

  return (
    <PageWrap>
      <PageTitle>Business Information</PageTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...config}
          label={'Business Name'}
          error={getInputError(errors?.name, 'Enter valid business name')}
          name={'name'}
          placeholder={'Business Name'}
        />
        <Input
          {...config}
          label={'Phone'}
          name={'phone_number'}
          type={'phone'}
          error={getInputError(
            errors?.phone_number,
            'Enter valid phone number',
          )}
        />
        <Input
          {...config}
          label={'Street Address'}
          error={getInputError(errors?.address1, 'Enter valid street address')}
          name={'address1'}
          placeholder={'Address Line 1'}
        />
        <Input
          {...config}
          label={'Primary Contact Name'}
          error={getInputError(errors?.first_name, 'Enter valid first name')}
          name={'first_name'}
          placeholder={'First Name'}
        />
        <Input
          {...config}
          label={''}
          name={'address2'}
          error={getInputError(errors?.address2, 'Enter valid city')}
          placeholder={'City'}
        />
        <Input
          {...config}
          label={''}
          name={'last_name'}
          error={getInputError(errors?.last_name, 'Enter valid last name')}
          placeholder={'Last Name'}
        />

        <Row>
          <Input
            {...config}
            label={'Zip Code'}
            name={'zip'}
            placeholder={'Zip Code'}
            styleContainer={{ marginRight: 13 }}
            error={getInputError(errors?.zip, 'Enter valid zip')}
            mask={'99999'}
            validation={{ required: true, maxLength: 5, minLength: 5 }}
          />
          <Input
            {...config}
            label={'State'}
            name={'state'}
            placeholder={'Select State'}
            type={'select'}
            error={getInputError(errors?.state, 'Choose state')}
            styleContainer={{ marginLeft: 13 }}
            options={store?.states}
            selectVars={{ value: 0, name: 1 }}
          />
        </Row>
        <Button
          type={'green'}
          text={'Save Business Information'}
          loading={loading}
          disabled={loading}
          style={{ marginTop: 'auto', maxWidth: 216, marginLeft: 'auto' }}
        />
      </Form>
    </PageWrap>
  )
})

export default BusinessInfoProfile
