import React from 'react'
import InputCustomCalendar from './InputTypes/input_customCalendar'
import InputDateRange from './InputTypes/input_dateRange'
import InputEmail from './InputTypes/input_email'
import InputFiles from './InputTypes/input_files'
import InputMentions from './InputTypes/input_mentios'
import InputMultiselect from './InputTypes/input_multiselect'
import InputPassword from './InputTypes/input_password'
import InputPhone from './InputTypes/input_phone'
import InputSelect from './InputTypes/input_select'
import InputText from './InputTypes/input_text'
import Textarea from './InputTypes/textarea'

export const inputTypes = (props) => {
  return {
    text: <InputText {...props} />,
    email: <InputEmail {...props} />,
    textarea: <Textarea {...props} />,
    date: <InputCustomCalendar {...props} />,
    date_range: <InputDateRange {...props} />,
    file: <InputFiles {...props} />,
    mention: <InputMentions {...props} />,
    phone: <InputPhone {...props} />,
    password: <InputPassword {...props} />,
    multiSelect: <InputMultiselect {...props} />,
    select: <InputSelect {...props} />,
  }
}
