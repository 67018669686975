import React, { useEffect, useState } from 'react'
import Modal from '../../../../../components/common/Modal'
import { Header, Name, Title } from './style'
import { DatePicker } from 'antd'
import Table from './Table'
import { api } from '../../../../../api'

const { RangePicker } = DatePicker;

const ActivityModal = ({ userId, userName, onClose }) => {
  const [data, setData] = useState();
  const [date, setDate] = useState();
  const [ordering, setOrdering] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const showActivity = (
    userId,
    date,
    ordering = null,
    pageNumber,
    pageSizeNumber
  ) => {
    setLoading(true);

    const url = date
      ? `/user_activity/?date_from=${date[0]}&date_to=${date[1]}&`
      : `/user_activity/?`;

    api(
      `${url}user=${userId}&page_size=${pageSizeNumber || pageSize}&page=${
        pageNumber || page
      }&ordering=${ordering || ""}`,
      {},
      "GET"
    ).then((data) => {
      if (!data.errors) {
        setData(data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    showActivity(userId);
  }, []);

  return (
    <Modal style={{ padding: "30px 80px" }} onClose={onClose}>
      <Title>Activity Log</Title>
      <Header>
        <Name>{userName}</Name>
        View Activity from
        <RangePicker
          onChange={(date, dateString) => {
            setDate(date ? dateString : null);
            setPage(1);
            showActivity(userId, dateString, ordering, 1);
          }}
          style={{ marginLeft: 15 }}
        />
      </Header>
      <Table
        data={data}
        onPageChange={(page) => {
          setPage(page);
          showActivity(userId, date, ordering, page);
        }}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
          setPage(1);
          showActivity(userId, date, ordering, 1, pageSize);
        }}
        pageSize={pageSize}
        page={page}
        loading={loading}
        handleFetch={(ordering) => {
          showActivity(userId, date, ordering);
          setOrdering(ordering);
        }}
      />
    </Modal>
  );
};

export default ActivityModal;
