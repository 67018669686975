import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Moment from 'react-moment'
import Button from '../../../components/common/Button/button'
import Input from '../../../components/common/Input'
import { Label } from '../../../components/common/Input/input.style'
import Modal from '../../../components/common/ModalNew'
import { useStore } from '../../../mobx-store/context'
import { Cancel } from '../Right/Tasks/Modal/style'
import { inputs } from './inputs'

const TopLine = observer(({ onSubmit, totals }) => {
  const store = useStore()
  const [visible, setVisible] = useState(false)
  const [date, setDate] = useState()
  const [currentDateValue, setCurrentDateValue] = useState(null)
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      jurisdictions: null,
    },
  })

  const showDateRangeModal = () => setVisible(true)

  const onClose = () => {
    if (!date) setValue('today_proximity', null)
    setVisible(false)
  }

  const onChange = (value) => {
    if (value === 'custom') return showDateRangeModal()
    setCurrentDateValue(value)
    handleSubmit(onSubmit)()
    setDate(null)
  }

  const onSave = () => {
    if (!date) return setVisible(false)
    setVisible(false)
    handleSubmit(onSubmit)()
  }

  const onCancel = () => {
    setVisible(false)
    setDate(null)
    setValue('today_proximity', currentDateValue)
  }

  useEffect(() => {
    store.getLegislativeOptions()
  }, [])

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        padding: '0 2.313em',
        gap: 15,
        marginTop: 15,
      }}
    >
      {visible && (
        <Modal onClose={onClose}>
          <Label style={{ marginBottom: 15 }}>Select Date Range</Label>
          <Input
            type='date_range'
            control={control}
            name='date_range'
            handleChange={(value) => setDate(value)}
            isSearchOpened
            noLimit
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 15,
            }}
          >
            <Cancel onClick={onCancel}>Cancel</Cancel>
            <Button text='Save' onClick={onSave} />
          </div>
        </Modal>
      )}
      {inputs(store, totals).map(
        (item) =>
          !item.isHidden && (
            <div>
              <Input
                key={item.key}
                control={control}
                type='select'
                smallLabel
                {...item}
                options={item.options}
                handleChange={onChange}
              />
              {item.name === 'today_proximity' && date && (
                <div
                  style={{
                    fontFamily: 'var(--light)',
                    fontSize: 12,
                    color: 'var(--text)',
                    marginTop: 5,
                    textAlign: 'center',
                  }}
                >
                  <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                  <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                </div>
              )}
            </div>
          )
      )}
    </div>
  )
})

export default TopLine
