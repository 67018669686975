import { CloseCircleFilled } from '@ant-design/icons'
import React from 'react'
import CalendarCustom from '../../Calendar'
import { CustomClose } from '../input.style'

const InputCustomCalendar = ({
  onChange,
  value,
  name,
  placeholder,
  setValue,
  noLimit,
}) => {
  const onReset = () => {
    setValue(name, null)
    onChange(null)
  }

  return (
    <>
      <CalendarCustom
        handleChange={(value) => onChange(value)}
        incomeValue={value}
        placeholder={placeholder}
        noLimit={noLimit}
        noLabel
        noBorder
      />
      <CustomClose className={'ant-select-clear'} onClick={onReset}>
        <CloseCircleFilled />
      </CustomClose>
    </>
  )
}

export default InputCustomCalendar
