import React from 'react'
import { MenuItem, Submenu, User, UserWrap } from '../topLine.style'
import user from '../../../assets/images/user.svg'
import { Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

const userDropdown = [
  {
    key: 1,
    title: 'My Settings',
    to: '/edit-profile',
  },
  {
    key: 2,
    title: 'Log Out',
    to: '/login',
    type: 'logout',
  },
]

const UserItem = observer(({ isHome, cookies, store, closeMobileMenu }) => {
  const history = useHistory()

  return <User>
    <UserWrap
      onClick={() => {
        history.push(
          isHome
            ? cookies.get('pbm_token')
              ? '/edit-profile'
              : '/login'
            : '/edit-profile',
        )
        closeMobileMenu()
      }
      }
    >
      <img src={user} alt={'user-icon'}/>
      {!store.user.length > 0 && !isHome && <Spin/>}
      {isHome && !Object.keys(store.user).length
        ? 'Login'
        : (store?.user[0]?.first_name || '') +
        ' ' +
        (store?.user[0]?.last_name || '')}
    </UserWrap>
    {Object.keys(store.user).length > 0 && (
      <Submenu>
        {userDropdown.map(({ key, type, to, title, disabled }) => (
          <MenuItem
            key={key}
            to={to}
            onClick={() => {
              if (type === 'logout') {
                cookies.remove('pbm_token')
                store.reset()
              }
            }}
            disabled={disabled}
            style={{
              padding: '1em 2em',
              marginLeft: 0,
              height: 30,
            }}
          >
            {title}
          </MenuItem>
        ))}
      </Submenu>
    )}
  </User>
})

export default UserItem
