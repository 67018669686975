import styled from 'styled-components'

export const Container = styled('div')`
  display: flex;
  justify-content: center;
  padding: 80px 15px;
`

export const Wrap = styled('div')`
  min-height: 336px;
  border-radius: 12px;
  background-color: #cfeaea;
  width: 1260px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  padding-right: 100px;
  align-items: center;
  img {
    transform: scale(1.05) translate(50px, 15px);
  }
  @media (max-width: 1200px) {
    img {
      width: 100%;
    }
  }
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column-reverse;
    padding: 50px;
    gap: 0;
    min-width: auto;
    width: 100%;
    img {
      width: 80%;
      transform: none;
      margin-top: 25px;
    }
  }
`

export const Title = styled('div')`
  font-family: var(--semiBold);
  font-size: 40px;
  color: var(--text);
`

export const Description = styled('div')`
  font-family: var(--regular);
  font-size: 18px;
  color: var(--text);
  max-width: 480px;
  margin-top: 15px;
  margin-bottom: 20px;
`
