export const trackingAdditionalInputs = (store) => [
  {
    name: 'topics',
    label: 'Assign By Topic',
    type: 'multiSelect',
    validation: { required: false },
    transparent: true,
    placeholder: 'All Topics in My Plan',
    options: 'topicsMembers',
    isHidden: false,
  },
  {
    name: 'jurisdictions',
    label: 'Assign By Jurisdiction',
    type: 'multiSelect',
    validation: { required: false },
    transparent: true,
    placeholder: 'All Jurisdictions in My Plan',
    options: 'jursMembers',
    showSearch: true,
    isHidden: false,
  },
  {
    name: 'line_of_business',
    label: 'Assign By Line of Business',
    type: 'multiSelect',
    validation: { required: false },
    transparent: true,
    placeholder: 'Line of Business',
    options: 'lineOfBusinessesMembers',
    isHidden: store?.company?.length === 0 || !store.company[0].is_premium,
  },
]

export const resourceAdditionalInputs = () => [
  {
    name: 'resource_guides',
    label: 'Assign By Guide',
    type: 'multiSelect',
    validation: { required: false },
    transparent: true,
    placeholder: 'All Guides in My Plan',
    options: 'resourceMembers',
  },
  {
    name: 'resource_jurisdictions',
    label: 'Assign By Jurisdiction',
    type: 'multiSelect',
    validation: { required: false },
    transparent: true,
    placeholder: 'All Jurisdictions in My Plan',
    options: 'resourceJurisdictionsMembers',
    showSearch: true,
  },
]
