import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container'
import TabsNav from '../../components/TabsNav/tabsNav'
import ResourceTab from './Tabs/ResourceTab/resourceTab'
import TrackingTab from './Tabs/Tracking/tracking'

const tabs = {
  tracking: {
    button: 'ComplianceSource',
    component: <TrackingTab />,
    link: '/current-plan/tracking',
  },
  resource: {
    button: 'ReSource',
    component: <ResourceTab />,
    link: '/current-plan/resource',
  },
}

const CurrentPlan = observer(() => {
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState('tracking')

  useEffect(() => {
    setActive(params.type || 'tracking')
  }, [params.type])

  return (
    <PageWrap>
      <PageTitle>Current Plan</PageTitle>
      <TabsNav
        tabs={tabs}
        active={active}
        onRedirectTo={(page) => history.push(`/current-plan/${page}`)}
      />
      {tabs[active].component}
    </PageWrap>
  )
})

export default CurrentPlan
