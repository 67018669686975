import { message, Popover } from 'antd'
import { useGA4React } from 'ga-4-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import showdown from 'showdown'
import { api } from '../../../api'
import flag from '../../../assets/images/flag.svg'
import flagGrey from '../../../assets/images/flag_grey.svg'
import questIcon from '../../../assets/images/help_24px.svg'
import pdf from '../../../assets/images/pdf.svg'
import Button from '../../../components/common/Button/button'
import ButtonOrange from '../../../components/common/ButtonOrange'
import CalendarCustom from '../../../components/common/Calendar'
import Input from '../../../components/common/Input'
import Tabs from '../../../components/common/Tabs/tabs'
import {
  checkForTrial,
  checkRole,
  getDescription,
  getError,
  getStatusColor,
} from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import DeleteModal from './DeleteModal'
import { infoInputs } from './inputs'
import Modal from './Modal'
import MoreInfo from './MoreInfo/moreInfo'
import {
  Block,
  Inputs,
  Label,
  LineOfBusiness,
  Read,
  Reference,
  Row,
  Text,
  Wrap,
} from './right.style'
import { showTabs } from './tabs'
import Tasks from './Tasks'

const converter = new showdown.Converter()

export const labelStyle = {
  color: 'var(--text)',
  fontFamily: 'var(--open-sans-semiBold)',
  fontSize: '0.875em',
}

const Right = observer(
  ({
    data,
    getCurrentLegislative,
    currentPage,
    isMyTasks,
    complianceItem,
    activeTab: mainActiveTab,
    onEdit,
  }) => {
    const [visible, setVisible] = useState()
    const [deleting, setDeleting] = useState(false)
    const [tasks, setTasks] = useState([])
    const history = useHistory()

    const params = useParams()
    const ga4React = useGA4React()

    const { control, reset, getValues, setValue } = useForm({
      defaultValues: {
        status: data.status,
        assign: data.assignee,
      },
    })
    const store = useStore()
    const [loading, setLoading] = useState({
      assignee: false,
      status: false,
      all: false,
      file: false,
      primary_due_date: false,
      deleting: false,
    })
    const [successFile, setSuccessFile] = useState(null)
    const [activeTab, setActiveTab] = useState('details')

    const { id } = data

    const confirmDelete = () => {
      setLoading((prev) => ({
        ...prev,
        deleting: true,
      }))
      api(`/legislative_edit/${id}/`, {}, 'DELETE').then((data) => {
        if (data.errors) return message.error(getError(data))

        store.loadLegislatives()
        store.setSingleValue('currentLegislative', null)
        history.push('/tracking/main')
        setDeleting(false)
        setLoading((prev) => ({
          ...prev,
          deleting: false,
        }))
      })
    }

    const onDelete = () => setDeleting(true)

    useEffect(() => {
      if (
        !store.lineOfBusinesses.length ||
        !store.jurs.length ||
        !store.topics.length
      ) {
        store.loadSelect('companyOptions')
      }

      if (!store.statuses.length) {
        store.loadSelect('currentMemberOptions')
      }
    }, [store])

    const references = [
      {
        key: 0,
        title: 'Reference #',
        description: data.reference_number,
      },
      {
        key: 3,
        title: (
          <LineOfBusiness>
            Line of Business{' '}
            <Popover
              placement='rightTop'
              title={''}
              overlayStyle={{ width: 290, fontFamily: 'var(--medium)' }}
              content={
                'Determining the applicable line of business for a law/requirement often is subject to interpretation. PBMSource has made efforts to assess the appropriate lines of business (which are subject to change as additional information becomes available). This information is provided as guidance only, and you are encouraged to do your own research and assessments and/or contact an attorney or the applicable government agency.If you question or conclude a different line of business(es), let us know so that we can consider updates to the line of business information. Contact us at 888-684-1391 or contact@pbmsource.com.'
              }
              trigger='click'
            >
              <img src={questIcon} alt='question-icon' />
            </Popover>
          </LineOfBusiness>
        ),
        description: getDescription(
          store.lineOfBusinesses,
          data.line_of_business
        )
          .split(',')
          .join('<br />'),
        hide: !store.company[0]?.is_premium,
      },
      {
        key: 1,
        title: 'Topic',
        description: getDescription(store.topics, data.topics)
          .split(',')
          .join('<br />'),
      },
      {
        key: 2,
        title: 'Jurisdiction',
        description: getDescription(store.jurs, data.jurisdiction),
      },
    ]

    const handleChange = (value, type) => {
      setLoading((prev) => ({
        ...prev,
        [type]: true,
      }))

      if (value === 'closed') {
        if (
          store.legislativeTasks?.find((item) => item.status !== 'complete')
        ) {
          setVisible(true)
          setValue('status', data.status)
          return
        }
      }

      api(
        `/legislative/${data.id}/`,
        {
          [type]:
            type === 'primary_due_date'
              ? moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD')
              : isNaN(value)
              ? value
              : parseInt(value),
          status:
            type === 'assignee'
              ? 'in_progress'
              : type === 'status'
              ? value
              : data.status,
        },
        'PATCH'
      ).then((data) => {
        if (!data.errors) {
          store.setCurrentLegislative(data, true)

          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
          })

          setLoading((prev) => ({
            ...prev,
            [type]: false,
          }))
          store.loadActivity(id, 1, null)
        }
      })
    }

    const postFile = (e, taskID, files) => {
      setLoading((prev) => ({
        ...prev,
        file: true,
      }))

      ga4React?.gtag('event', 'file_upload')

      let formData = new FormData()

      formData.append('file', files || e.target.files[0])
      formData.append('legislative', data.id)
      if (taskID) formData.append('task', taskID) // Oleksandr, add optional task_id here

      api('/legislative_file/', formData, 'POST', false, true).then((data) => {
        if (!data.errors) {
          if (!taskID) {
            store.setDocument(data)
            setSuccessFile(data.file_name)
          }

          if (taskID)
            getCurrentLegislative(store.currentLegislative.id, null, null, true)

          store.loadActivity(id, 1, null)
          store.loadDocuments(id, 1)

          setLoading((prev) => ({
            ...prev,
            file: false,
          }))
        } else {
          message.error(getError(data))
        }
        reset({ ...getValues(), file: '' })
      })
    }

    const isAssigneeMember = (id) => {
      return (
        store.members?.find((item) => item.id === id)?.member_type === 'member'
      )
    }

    const getTasks = () => {
      if (!params.id && !complianceItem) return null
      api(`/tasks/?legislative=${id}&page_size=100`, {}, 'GET').then((data) => {
        if (!data.errors) {
          setTasks(data.results)
          store.setSingleValue('legislativeTasks', data.results)
        }
      })
    }

    useEffect(() => {
      reset({
        ...data,
        assignee: isAssigneeMember(data.assignee) ? null : data.assignee,
      })
    }, [data])

    useEffect(() => {
      store.loadActivity(id, 1, null)
      getTasks()
    }, [])

    useEffect(() => {
      setTimeout(() => {
        setSuccessFile(null)
      }, 5000)
    }, [successFile])

    return (
      <Wrap>
        {deleting && (
          <DeleteModal
            onConfirm={confirmDelete}
            name={data?.reference_number}
            onClose={() => setDeleting(!deleting)}
            loading={loading.deleting}
          />
        )}
        {visible && (
          <Modal
            onClose={() => setVisible(false)}
            onConfirm={() => setVisible(false)}
          />
        )}
        <Reference>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 30px',
              gap: 10,
              alignItems: 'center',
            }}
          >
            {data.reference_number}
            <span
              style={{
                backgroundColor: getStatusColor(data?.status, data),
              }}
            />
          </div>
          {checkRole(
            [store.user?.[0]?.member_type],
            ['admin', 'compliance_owner']
          ) && (
            <Button
              text={
                <>
                  Priority{' '}
                  <img
                    src={data?.is_starred ? flag : flagGrey}
                    alt='flag-icon'
                  />
                </>
              }
              type={'white'}
              onClick={() => {
                api(
                  `/legislative/${data?.id}/set_starred/`,
                  { is_starred: !data?.is_starred },
                  'POST'
                ).then((data) => {
                  if (!data.errors) {
                    const mutatedLegislative = {
                      ...store.currentLegislative,
                      is_starred: data[id],
                    }
                    store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
                      page: currentPage,
                      page_size: checkForTrial(store) ? 10 : 20,
                    })
                    store.setSingleValue(
                      'currentLegislative',
                      mutatedLegislative
                    )
                  }
                })
              }}
            />
          )}
        </Reference>
        <Row style={{ marginTop: '1em' }}>
          {references.map(
            ({ key, title, description, hide }) =>
              !hide && (
                <Block key={key} style={{ maxWidth: 200 }}>
                  <Label>{title}</Label>
                  <Text dangerouslySetInnerHTML={{ __html: description }} />
                </Block>
              )
          )}
        </Row>

        <MoreInfo data={data} />

        {data.notes && (
          <Block style={{ marginTop: '1em' }}>
            <Label>Notes:</Label>
            <Text
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(data.notes),
              }}
            />
          </Block>
        )}
        <Block style={{ marginTop: '1em' }}>
          <Label>Summary:</Label>
          <Text
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(data.summary),
            }}
          />
        </Block>

        <Read
          style={{ backgroundColor: 'var(--green)', marginTop: '1em' }}
          icon={pdf}
          href={data?.reference_document}
          as={'a'}
          target={'_blank'}
        >
          <img src={pdf} alt='pdf-icon' />
          View Source
        </Read>

        <Inputs>
          {infoInputs(store, data).map((item) =>
            item.role.includes(store.user[0]?.member_type) ? (
              item.type === 'date' ? (
                <CalendarCustom
                  handleChange={(value) =>
                    handleChange(value, 'primary_due_date')
                  }
                  incomeValue={data?.primary_due_date}
                  loading={loading.primary_due_date}
                  styleDropdown={{ left: 'auto', right: 0, top: 50 }}
                  placeholder={'Select Date'}
                  label={'Primary Due Date'}
                  redCircleDate={data?.compliance_date}
                />
              ) : (
                <Input
                  type={'select'}
                  control={control}
                  name={item.name}
                  placeholder={item.placeholder}
                  allowClear={false}
                  label={item.label}
                  options={item.options}
                  customOptions={item.customOptions}
                  selectVars={item.selectVars}
                  handleChange={(value) => handleChange(value, item.name)}
                  smallLabel
                  transparent
                  showSearch={item.name === 'assignee'}
                />
              )
            ) : (
              <div>
                <Label smallLables>{item.label}</Label>
                <span style={{ fontSize: 14 }}>{item.value}</span>
              </div>
            )
          )}
        </Inputs>

        <Tasks
          data={data}
          getCurrentLegislative={getCurrentLegislative}
          postFile={postFile}
          isMyTasks={isMyTasks}
          currentPage={currentPage}
          loadingFile={loading.file}
          complianceItem={complianceItem}
          tasks={tasks}
          getTasks={getTasks}
          activeTab={mainActiveTab}
        />

        <Tabs
          tabs={[
            {
              key: 'details',
              title: 'Details',
            },
            {
              key: 'comments',
              title: 'Comments',
            },
            {
              key: 'documents',
              title: 'Documents',
            },
            {
              key: 'activity',
              title: 'Activity',
            },
          ]}
          onClick={(key) => setActiveTab(key)}
          active={activeTab}
        />

        {showTabs({
          data,
          control,
          postFile,
          loading,
          labelStyle,
          successFile,
          getTasks,
        })[activeTab].render()}
        {checkRole(
          [store.user?.[0]?.member_type],
          ['admin', 'compliance_owner']
        ) &&
          data.created_by_company === store.company?.[0]?.id && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 30,
              }}
            >
              {!tasks?.length && !data.assignee && (
                <ButtonOrange
                  text='Delete Compliance Item'
                  style={{
                    color: 'var(--text)',
                    fontSize: 16,
                    cursor: 'pointer',
                  }}
                  onClick={onDelete}
                />
              )}

              <ButtonOrange
                text='Edit Compliance Item'
                style={{
                  color: 'var(--text)',
                  fontSize: 16,
                  cursor: 'pointer',
                }}
                onClick={onEdit}
              />
            </div>
          )}
      </Wrap>
    )
  }
)

export default Right

export const getString = (arr, toEqual) => {
  const getContent = () => {
    const description = getDescription(arr, toEqual)

    if (description?.split(',').length > 2) {
      return description
        .split(',')
        .splice(2, description.length - 1)
        .join(', ')
    } else {
      return description
    }
  }

  return (
    <>
      {getDescription(arr, toEqual)?.split(',').length > 2
        ? getDescription(arr, toEqual)
            .split(',')
            .map(
              (item, index) => index < 2 && item + (index !== 1 ? ',' : ' and')
            )
        : getDescription(arr, toEqual)}
      <Popover
        content={<div style={{ maxWidth: 150 }}>{getContent()} </div>}
        placement={'rightTop'}
      >
        <span
          style={{
            fontFamily: 'var(--bold)',
            color: 'var(--blue)',
            paddingLeft: '.5em',
          }}
        >
          {getDescription(arr, toEqual)?.split(',').length > 2 &&
            `${getDescription(arr, toEqual)?.split(',').length - 2} more`}
        </span>
      </Popover>
    </>
  )
}
