import { CloseCircleFilled } from '@ant-design/icons'
import React from 'react'
import InputMask from 'react-input-mask'
import { CustomClose, TextInputWrap } from '../input.style'

const InputText = ({
  mask,
  value,
  handleChange,
  handleBlur,
  placeholder,
  disabled,
  styleInput,
  onChange,
  handleClear,
}) => {
  const onClear = () => {
    onChange(null)
    handleClear && handleClear()
  }
  return (
    <TextInputWrap>
      <InputMask
        mask={mask}
        value={value || ''}
        onChange={(e) => {
          onChange(e.target.value || undefined)
          handleChange && handleChange(e)
        }}
        onBlur={() => handleBlur && handleBlur()}
        maskChar={null}
        placeholder={placeholder}
        disabled={disabled}
        style={styleInput}
      />
      <CustomClose onClick={onClear}>
        <CloseCircleFilled />
      </CustomClose>
    </TextInputWrap>
  )
}

export default InputText
