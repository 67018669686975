import styled from 'styled-components'

const commonStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0 2em;
  font-family: var(--medium);
  line-height: 1em;
  border: 0;
  outline: none !important;
  transition: all 0.2s ease-in-out;
    cursor: pointer;
  img {
    height: 1em;
    margin-right: 0.5em;
  }
`

export const Wrap = styled('button')`
  ${commonStyles};
  border-radius: 6px;
  background-color: ${(props) => (props.border ? '#fff' : 'var(--blue)')};
  border: ${(props) => props.border && '1px solid var(--blue)'};
  color: ${(props) =>
    props.color ||
    (props.border ? 'var(--blue) !important' : '#fff !important')};
  font-size: ${(props) => (props.small ? '0.75em' : '1em')};
  height: ${(props) => (props.small ? '27px' : '37px')};
  opacity: ${(props) => (props.disabled ? '.5' : 1)};
  width: fit-content;
  cursor: ${(props) => props.disabled && 'default'};

  &:hover {
    background-color: #113363;
    color: ${(p) => p.color || '#fff !important'};
  }
`

export const WrapWhite = styled('button')`
  ${commonStyles};
  height: 2.5em;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #c4cacc;
  color: var(--text);
  font-size: 0.875em;
  cursor: ${(props) => props.disabled && 'default'};
  opacity: ${(props) => (props.disabled ? '.5' : 1)};

  &:hover {
    color: #bbb;
  }
`

export const WrapGreen = styled('button')`
  ${commonStyles};
  border: 1px solid var(--green);
  background-color: ${(props) =>
    props.border ? 'transparent' : 'var(--green)'};
  border-radius: 6px;
  color: ${(props) => (props.border ? 'var(--green)' : '#fff')};
  cursor: ${(props) => props.disabled && 'default'};
  height: ${(props) => (props.small ? '27px' : '37px')};

  &:hover {
    color: ${(props) => (props.border ? 'var(--green)' : '#fff')};
  }
`
