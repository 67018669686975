import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../mobx-store/context'
import Search from '../Search/search'
import Table from '../Table/table'
import { Wrap } from './left.style'
import LockBlock from './LockBlock/lockBlock'

const Left = observer(
  ({
    auth,
    getCurrentLegislative,
    archived,
    changeTab,
    currentLegislativeLoading,
    active,
    currentPage,
    setCurrentPage,
    isMyTasks,
    complianceItem,
    onCreateNew,
  }) => {
    const store = useStore()
    const [page, setPage] = useState(1)

    const count =
      active === 'main' || active === 'closed'
        ? store.legislatives?.count
        : store.tasks?.count

    useEffect(() => {
      setCurrentPage(1)
      setPage(1)
    }, [active])

    const checkForTrial = () =>
      store.company.length > 0 && store.company[0].payment_status === 'trial'

    return (
      <Wrap>
        <Search
          auth={auth}
          closed={archived}
          isTrial={checkForTrial()}
          onSearch={() => setCurrentPage(1)}
          isMyTasks={active === 'myTasks'}
          activeTab={active}
        />
        <Table
          count={count || 0}
          loading={currentLegislativeLoading}
          changeTab={(key) => changeTab(key)}
          getCurrentLegislative={(id) => getCurrentLegislative(id)}
          archived={archived}
          isTrial={checkForTrial()}
          currentPage={currentPage}
          onCurrentPageChange={(page) => setCurrentPage(page)}
          activeTab={active}
          isMyTasks={isMyTasks}
          complianceItem={complianceItem}
          onCreateNew={onCreateNew}
        />
        {checkForTrial() && <LockBlock />}
      </Wrap>
    )
  }
)

export default Left
