import styled from 'styled-components'

export const Wrap = styled('div')`
  padding-right: 2em;
  @media (max-width: 1350px) {
    padding-right: 0;
  }
`

export const Title = styled('div')`
  font-family: var(--regular);
  font-size: 2.5em;
  color: var(--text);
  margin-top: 3.75em;
`
