import { CloseCircleFilled } from '@ant-design/icons'
import moment from 'moment'
import 'moment-timezone'
import React from 'react'
import Moment from 'react-moment'
import { useStore } from '../../../../mobx-store/context'
import { CustomClose } from '../input.style'

const InputDateRange = ({ value, name, handleChange, reset }) => {
  const store = useStore()
  let tz = moment?.tz?.guess()

  return (
    <>
      {value ? (
        <>
          <Moment
            format={'MM/DD/YYYY'}
            style={{ marginRight: 5 }}
            className={'date_range_click'}
            tz={tz}
          >
            {value?.split('±')[0]}
          </Moment>
          -
          <Moment
            format={'MM/DD/YYYY'}
            style={{ paddingLeft: 5 }}
            className={'date_range_click'}
            tz={tz}
          >
            {value?.split('±')[1]}
          </Moment>
        </>
      ) : (
        <span
          style={{ color: 'var(--text)', opacity: '.6' }}
          className={'date_range_click'}
        >
          {' '}
          Select Date Range
        </span>
      )}
      <CustomClose
        className={'ant-select-clear'}
        onClick={() => {
          const newSearchObj = store.searchableObject

          delete newSearchObj.adopted_date_after
          delete newSearchObj.adopted_date_before
          delete newSearchObj.primary_due_date_before
          delete newSearchObj.primary_due_date_after

          store.setSingleValue('searchableObject', newSearchObj)

          reset(name)

          handleChange && handleChange()
        }}
      >
        <CloseCircleFilled />
      </CustomClose>
    </>
  )
}

export default InputDateRange
