import styled from 'styled-components'

export const Wrap = styled("div")`
  height: 23.875em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.875em;
  border-radius: 4px;
  background: linear-gradient(
    0deg,
    #225194 3.68%,
    #4e74a9 37.06%,
    #f4f6fa 140.18%
  );
`;

export const Content = styled("div")`
  width: 100%;
  height: 11.625em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue);
  border-radius: 3px;
  color: var(--blue);
  font-size: 0.875em;
  padding: 0 2.938em;
  a {
    color: var(--orange);
    text-decoration: underline;
  }
  div {
    text-align: center;
    margin-top: 1em;
  }
`;
