import styled from 'styled-components'

export const Wrap = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 1.3fr;
  align-items: center;
  min-height: 78px;
  border-bottom: 1px solid #c4cacc;
  background-color: ${(p) => (p.active ? '#F2F5FF' : 'fff')};
`

export const Text = styled('div')`
  font-size: 14px;
  font-family: var(--regular);
  color: var(--text);
  display: flex;
  align-items: center;
  img {
    margin: 0 25px 0 10px;
  }
`
