import React from 'react'
import Input from '../../../../components/common/Input'

const Form = ({ control, errors }) => {
  const commonStyles = { marginBottom: 20 }

  return (
    <form style={{ width: 400 }}>
      <Input
        control={control}
        name={'first_name'}
        label={'First Name'}
        error={errors && errors.first_name && 'Enter valid name'}
        validation={{ required: true, pattern: /[A-Za-z]+/ }}
        style={commonStyles}
        transparent
        smallLabel
      />
      <Input
        control={control}
        name={'last_name'}
        label={'Last Name'}
        error={errors && errors.last_name && 'Enter valid last name'}
        validation={{ required: true, pattern: /[A-Za-z]+/ }}
        style={commonStyles}
        transparent
        smallLabel
      />
      <Input
        control={control}
        type={'email'}
        name={'email'}
        label={'Email'}
        disabled
        style={commonStyles}
        transparent
        smallLabel
      />
    </form>
  )
}

export default Form
