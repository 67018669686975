import React, { useEffect, useState } from 'react'
import PageWrap from '../../components/Container'
import { PageTitle } from '../../components/common/style'
import { Form, Row, Title } from './style'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { useForm } from 'react-hook-form'
import Button from '../../../../components/common/Button/button'
import {
  getError,
  getInputError,
  resetPasswordValidationBlock,
} from '../../../../helpers/helpers'
import { api } from '../../../../api'
import Input from '../../../../components/common/Input'

const PasswordAndSecurity = () => {
  const {
    control,
    handleSubmit,
    errors,
    getValues,
    reset,
    clearErrors,
    setError,
    setValue,
  } = useForm()
  const [success, setSuccess] = useState(false)
  const [validationText, setValidationText] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = (values) => {
    setLoading(true)
    api(
      '/user_settings/change_password/',
      {
        password: values.password,
        current_password: values.current_password,
      },
      'POST',
    ).then((data) => {
      if (data.ok) {
        setSuccess(true)
        reset({
          password: null,
          password_confirm: null,
          current_password: null,
        })
        clearErrors()
        setValue('password', '')
        setValidationText('You have successefully updated your password')
      } else {
        setSuccess(false)
        setValidationText(getError(data))
        getError(data, setError)
      }
      setLoading(false)
    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    if (errors.password && getValues('password')?.length < 8) {
      setSuccess(false)
      setValidationText(
        'Please ensure that you password meets all of the following criteria:',
      )
      return
    }
    if (errors.password_confirm) {
      setValidationText('New passwords do not match. Please try again.')
      setSuccess(false)
    }
  }, [errors])

  return (
    <PageWrap>
      <PageTitle>Password and Security</PageTitle>
      <Row style={{ marginTop: 10, justifyContent: 'flex-start' }}>
        <Title>Update Your Password</Title>
        <ButtonOrange
          text={'Forgot Password?'}
          link={{
            pathname: '/reset-password-enter-email',
            state: { fromSettings: true },
          }}
        />
      </Row>
      <Form>
        <Input
          name={'current_password'}
          type={'password'}
          label={'Current Password'}
          control={control}
          error={getInputError(errors?.current_password,
            'Enter valid password')}
          placeholder={'Password'}
          validation={{
            required: true,
            validate: (value) => value.length >= 8,
          }}
          smallLabel
          transparent
          relativeIcon
        />
        <div/>

        <Input
          name={'password'}
          type={'password'}
          label={'New Password'}
          placeholder={'Password'}
          control={control}
          error={errors && errors.password && 'Enter valid password'}
          handleChange={(value) => {
            if (
              value &&
              getValues('password_confirm') &&
              value === getValues('password_confirm')
            ) {
              clearErrors(['password_confirm'])
            }
          }}
          validation={{
            required: true,
            validate: (value) => value.length >= 8,
          }}
          showErrorText={false}
          success={getValues('password')?.length >= 8}
          smallLabel
          transparent
        />
        <Input
          name={'password_confirm'}
          type={'password'}
          label={'Confirm New Password'}
          placeholder={'Password'}
          control={control}
          error={
            (getValues('password_confirm') &&
              getValues('password') &&
              getValues('password') !== getValues('password_confirm') &&
              'Password doesn\'t match') ||
            (errors.password_confirm && 'Password doesn\'t match')
          }
          validation={{
            validate: (value) => value === getValues('password'),
            required: true,
          }}
          showErrorText={false}
          success={
            getValues('password') &&
            getValues('password_confirm') &&
            !errors.password_confirm &&
            getValues('password') === getValues('password_confirm')
          }
          smallLabel
          transparent
        />
      </Form>
      {resetPasswordValidationBlock(getValues, success, errors, validationText)}
      <Row>
        <Button
          text={'Save Changes'}
          onClick={handleSubmit(onSubmit)}
          type={'green'}
          loading={loading}
          disabled={loading}
        />
      </Row>
    </PageWrap>
  )
}
export default PasswordAndSecurity
