import React from 'react'
import image from '../../../../assets/images/resource-benefits.svg'
import Item from '../../Compliance/Benefits/Item/index'
import { RightSide as Grid } from '../../Compliance/Benefits/style'
import { TitleSmall } from '../Header/style'
import { Container, Description, Image, Wrap } from './style'

const benefits = [
  {
    key: 0,
    title: 'Onboard new customers with confidence',
    description:
      'View summaries of regulatory requirements to validate a compliant set up',
  },
  {
    key: 1,
    title: 'Simplify creation of P&Ps',
    description:
      'View summaries of regulatory requirements to identify the most stringent requirements or common denominator or outliers to simplify operations and P&Ps',
  },
  {
    key: 2,
    title: 'Customize by line of business',
    description:
      'Know line of business applicability to regulatory requirements to have flexibility to customize your operations by line of business (e.g. Medicare, Medicade, commerical fully insured, self insured, self-funded, ERISA, self-funded government, self-funded church, discount card)',
  },
  {
    key: 3,
    title: 'Accurately create/respond to RFPs`',
    description:
      'Research existing laws and regulations by either topic or jurisdiction. View regulations across jurisdictions or by topic. ',
  },
  {
    key: 4,
    title: 'Inovate new compliant programs',
    description:
      'View summaries of regulatory requirements when developing new programs to save time and money. ',
  },
  {
    key: 5,
    title: 'Summary and details at your finger tips',
    description:
      'Regulatory requirements are summarized by topic and broken down into subparts for easy identification of commonality and outliers at the component levels with the specific detail of each requirements available with simply a click. ',
  },
]

const Info = () => {
  return (
    <Wrap>
      <Container>
        <Image src={image} alt='benefits' />
        <div>
          <TitleSmall style={{ textAlign: 'left' }}>
            Have the information you need when you need it
          </TitleSmall>
          <Description>
            Access summaries of existing laws and regulations by topic or
            jurisdiction to manage new business opportunities and programs and
            to promote compliance with existing business.
          </Description>
          <Grid>
            {benefits.map((item) => (
              <Item key={item.key} {...item} />
            ))}
          </Grid>
        </div>
      </Container>
    </Wrap>
  )
}

export default Info
