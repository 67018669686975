import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import plusBlue from '../../../assets/images/plus-blue.svg'
import ItemsShowing from '../../../components/common/TableItemsShowing'
import { checkRole, getLocalStorageItem } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { columnsCommon, columnsTasks, getRecord } from './columns'
import Empty from './EmptyTable/empty'
import {
  Header,
  HeaderButton,
  HeaderTitle,
  TableStyled,
  Wrap,
} from './table.style'

const tableHeaderButton = {
  main: {
    title: 'View My Tasks',
    key: 'myTasks',
  },
  myTasks: {
    title: 'View Tracking',
    key: 'main',
  },
  closed: {
    title: 'View Tracking',
    key: 'main',
  },
}

const tableHeaderButtonSecond = {
  main: {
    title: 'View Closed',
    key: 'closed',
  },
  myTasks: {
    title: 'View Closed',
    key: 'closed',
  },
  closed: {
    title: 'View My Tasks',
    key: 'myTasks',
  },
}

const Table = observer(
  ({
    style,
    getCurrentLegislative,
    count,
    archived,
    isTrial,
    currentPage,
    onCurrentPageChange,
    activeTab,
    isMyTasks,
    complianceItem,
    onCreateNew,
  }) => {
    const history = useHistory()
    const store = useStore()
    const params = useParams()
    const [sorter, setSorter] = useState({})
    const [sortLoading, setSortLoading] = useState(false)
    const [active, setActive] = useState(null)
    const [dataSource, setDataSource] = useState([])

    const sortLegislatives = (direction, mutatedDirection, dataIndex, page) => {
      if (direction) {
        store.setSingleValue('searchableObject', {
          ...store.searchableObject,
          ordering: mutatedDirection[direction] + dataIndex,
        })
      } else {
        const newObj = store.searchableObject

        delete newObj.ordering

        store.setSingleValue('searchableObject', {
          ...newObj,
        })
      }
      store.loadLegislatives({
        page,
        handleLoading: () => setSortLoading(false),
      })
    }

    const sort = (direction, dataIndex, page) => {
      const mutatedDirection = {
        ascend: '',
        descend: '-',
      }
      if (activeTab === 'myTasks') {
        if (direction) {
          store.setSingleValue('searchableObjectTasks', {
            ...store.searchableObject,
            ordering: mutatedDirection[direction] + dataIndex,
          })
        } else {
          const newObj = store.searchableObjectTasks

          delete newObj.ordering

          store.setSingleValue('searchableObjectTasks', {
            ...newObj,
          })
        }
        store.loadTasks({
          page,
          handleLoading: () => setSortLoading(false),
        })
      } else {
        sortLegislatives(direction, mutatedDirection, dataIndex, page)
      }
    }

    const handleTableChange = (sorter, page) => {
      setSorter(sorter)
      setSortLoading(true)
      sort(
        sorter.order,
        Array.isArray(sorter.field) ? sorter.field[1] : sorter.field,
        page
      )
    }

    useEffect(() => {
      setSorter(null)
    }, [archived, activeTab])

    useEffect(() => {
      if (store.searchableObject?.status === 'unread') {
        const data = store.legislatives.results.filter(
          (item) => !JSON.parse(localStorage.getItem('readItems'))[item.id]
        )

        return setDataSource(data)
      }
      if (store.searchableObject?.status === 'read') {
        const data = store.legislatives.results.filter(
          (item) => JSON.parse(localStorage.getItem('readItems'))[item.id]
        )

        return setDataSource(data)
      }

      return setDataSource(store?.legislatives?.results)
    }, [isMyTasks, store.legislatives, store.legislatives?.results])

    useEffect(() => {
      if (isMyTasks) {
        setDataSource(store.tasks?.results)
      }
    }, [isMyTasks, store.tasks])

    const isLoading = isMyTasks ? !store.tasks : !store.legislatives

    if (!store.topics) return null

    return (
      <Wrap style={style}>
        <Header>
          <HeaderTitle>
            {archived
              ? 'Tracking Archive'
              : isMyTasks
              ? 'My Tasks'
              : 'Compliance Items Currently Being Tracked'}
          </HeaderTitle>
          <div style={{ display: 'flex' }}>
            {checkRole(
              [store.user[0].member_type],
              ['admin', 'compliance_owner']
            ) &&
              !isMyTasks &&
              !archived && (
                <HeaderButton onClick={onCreateNew} style={{ marginRight: 10 }}>
                  Create New
                  <img src={plusBlue} alt='plus-icon' />
                </HeaderButton>
              )}
          </div>
        </Header>

        <TableStyled
          locale={{
            emptyText: <Empty />,
          }}
          dataSource={dataSource}
          rowKey={(record) => record.id} // Обязательно должны быть разные ID !!!!!
          columns={
            isMyTasks
              ? columnsTasks({
                  store,
                  sorter,
                  isMyTasks,
                  activeTab,
                  reference_number: {
                    width: 200,
                  },
                })
              : columnsCommon({ store, sorter, isMyTasks, activeTab })
          }
          onChange={(pagination, _, sorter) => {
            handleTableChange(sorter, pagination.current)
            onCurrentPageChange(pagination.current)
          }}
          loading={isLoading || sortLoading}
          size='small'
          pagination={{
            position: ['bottomLeft'],
            pageSize: isTrial ? 10 : 20,
            hideOnSinglePage: true,
            total: isTrial ? 10 : count,
            disabled: isLoading,
            current: currentPage,
            showSizeChanger: false,
          }}
          rowClassName={(record) => {
            if (
              +getRecord({ record, activeTab, isMyTasks })?.id ===
              (+params.id || +complianceItem)
            ) {
              return 'active-row'
            }
            if (
              !getLocalStorageItem(
                +getRecord({ record, activeTab, isMyTasks })?.id
              )
            )
              return 'unread-row'
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                const id = getRecord({ record, activeTab, isMyTasks })?.id
                getCurrentLegislative(
                  getRecord({ record, activeTab, isMyTasks })?.id
                )

                setActive(+id)

                history.push(`/tracking/${activeTab}/?compliance_item=${id}`)

                const readItems = JSON.parse(localStorage.getItem('readItems'))
                localStorage.setItem(
                  'readItems',
                  JSON.stringify({ ...readItems, [id]: true })
                )
              },
            }
          }}
        />

        <ItemsShowing
          page={currentPage}
          pageSize={isTrial ? 10 : 20}
          total={store.legislatives?.count}
          style={{
            textAlign: 'right',
            marginTop: -31,
            paddingRight: 10,
            width: 'fit-content',
            marginBottom: 10,
          }}
        />
      </Wrap>
    )
  }
)

Table.propTypes = {
  style: PropTypes.object,
}

export default Table

export const statuses = {
  read: ['Read', 'var(--blue)'],
  unread: ['Unread', 'var(--orange)'],
  in_progress: ['In progress', 'var(--green)'],
  closed: ['Closed', 'grey'],
  all_tasks_completed: ['All Tasks Completed', 'var(--green)'],
  complete: ['Completed', 'var(--green)'],
  incomplete: ['Incomplete', 'var(--orange)'],
  unassigned: ['Unassigned', 'var(--orange)'],
}
