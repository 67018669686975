import styled from 'styled-components'
import { PlainText } from '../../../../components/common/PlainText/style'

export const Text = styled(PlainText)`
  font-size: 0.875em;
`

export const AutoRenewalWrap = styled('div')`
  border: 1px solid ${(props) => (props.active
    ? 'var(--orange)'
    : 'transparent')};
  padding: ${(props) => props.active && '1em'};
  font-family: var(--bold);
  font-size: 1em;
  color: var(--orange);
  margin-top: 20px;
  background-color: ${({ active }) => (active ? '#fff' : 'transparent')};

  .ant-checkbox-wrapper {
    color: ${(props) => props.active && 'var(--orange)'};
  }

  .ant-checkbox-inner {
    border-color: ${(props) => props.active && 'var(--orange) !important'};
  }
`

export const Render = styled('div')`
  font-family: ${(props) =>
    props.status === 'unread' && 'var(--open-sans-bold)'};
  opacity: ${p => p.isActive ? 1 : 0.5};
`
