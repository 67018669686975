import React, { useState } from 'react'
import Calendar from 'react-calendar'
import close from '../../../assets/images/close-blue.svg'
import useDidMountEffect from '../../../hooks/useEffectNotOnPageLoad'
import { CalendarWrap } from '../../../pages/Auth/StartDate/startDate.style'
import Overlay from '../Overlay'
import { Close, Wrap } from './style'

const DateRange = ({
  visible,
  onChange,
  onClose,
  onBlur,
  noLimit,
  style,
  value,
}) => {
  const [date, setDate] = useState({ start: null, end: null })

  const dateStart = new Date(value?.split('±')[0]) || null
  const dateEnd =
    new Date(value?.split('±')[1]) >= dateStart
      ? new Date(value?.split('±')[1])
      : dateStart

  useDidMountEffect(() => {
    if (new Date(value?.split('±')[1]) < new Date(value?.split('±')[0])) {
      onChange({ start: date.start, end: date.start })
    }
    if (!value) {
      setDate({ start: null, end: null })
    }
  }, [value])

  return (
    <>
      {visible && <Overlay style={{ zIndex: 99 }} onClick={onClose} />}
      <Wrap visible={visible} style={style}>
        <Close src={close} alt={'close-icon'} onClick={onClose} />
        <CalendarWrap>
          <Calendar
            calendarType={'US'}
            locale={'en-EN'}
            onChange={(value) => {
              const newDate = { start: value, end: date.end || value }
              setDate(newDate)
              onChange(newDate)
            }}
            value={dateStart}
            defaultActiveStartDate={new Date()}
            maxDate={noLimit ? null : date.end || new Date()}
            minDate={noLimit ? null : new Date(2021, 0, 1)}
          />
        </CalendarWrap>
        <CalendarWrap>
          <Calendar
            calendarType={'US'}
            locale={'en-EN'}
            onChange={(value) => {
              const newDate = { start: date.start || value, end: value }
              setDate(newDate)
              onChange(newDate)
              if (date.start && date.end) {
                onClose()
                onBlur && onBlur()
              }
              onChange(newDate)
            }}
            defaultActiveStartDate={new Date()}
            value={dateEnd}
            maxDate={noLimit ? null : new Date()}
            minDate={date.start || new Date()}
          />
        </CalendarWrap>
      </Wrap>
    </>
  )
}

export default DateRange
