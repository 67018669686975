import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { api } from '../../../api'
import { getError } from '../../../helpers/helpers'
import useDidMountEffect from '../../../hooks/useEffectNotOnPageLoad'
import { useStore } from '../../../mobx-store/context'
import Guides from './Guides'
import Item from './Guides/Item'
import Jurisdictions from './Jurisdictions'
import { Wrap } from './style'
import TopLine from './TopLine'

const ResourceOptions = observer(() => {
  const { control, handleSubmit, errors, setError, clearErrors } = useForm()
  const store = useStore()
  const history = useHistory()
  const [primaryJurisdictions, setPrimaryJurisdictions] = useState([])
  const [paidPrimaryJurisdictions, setPaidPrimaryJurisdictions] = useState([])
  const [staticItems, setStaticItems] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [items, setItems] = useState(null)
  const [finalItems, setFinalItems] = useState(null)
  const [monthPrice, setMontPrice] = useState(0)
  const [period, setPeriod] = useState('month')
  const [isTotal, setIsTotal] = useState(false)
  const [totalLineOfBusiness, setTotalLineOfBusiness] = useState(false)
  const [staticPrimaryJurisdictions, setStaticPrimaryJurisdictions] = useState(
    []
  )

  const totalItem = (
    <Item
      totalSource
      toggleItem={(_, action) => {
        changeAll(action === 'add', 'checked')
        setIsTotal(action === 'add')
      }}
      onLineOfBusinessSelect={(_, action) => {
        if (!isTotal) return
        changeAll(action === 'add', 'line_of_business')
        setTotalLineOfBusiness(action === 'add')
      }}
      isTotal={isTotal}
      item={{
        checked: isTotal,
        line_of_business: totalLineOfBusiness,
      }}
    />
  )

  const isNotPaidJursExist = () => {
    if (!items) return false

    for (let i = 0; i < items.length; i++) {
      if (items[i].custom_no_paid_jurs?.length > 0) return true
    }

    return false
  }

  const isPrimaryJursAdded =
    [
      ...primaryJurisdictions.filter(
        (item) => !paidPrimaryJurisdictions?.includes(item)
      ),
      ...paidPrimaryJurisdictions,
    ]?.length !== paidPrimaryJurisdictions?.length

  // const allowNext = store.company?.[0]?.is_resource_registration_passed
  //   ? items?.find(
  //       (item) => item.checked && item.custom_no_paid_jurs?.length > 0
  //     ) ||
  //     items?.filter((item) => item.checked)?.length !==
  //       staticItems?.filter((item) => item.checked)?.length ||
  //     isPrimaryJursAdded
  //   : isPrimaryJursAdded &&
  //     items?.filter((item) => item.checked)?.length !==
  //       staticItems?.filter((item) => item.checked)?.length

  const allowNext = monthPrice > 0

  const changeAll = (boolValue, type) => {
    const newItems = items.map((item) => ({
      ...item,
      line_of_business: type === 'line_of_business' ? boolValue : false,
      jurisdictions: primaryJurisdictions,
      customJurisdictions: false,
      [type]: boolValue,
    }))

    setItems(newItems)
  }

  const filteredCustomJurisdctions = (jurs) => {
    if (!jurs?.length) return []

    return jurs.filter((item) => !primaryJurisdictions.includes(item))
  }

  const calculatedItemFitler = (item) => {
    if (item.status === 'paid') {
      if (item.custom_no_paid_jurs?.length) return true

      const checkIfItemCustomized = () => {
        for (let i = 0; i < item.jurisdictions?.length; i++) {
          if (
            ![
              ...primaryJurisdictions.filter(
                (item) => !paidPrimaryJurisdictions?.includes(item)
              ),
              ...paidPrimaryJurisdictions,
            ].includes(item.jurisdictions[i])
          )
            return true
        }

        return false
      }

      if (checkIfItemCustomized()) return false

      return true
    }

    return true
  }

  const calculate = () => {
    if (!items) return null

    const newItems = items
      .filter((item) => item.checked && calculatedItemFitler(item))
      .map((item) => {
        return {
          guide_id: item?.id || item?.guide_id,
          line_of_business: item.line_of_business,
          jurisdictions: item.custom_no_paid_jurs?.length
            ? item.custom_no_paid_jurs
            : [
                ...filteredCustomJurisdctions(
                  item.jurisdictions?.length
                    ? item.jurisdictions
                    : item.custom_no_paid_jurs
                ),
                ...primaryJurisdictions,
                ...paidPrimaryJurisdictions,
              ],
        }
      })

    const body = isTotal
      ? { line_of_business: totalLineOfBusiness }
      : { items: newItems }

    api(
      '/resource_payments/calculator/',
      {
        tariff: isTotal ? 'TotalSource' : 'SelectSource',
        ...body,
        primary_jurisdictions: primaryJurisdictions,
      },
      'POST'
    ).then((data) => {
      if (!data.errors) {
        setMontPrice(data.month_price)
        setFinalItems(newItems)
      }
    })
  }

  const onSave = (values) => {
    clearErrors()
    setPrimaryJurisdictions(values.jurisdictions)
    setModalVisible(false)
  }

  const annualDiscount = store.resourceTariffs?.[0].annual_discount

  const onCheck = (guide_id, action, type) => {
    const index = items.findIndex(
      (item) => (item.id || item.guide_id) === guide_id
    )

    setIsTotal(false)
    setTotalLineOfBusiness(false)

    const newItems = items.map(
      (item, itemIndex) =>
        (itemIndex === index && { ...item, [type]: action === 'add' }) || item
    )

    setItems(newItems)
  }

  const onJurisdictionCustomize = (guide_id, arr) => {
    const index = items.findIndex(
      (item) => (item.id || item.guide_id) === guide_id
    )

    setIsTotal(false)

    const newItems = [...items]

    const currItem = newItems[index]

    newItems[index].custom_no_paid_jurs = arr.filter(
      (item) => !currItem.jurisdictions?.includes(item)
    )

    newItems[index].customJurisdictions = arr.length ? true : false

    setItems(newItems)
  }

  const deletePrimaryJurisdiction = (deleteItem) => {
    const newArr = primaryJurisdictions.filter((item) => item !== deleteItem)
    const indexes = []

    for (let i = 0; i < items.length; i++) {
      if (items[i].jurisdictions?.includes(deleteItem)) indexes.push(i)
    }

    const newItems = [...items].filter((item) => item.checked)

    for (let i = 0; i < indexes.length; i++) {
      newItems[i].jurisdictions = newItems[i].jurisdictions?.filter(
        (item) => item !== deleteItem
      )
    }

    setPrimaryJurisdictions(newArr)
  }

  const filterByCategory = (value) => {
    if (value === 'all') {
      return setItems(staticItems)
    }

    const filtered = staticItems.filter((item) => item.category === value)

    setItems(filtered)
  }

  const confirmSelectedGuides = () => {
    api(
      '/resources/selected_guides/',
      { items: finalItems, primary_jurisdictions: primaryJurisdictions },
      'PUT'
    )
      .then((data) => {
        if (!data.errors) {
          if (store.company?.[0]?.is_resource_registration_passed) {
            history.push('/resource/billing')
          } else {
            history.push('/resource/addMembers')
          }
          store.getSelectedJurisdictions()
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (!store.resources) store.getResourceTopic()
    if (!store.jurs) store.loadSelect('companyOptions')
    store.getResourceRegisterGuides()

    store.setSingleValue('isResourceRegisterStarted', true)
  }, [])

  useDidMountEffect(() => {
    setItems(items)
  }, [primaryJurisdictions])

  const getItems = (checkedItems, primaryJurs) =>
    store.registerGuides.map((allItem) => {
      const findItem = checkedItems.find((item) => item.guide_id === allItem.id)

      if (!findItem) return allItem

      const onHoldItem = checkedItems.find(
        (item) =>
          item.status === 'on hold' && item.guide_id === findItem.guide_id
      )

      const isPaid = checkedItems.find(
        (item) => item.status === 'paid' && item.guide_id === findItem.guide_id
      )

      return {
        ...findItem,
        custom_no_paid_jurs:
          onHoldItem?.jurisdictions.filter(
            (item) => !primaryJurs?.includes(item)
          ) || [],
        jurisdictions:
          findItem.status === 'on hold' ? [] : findItem.jurisdictions,
        checked: true,
        disabled: isPaid,
        price: allItem.price,
      }
    })

  const handlePeriod = (period) => {
    api('/resources/my-tariff/', { payment_period: period }, 'PATCH').then(
      (data) => {
        if (data.errors) return message.error(getError(data))

        calculate()

        setPeriod(period)
      }
    )
  }

  useEffect(() => {
    if (store.registerGuides?.length > 0) {
      setStaticItems(store.registerGuides)
      api('/resources/selected_guides/', {}, 'GET').then((data) => {
        if (!data.errors) {
          if (data.items.length) {
            setItems(getItems(data.items, data.primary_jurisdictions))
            setStaticItems(getItems(data.items))
          } else {
            setItems(store.registerGuides)
          }
          setPrimaryJurisdictions(data.primary_jurisdictions)
          setStaticPrimaryJurisdictions(data.primary_jurisdictions)
          setPaidPrimaryJurisdictions(data.paid_primary_jurisdictions)
        }
      })
    }
  }, [store.registerGuides])

  useEffect(() => {
    if (!store.resourceTariffs)
      api('/resources/tariffs/', {}, 'GET').then((data) => {
        if (!data.errors) {
          store.setSingleValue('resourceTariffs', data)
        }
      })
  }, [])

  // Calculator
  useDidMountEffect(() => {
    calculate()
  }, [items, primaryJurisdictions])

  useEffect(() => {
    if (!store.guideCategories) {
      api('/guide_category/', {}, 'GET').then((data) => {
        if (!data.errors) {
          store.setSingleValue('guideCategories', data)
        }
      })
    }
  }, [])

  return (
    <Wrap>
      <TopLine
        total={monthPrice}
        setPeriod={handlePeriod}
        active={period}
        annualDiscount={annualDiscount}
      />
      <Jurisdictions
        store={store}
        control={control}
        onSave={handleSubmit(onSave)}
        selectedJurisdictions={primaryJurisdictions}
        onDelete={(item) => deletePrimaryJurisdiction(item)}
        error={errors.jurisdictions}
        toggleModal={(value) => setModalVisible(value)}
        visible={modalVisible}
        paidJurs={paidPrimaryJurisdictions}
      />

      <Guides
        store={store}
        items={items}
        staticItems={staticItems}
        primaryJurisdictions={staticPrimaryJurisdictions}
        toggleItem={(guide_id, action) => onCheck(guide_id, action, 'checked')}
        onLineOfBusinessSelect={(guide_id, action) =>
          onCheck(guide_id, action, 'line_of_business')
        }
        onSubmit={confirmSelectedGuides}
        onJurisdictionChange={(guide_id, arr) =>
          onJurisdictionCustomize(guide_id, arr)
        }
        allowNext={allowNext}
        totalItemComponent={totalItem}
        isTotal={isTotal}
        totalLineOfBusiness={totalLineOfBusiness}
        filterByCategory={(value) => filterByCategory(value)}
        paidJurs={paidPrimaryJurisdictions}
      />
    </Wrap>
  )
})

export default ResourceOptions
