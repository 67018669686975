import styled from 'styled-components'
import { RightSide as Grid } from '../../Compliance/Benefits/style'

export const Wrap = styled('div')`
  background-color: #f9fafb;
  border: 2px solid #dbe9f1;
  padding: 50px 0;
`

export const Container = styled('div')`
  max-width: 1250px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 70px;
  align-items: center;
  @media (max-width: 950px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 15px;
    ${Grid} {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const Description = styled('div')`
  font-family: var(--regular);
  font-size: 24px;
  color: var(--text);
  line-height: 30px;
  margin-top: 15px;
  margin-bottom: 50px;
`

export const Image = styled('img')`
  width: 100%;
  @media (max-width: 950px) {
    width: 70%;
  }
`
