import { observer } from 'mobx-react-lite'
import React from 'react'
import arrowRight from '../../../../assets/images/arrow-right.svg'
import macbook from '../../../../assets/images/MacBook-Pro@2x.png'
import Button from '../../../../components/common/Button/button'
import { useStore } from '../../../../mobx-store/context'
import { ContainerGlobal, DescriptionGlobal, TitleGlobal } from '../styles'
import { FreeSign, LeftSide, RightSide, Wrap } from './style'

const Header = observer(() => {
  const store = useStore()

  return (
    <Wrap>
      <ContainerGlobal>
        <LeftSide>
          <TitleGlobal>
            Comprehensive <br /> Compliance Tool For <br /> The PBM Industry
          </TitleGlobal>
          <DescriptionGlobal>
            One source for compliance. New laws, regulations, subregulatory
            guidance identified and automatically uploaded to your dashboard.
            Alerts to your inbox. Online team collaboration. Tracking and
            documentation of activities. For PBMs, PSAOs, Plans, UROs, Auditors,
            and Discount Card programs.
          </DescriptionGlobal>
          <Button
            text={
              <>
                Get started <img src={arrowRight} alt='arrow' />
              </>
            }
            to={store.user.length ? '/tracking' : '/register'}
          />
          <FreeSign>Try us for free! No credit card required.</FreeSign>
        </LeftSide>
        <RightSide>
          <img src={macbook} alt='macbook-pro-image' />
        </RightSide>
      </ContainerGlobal>
    </Wrap>
  )
})

export default Header
