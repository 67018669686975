import React from 'react'
import demoImage from '../../../../assets/images/demo.svg'
import ScheduleInput from './ScheduleInput'
import { Container, Description, Title, Wrap } from './style'

const Demo = () => {
  return (
    <Container>
      <Wrap>
        <div>
          <img src={demoImage} alt='process' />
        </div>
        <div>
          <Title>Want to see more?</Title>
          <Description>
            We are always happy to show and discuss more. Tell us where to reach
            you and we’ll schedule a demo.
          </Description>
          <ScheduleInput />
        </div>
      </Wrap>
    </Container>
  )
}

export default Demo
