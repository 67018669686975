import { Spin } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { api } from '../../../../../api'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import { Row } from '../../../components/common/style'
import AutoRenewal from '../autoRenewal'
import { Text } from '../style'
import Table from '../table'

const ResourceTab = observer(() => {
  const store = useStore()
  const company = store.company[0]
  const user = store.user?.[0]
  const tariffInfo = store.resourceTariffInfo

  useEffect(() => {
    if (!tariffInfo) store.getResourceTariffInfo()
  }, [])

  const patchOnAutoRenew = (checked) => {
    api(
      '/resources/my-tariff/',
      {
        auto_renew_subscription: checked,
      },
      'PATCH'
    ).then((data) => {
      if (!data.error) {
        store.setSingleValue('resourceTariffInfo', data)
      }
    })
  }

  const members = store.members?.filter((item) => item.have_access_to_resource)

  const getPrice = () => {
    if (tariffInfo?.payment_period === 'month') {
      return tariffInfo?.month_price
    } else {
      return (
        +tariffInfo?.month_price * 12 -
        (+tariffInfo?.annual_discount_percent * +tariffInfo?.month_price * 12) /
          100
      )
    }
  }

  if (!company) return <Spin />

  return (
    <>
      <Row style={{ marginTop: '1em' }}>
        {/* <Title>{tariffInfo?.tariff}</Title> */}
        {checkRole([user?.resource_role], ['admin']) && (
          <ButtonOrange
            text={'Edit Your Plan'}
            link={'/resource/options'}
            // style={{ marginLeft: '60px' }}
          />
        )}
      </Row>
      <Text style={{ marginTop: '10px' }}>
        {tariffInfo?.payment_period === 'month' ? 'Month' : 'Annual'}{' '}
        Subscription:{' '}
        <span style={{ fontFamily: 'var(--medium)' }}>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(getPrice(store))}
          /{tariffInfo?.payment_period === 'month' ? 'mo' : 'yr'}
        </span>
      </Text>
      <Text>{members?.length} Active Users</Text>
      {checkRole([user?.resource_role], ['admin']) && (
        <AutoRenewal
          checked={tariffInfo?.auto_renew_subscription}
          onCheck={patchOnAutoRenew}
          firstPaymentDate={tariffInfo?.first_payment_date}
          nextDate={tariffInfo?.next_payment_date}
        />
      )}

      {store.members.length > 0 ? (
        <Table data={members} isProductSetting />
      ) : (
        <Spin />
      )}
    </>
  )
})

export default ResourceTab
