import { message } from 'antd'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { api } from '../../../api'
import Input from '../../../components/common/Input'
import Logo from '../../../components/common/Logo/logo'
import { getError, modifyPaymentCardValues } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import ExampleComponent from '../../Payments/payForm'
import { Title } from '../authForm.style'
import { Container, Grid, Left, Middle, Right } from './billing.style'
import Evalon from './Elavon'
import GreenLine from './greenLine'
import OfflinePaymentModal from './Modal'
import NoPayment from './NoPayment'
import Summary from './Summary/summary'

const billingInfoInputs = [
  {
    key: 0,
    label: 'Full Name',
    placeholder: 'Full Name',
    name: 'full_name',
  },
  {
    key: 1,
    label: 'Business Name',
    placeholder: 'Business Name',
    name: 'business_name',
  },
  {
    key: 3,
    label: 'Billing Address',
    placeholder: 'Address Line 1',
    name: 'address',
  },
  // {
  //   key: 10,
  //   label: 'Phone',
  //   type: 'phone',
  //   name: 'phone',
  // },
  {
    key: 4,
    placeholder: 'City',
    name: 'city',
  },
  {
    key: 5,
    label: 'Billing ZIP code',
    placeholder: 'ZIP code',
    name: 'zip',
    mask: '99999',
  },
  {
    key: 6,
    label: 'State',
    placeholder: 'State',
    name: 'state',
    type: 'select',
  },
]

const Billing = observer(({ isResource }) => {
  const store = useStore()
  const history = useHistory()
  const {
    control,
    handleSubmit,
    formState,
    errors,
    trigger,
    unregister,
    register,
    reset,
    setError,
  } = useForm()
  const [state, setState] = useState({})
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [addingCard, setAddingCard] = useState(false)

  const paymentMethod = 'elavon'
  const company = store.company?.[0]

  const onSubmit = (values) => {
    setLoading(true)
    post(
      company.is_resource_registration_passed && isResource
        ? values
        : modifyPaymentCardValues(values)
    )
  }

  const onSkip = (values) => {
    companyPatch(values, false, () => {
      if (!isResource) {
        api('/payments/start_trial/', {}, 'POST').then((data) => {
          if (data.errors) return message.error(getError(data))
          store.getCompany(() => {
            store.getUser(() => {
              history.push(
                isResource ? '/resource/table/jurisdictions' : '/tracking/main'
              )
            })
          })
        })
      } else {
        history.push(
          isResource ? '/resource/table/jurisdictions' : '/tracking/main'
        )
      }
    })
  }

  const getBillingInfo = () => {
    api(
      `/${isResource ? 'resource_payments' : 'payments'}/first_payment_info/`,
      {},
      'POST'
    ).then((data) => {
      if (!data.errors) {
        setState(data)
      }
    })
  }

  const companyPatch = (body, shouldGetBillingInfo, onLoad) => {
    api(`/companies/${company.id}/`, body, 'PATCH').then((data) => {
      if (!data.errors) {
        store.setSingleValue('company', [data])

        if (shouldGetBillingInfo) {
          getBillingInfo()
        } else {
          if (onLoad) return onLoad()
          history.push(isResource ? '/resource/' : '/tracking/main')
        }
      }
    })
  }

  const confirmTeam = () => {
    const members = store.addedMembers

    api(
      '/members/confirm_team/',
      {
        members,
      },
      'POST'
    ).then((data) => {
      if (!data.errors) {
        store.setSingleValue('addedMembers', [])
        store.setSingleValue('members', [])
        setLoading(false)
      } else {
        if (data?.[0]?.email) {
          alert(data[0].email)
        }
      }
    })
  }

  const post = (body) => {
    const url = isResource
      ? company.is_resource_registration_passed
        ? '/resource_payments/upgrade_tariff/'
        : '/resource_payments/converge_create_subscription/'
      : '/payments/converge_create_subscription/'

    api(url, body, 'POST')
      .then((data) => {
        if (data.errors || !data.ok) {
          message.error(getError(data))
          return setLoading(false)
        }

        if (isResource) confirmTeam()
        store.getTariffInfo()
        store.getResourceTariffInfo()
        store.getCompany(() =>
          store.getUser(() => {
            history.push(
              isResource ? '/resource/table/jurisdictions' : '/tracking'
            )
            store.setSingleValue('isResourceRegisterStarted', false)
            store.loadSelect('companyOptions', () =>
              store.getSelectedJurisdictions()
            )
          })
        )
        store.getBillingCards()

        setLoading(false)
      })
      .catch((err) => alert(err))
  }

  const changePaymentPeriod = async (payment_period) => {
    if (isResource) {
      api('/resources/my-tariff/', { payment_period }, 'PATCH').then((data) => {
        if (data.errors) return message.error(getError(data))

        getBillingInfo()

        store.setSingleValue('resourceTariffInfo', {
          ...store.resourceTariffInfo,
          payment_period,
        })
      })
    } else {
      await companyPatch({ payment_period }, true)
    }
  }

  const handleSetOfflinePayment = () => {
    api('/resources/set_offline/', {}, 'POST').then((data) => {
      if (data.errors) return message.error(getError(data))
      if (isResource) confirmTeam(true)
      store.getCompany()
      store.getResourceTariffInfo()
      history.push('/edit-profile')
      setVisible(false)
    })
  }

  const editCard = (values) => {
    setLoading(true)
    api(
      `/payment_cards/${store.billingCards[0].id}/`,
      modifyPaymentCardValues(values),
      'PATCH'
    ).then((data) => {
      if (!data.errors) {
        message.success('Info saved')
        store.getBillingCards()
        setIsEditing(false)
        setLoading(false)
      }
    })
  }

  const addCard = (values) => {
    setLoading(true)
    api(`/payment_cards/`, modifyPaymentCardValues(values), 'POST').then(
      (data) => {
        if (!data.errors) {
          message.success('Card added')
          store.getBillingCards()
          setLoading(false)
          setAddingCard(false)
          store.setPrimaryCard(data.id)
        } else {
          message.error(getError(data))
          getError(data, setError)
          setLoading(false)
          setAddingCard(false)
        }
      }
    )
  }

  useEffect(() => {
    store.getResourceTariffInfo()
  }, [])

  useEffect(() => {
    getBillingInfo()
  }, [])

  useEffect(() => {
    setVisible(false)
  }, [errors])

  const onClose = () => {
    setAddingCard(false)
  }

  const { isValid } = formState

  const paymentMethods = {
    braintree: (
      <>
        <Title style={{ margin: 0 }}>Enter Card Information</Title>
        <ExampleComponent
          companyId={store.company.length && toJS(store.company[0].id)}
          isValid={isValid}
          callback={handleSubmit(onSubmit)}
          setError={() => {
            message.error('Fill all fields')
            trigger()
          }}
        />
      </>
    ),
    strapi: '',
    null: (
      <NoPayment
        isValid={isValid}
        setError={() => {
          message.error('Fill all fields')
          trigger()
        }}
        onSubmit={handleSubmit(onSubmit)}
      />
    ),
    elavon: (
      <Evalon
        control={control}
        handleSubmit={handleSubmit(
          isEditing ? editCard : addingCard ? addCard : onSubmit
        )}
        onSkip={handleSubmit(onSkip)}
        errors={errors}
        showSkip={!isResource}
        loading={loading}
        unregister={unregister}
        register={register}
        isResource={isResource}
        handleShowModal={() => setVisible(true)}
        setError={setError}
        reset={reset}
        isEditing={isEditing}
        setEditing={setIsEditing}
        addingCard={addingCard}
        setAddingCard={setAddingCard}
        onClose={onClose}
      />
    ),
  }

  useEffect(() => {
    if (!store.states.length) store.loadSelect('states')
  }, [])

  return (
    <>
      {visible && (
        <OfflinePaymentModal
          onClose={() => setVisible(false)}
          handleFinish={handleSubmit(handleSetOfflinePayment)}
          unregister={unregister}
        />
      )}
      <Container>
        <Logo style={{ margin: '2em 0' }} />
      </Container>
      <GreenLine
        data={state}
        paymentPeriod={
          isResource
            ? store.resourceTariffInfo?.payment_period
            : store?.company[0]?.payment_period
        }
        changePaymentPeriod={(payment_period) =>
          changePaymentPeriod(payment_period)
        }
      />
      <Container>
        <Grid>
          <Left>
            <Title style={{ margin: 0, marginBottom: '1em' }}>
              Enter Company Information
            </Title>
            <form>
              {billingInfoInputs.map((item, index) => (
                <>
                  <Input
                    type={item.type || 'text'}
                    label={item.label}
                    key={item.key}
                    mask={item.mask}
                    name={item.name}
                    placeholder={item.placeholder}
                    control={control}
                    validation={{ required: true }}
                    error={errors[item.name] && 'Enter valid data'}
                    styleContainer={{
                      marginBottom:
                        index === billingInfoInputs.length - 1 ? '.5em' : '1em',
                    }}
                    options={store.states}
                    selectVars={{ value: 0, name: 1 }}
                    transparent
                  />
                </>
              ))}
            </form>
          </Left>
          <Middle>
            <Title style={{ margin: 0 }}>Enter Payment Method</Title>

            {paymentMethods[paymentMethod]}
          </Middle>
          <Right>
            <Summary
              data={state}
              paymentPeriod={store?.company[0]?.payment_period}
              isResource={isResource}
            />
          </Right>
        </Grid>
      </Container>
    </>
  )
})

export default Billing
