import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Input from '../../../../../components/common/Input'
import { useStore } from '../../../../../mobx-store/context'
import ResourceModal from '../../../components/ResourceAccessModal/resourceModal'
import { resourceInputs } from './inputs'

const ResourceTab = ({ control, errors, showFields, register }) => {
  const store = useStore()
  const history = useHistory()

  const { name, label, type, customOptions, placeholder, validation, error } =
    resourceInputs(store, errors, showFields)[0]

  useEffect(() => {
    if (!store.resources) store.getResourceTopic()
  }, [])

  if (!store.user?.[0]?.have_access_to_resource)
    return (
      <ResourceModal
        type='tracking'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Input
        name={name}
        label={label}
        type={type}
        control={control}
        customOptions={customOptions}
        placeholder={placeholder}
        validation={validation}
        error={error}
      />
      <input
        type='hidden'
        name='have_access_to_resource'
        value={true}
        ref={register}
      />

      {resourceInputs(store, errors, showFields).map(
        ({
          name,
          label,
          type,
          options,
          placeholder,
          validation,
          error,
          showFields,
          key,
          transparent,
          style,
          loading,
          selectVars,
        }) =>
          showFields && (
            <Input
              name={name}
              label={label}
              error={error}
              type={type}
              control={control}
              validation={validation}
              key={key}
              transparent={transparent}
              style={style}
              placeholder={placeholder}
              options={options || []}
              loading={loading}
              selectVars={selectVars}
            />
          )
      )}
    </>
  )
}

export default ResourceTab
