import React from 'react'
import { Content, Wrap } from './lockBlock.style'
import Logo from '../../../../components/common/Logo/logo'
import { Link } from 'react-router-dom'

const LockBlock = () => {
  return (
    <Wrap>
      <Content>
        <Logo />
        <div>
          Thank you for trying PBMSource. A trial account is only capable of
          viewing the first 5 items in the tracker.
          <Link to={"/billing-info"}>Subscribe</Link> to view everything!
        </div>
      </Content>
    </Wrap>
  );
};

export default LockBlock;
