import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import Modal from '../../../../components/common/ModalNew/index'
import { useStore } from '../../../../mobx-store/context'
import { Cancel } from '../../../Tracking/Right/Tasks/Modal/style'

const types = {
  resource: 'ReSource',
  tracking: 'ComplianceSource',
}

const ResourceModal = ({ onClose, type = 'resource', to }) => {
  const store = useStore()
  const history = useHistory()

  const link =
    to ||
    (type === 'ReSource' && store.user?.[0]?.resource_role === 'admin') ||
    (type !== 'ReSource' &&
      store.user?.[0]?.member_type === 'admin' &&
      '/resource/options') ||
    '/pricing'

  const onClick = () => {
    if (store.user?.[0]?.member_type === 'admin') {
      history.push(link)
    } else {
      history.push('/edit-profile')
    }
  }

  return (
    <Modal onClose={onClose}>
      <Label>You are not subscribed to {types[type]}</Label>
      <div>
        If you’d like to learn more about {types[type]}
        <Link
          to={to}
          style={{ fontFamily: 'var(--medium)', color: '#70A3EC' }}
          disabled
        >
          {' '}
          click here
        </Link>
        , or click below to continue to sign up.
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Cancel onClick={onClose}>Cancel</Cancel>
        <Button text={`Configure ${types[type]}`} onClick={onClick} />
      </div>
    </Modal>
  )
}

export default ResourceModal
