import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { api } from '../../../api'
import flag from '../../../assets/images/flag.svg'
import flagGrey from '../../../assets/images/flag_grey.svg'
import Button from '../../../components/common/Button/button'
import Input from '../../../components/common/Input/index'
import { getError } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { Cancel } from '../Right/Tasks/Modal/style'
import { topInputs } from './inputs'
import AddNewModal from './Modal'
import { Title, TopLine } from './style'

const CreateNewComplianceItem = observer(
  ({ onClose, editing, getCurrentLegislative }) => {
    const store = useStore()
    const history = useHistory()
    const { control, reset, handleSubmit, errors, setValue, getValues, watch } =
      useForm()
    const [isStarred, setIsStarred] = useState()
    const [file, setFile] = useState(null)
    const [visible, setVisible] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState({})

    watch()

    const onSubmit = (values) => {
      const url = editing
        ? `/legislative_edit/${store.currentLegislative.id}/`
        : '/legislative_edit/'
      setIsLoading(true)

      api(
        url,
        getBody(values, file),
        editing ? 'PATCH' : 'POST',
        false,
        true
      ).then((data) => {
        setIsLoading(false)

        if (data.errors) return message.error(getError(data))

        store.setSingleValue('currentLegislative', null)
        message.success('Compliance Item created')
        getCurrentLegislative(data.id)
        history.push(`/tracking/main/?compliance_item=${data.id}`)
        onClose()
      })
    }

    useEffect(() => {
      if (editing) {
        reset(store.currentLegislative)
      }
    }, [editing])

    useEffect(() => {
      store.getLegislativeOptions()
    }, [])

    return (
      <div style={{ marginTop: 50 }}>
        {visible && (
          <AddNewModal
            type={visible}
            onClose={() => setVisible(null)}
            setValue={setValue}
            onSuccess={() => store.getLegislativeOptions()}
            value={getValues(visible)}
            values={getValues()}
            reset={reset}
            adding
          />
        )}
        <TopLine>
          <Title>
            {editing ? 'Update Compliance Item' : 'Create New Compliance Item'}
          </Title>
          <Button
            text='Priority'
            type='white'
            icon={isStarred ? flag : flagGrey}
            style={{ padding: '0 15px', flexDirection: 'row-reverse' }}
            styleIcon={{ margin: '0 0 0 10px' }}
            onClick={() => setIsStarred(!isStarred)}
          />
        </TopLine>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            rowGap: 20,
            columnGap: 50,
            marginBottom: 20,
          }}
        >
          {topInputs.map((item) => (
            <Input
              control={control}
              smallLabel
              {...item}
              reset={reset}
              options={store.legislative_options?.[item.options]}
              error={errors[item.name] && 'This field is required'}
              onAddNew={() => setVisible(item.name)}
              setValue={setValue}
              allowDisabled
              multiSelectItemColor='var(--text)'
            />
          ))}
        </div>
        <Input
          control={control}
          name='summary'
          type='textarea'
          smallLabel
          label='Summary'
          style={{ marginBottom: 15 }}
          validation={{ required: true }}
          error={errors.summary && 'This field is required'}
        />
        <Input
          control={control}
          name='notes'
          type='textarea'
          smallLabel
          label='Notes'
          style={{ marginBottom: 15 }}
          error={errors.notes && 'This field is required'}
        />
        <Input
          control={control}
          name='file'
          type='file'
          handleChange={(e) => setFile(e.target.files[0])}
          showFile
          label='Upload Document (.pdf only)'
          smallLabel
          validation={{ required: !editing }}
          error={errors.file && 'This field is required'}
          accept='application/pdf'
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 35,
          }}
        >
          <Cancel onClick={onClose}>Cancel</Cancel>
          <Button
            text={editing ? 'Update Compliance Item' : 'Create Compliance Item'}
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
          />
        </div>
      </div>
    )
  }
)

export default CreateNewComplianceItem

const keys = ['compliance_date', 'effective_date', 'end_date']

const getBody = (values, file) => {
  let formData = new FormData()

  for (let key in values) {
    if (Array.isArray(values[key]) ? values[key].length : values[key]) {
      if (key === 'adopted_date') {
        formData.append(
          'adopted_date',
          moment(values.adopted_date).format('YYYY-MM-DD')
        )
      } else if (keys.includes(key)) {
        formData.append(key, moment(values[key]).format('MM/DD/YYYY'))
      } else if (key === 'file') {
        formData.append('reference_document', file)
      } else {
        formData.append(key, values[key])
      }
    }
  }

  return formData
}
