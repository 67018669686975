import { observer } from 'mobx-react-lite'
import React from 'react'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { PlainText } from '../../../../components/common/PlainText/style'
import { useStore } from '../../../../mobx-store/context'
import { Title } from '../../components/common/style'

const BillingSchedule = observer(() => {
  const params = useParams()
  const store = useStore()

  const tab = params.type || 'tracking'

  const data =
    tab === 'tracking' ? store.company?.[0] : store.resourceTariffInfo

  return (
    <div style={{ marginBottom: 30 }}>
      <Title>Billing Schedule</Title>
      <PlainText>
        You have opted for the annual billing schedule. Your next payment will
        be collected on{' '}
        <span style={{ fontFamily: 'var(--semiBold)' }}>
          <Moment format='MM/DD/YYYY'>
            {(data?.payment_status === 'trial'
              ? data?.trial_end
              : data?.next_payment_date) || null}
          </Moment>
        </span>
        . Please note that any changes to your billing schedule and plan will
        not take effect until after this date.
        <ButtonOrange
          text={' Need to edit your Billing Information?'}
          style={{ display: 'contents' }}
          link={'/billing-info'}
        />
      </PlainText>
    </div>
  )
})

export default BillingSchedule
