import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import Input from '../../../../components/common/Input'
import { Label } from '../../../../components/common/Input/input.style'
import Modal from '../../../../components/common/ModalNew/index'
import { getError } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { Cancel } from '../../Right/Tasks/Modal/style'

const AddNewModal = ({
  type = 'jurisdiction',
  onClose,
  setValue,
  value,
  edit,
  data,
  onSuccess,
  onRemove,
  adding,
  reset: resetMain,
  values: mainValues,
}) => {
  const { control, handleSubmit, reset, errors } = useForm()
  const store = useStore()
  const [loading, setLoading] = useState(false)

  const url = edit
    ? types(edit, adding)[type].url + data.id + '/'
    : types(edit, adding)[type].url

  const onSubmit = (values) => {
    setLoading(true)
    api(url, values, edit ? 'PUT' : 'POST').then((data) => {
      setLoading(false)
      if (data.errors) return message.error(getError(data))

      store.loadSelect('companyOptions')
      store.loadSelect('currentMemberOptions')
      store.getCompany()

      resetMain &&
        resetMain({
          ...mainValues,
          [type]:
            type === 'topics' || type === 'line_of_business'
              ? [...(value ?? []), data.id]
              : data.id,
        })
      onClose && onClose()
      onSuccess && onSuccess()
    })
  }

  useEffect(() => {
    if (edit) reset(data)
  }, [])

  return (
    <Modal onClose={onClose}>
      <Label
        style={{
          marginBottom: 15,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {types(edit, adding)[type].title}
        {edit && (
          <Cancel style={{ fontSize: 16 }} onClick={onRemove}>
            Remove
          </Cancel>
        )}
      </Label>
      {types(edit, adding)[type].inputs.map((item) => (
        <Input
          control={control}
          key={item.key}
          {...item}
          style={{ ...item.style, marginBottom: 15 }}
          error={errors[item.name] && 'This field is required'}
          smallLabel
        />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Cancel onClick={onClose}>Cancel</Cancel>
        <Button
          text='Submit'
          onClick={handleSubmit(onSubmit)}
          loading={loading}
        />
      </div>
    </Modal>
  )
}

export default AddNewModal

const types = (edit, adding) => ({
  jurisdiction: {
    title: edit ? 'Edit Jurisdiction' : 'Add New Jurisdiction',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Jurisdiction Name',
      },
      {
        key: 1,
        name: 'code',
        label: 'Jurisdiction Abbreviation',
        style: { width: '50%' },
      },
    ],
    url: '/jurisdictions/',
  },
  topics: {
    title: edit ? 'Edit Topic' : 'Add New Topic',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Topic Name',
      },
    ],
    url: '/topics/',
  },
  [adding ? 'line_of_business' : 'lines_of_business']: {
    title: edit ? 'Edit Lines of Business' : 'Add New Lines of Business',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Line of Business Name',
      },
    ],
    url: '/lines_of_business/',
  },
})
