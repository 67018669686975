import { Table as AntTable } from 'antd'
import { toJS } from 'mobx'
import React, { useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import expandIcon from '../../../../../assets/images/expand.svg'
import hideIcon from '../../../../../assets/images/hide.svg'
import Button from '../../../../../components/common/Button/button'
import { getDescription } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import { Text } from '../../../components/style'
import { None, ViewButton } from '../../../Resource/RightSide/style'
import { Row, TableWrap } from '../style'
import ModalTerms from './Modal'
import { DescriptionLabel, Wrap } from './style'

const Item = ({ item, active, setActive, isMoreThanOne }) => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const location = useLocation()

  const [visible, setVisible] = useState(false)

  const isGuides = params.type === 'guides'

  const row = item

  const getArrItems = (ids, type) => {
    return ids
      .map(
        (item) =>
          store.resourceFilters[type]?.find((newItem) => newItem[0] === item)[1]
      )
      .join(', ')
  }

  const toggleBlock = () => {
    if (active) return setActive(null)
    setActive(item.id)
  }

  const onClick = (link) => {
    history.push(link)

    store.setResourcePrevLink([...store.resourcePrevLink, location.pathname])
  }

  const columns = [
    {
      title: 'Requirement',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Summary',
      dataIndex: 'code',
      key: 'code',
      render: (code, record) => {
        const summary = item?.row_data?.custom_fields_data.find((item) => {
          return item.code === code
        })?.summary

        if (summary && summary !== 'Not Specified')
          return (
            <ViewButton
              onClick={() =>
                onClick(
                  `/resource/details/${record.code}/${
                    isGuides ? item.id : params.id
                  }/${params.selectId}/${params.type}/${
                    isGuides ? params.id : item.id
                  }`
                )
              }
            >
              {summary}
            </ViewButton>
          )

        return <None>Not Specified</None>
      },
    },
    {
      title: '',
      dataIndex: 'button',
      key: 'button',
      width: 200,
      align: 'right',
      render: (_text, record) => {
        const summary = item?.row_data?.custom_fields_data.find(
          (item) => item.code === record.code
        )?.summary

        if (summary)
          return (
            <Button
              text={'View Across Jurisd.'}
              style={{
                margin: '0 auto',
                height: 24,
                color: '#969FA2',
              }}
              onClick={() =>
                onClick(
                  `/resource/across-jurisdiction/${record.code}/${params.id}/${params.selectId}/${params.type}`
                )
              }
              type='white'
            />
          )
      },
    },
  ]

  return (
    <Wrap>
      {visible && (
        <ModalTerms
          onClose={() => setVisible(null)}
          text={visible === 'citations' ? item[visible].replace(/;/g, '\n') : item[visible]}
          title={visible === 'defined_terms' ? 'Defined Terms' : 'Citations'}
        />
      )}
      <DescriptionLabel>
        Differentiator: {item.description || '-'}
      </DescriptionLabel>
      <Row style={{ marginTop: 0 }}>
        <div>
          <Text>Governing Agency</Text>
          <Text>{row.governing_agency}</Text>
        </div>
        <div>
          <Text>Line of Business</Text>
          <Text>
            {getDescription(store.lineOfBusinesses, row.line_of_business) || (
              <None>Not Specified</None>
            )}
          </Text>
        </div>
        <div>
          <Text>Applies To</Text>
          <Text>{getArrItems(row.applies_to, 'applies_to')}</Text>
        </div>
        <div>
          <Text>Specified Exclusion</Text>
          <Text>{row.specified_exclusions}</Text>
        </div>
      </Row>
      {row.notes && (
        <>
          <Text style={{ fontFamily: 'var(--semiBold)', fontSize: 14 }}>
            Notes
          </Text>
          <Text>{row.notes}</Text>
        </>
      )}
      {active && (
        <TableWrap>
          <AntTable
            columns={columns}
            dataSource={toJS(
              store.resourcesCurrentTopic?.guide_data?.custom_fields
            )}
            pagination={false}
            scroll={{ y: '50vh' }}
          />
        </TableWrap>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        {isMoreThanOne && (
          <Button
            text={active ? 'Hide' : 'Expand'}
            style={{
              backgroundColor: '#fff',
              border: '1px solid #455154',
              color: '#455154',
              padding: '0 15px',
            }}
            onClick={toggleBlock}
            icon={active ? hideIcon : expandIcon}
          />
        )}
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          {row.citations && (
            <Button
              text={'Citations'}
              style={{
                backgroundColor: '#455154',
                marginRight: 15,
              }}
              onClick={() => setVisible('citations')}
            />
          )}
          <Button
            text={'View Defined Terms'}
            style={{
              backgroundColor: '#455154',
            }}
            onClick={() => setVisible('defined_terms')}
          />
        </div>
      </div>
    </Wrap>
  )
}

export default Item
