import styled from 'styled-components'
import { TableWrap as Table } from '../components/style'

export const TableWrap = styled(Table)`
  tbody {
    .ant-table-cell {
      padding-left: 20px !important;
    }
  }
  .ant-table-thead th.ant-table-column-has-sorters {
    padding: 0 6px !important;
  }
`
