import styled from 'styled-components'
import { Title as GlobalTitle } from '../style'

export const Title = styled(GlobalTitle)`
  font-size: 28px;
  @media (max-width: 1200px) {
    font-size: 22px;
  }
`

export const ListDescription = styled('div')`
  font-family: var(--medium);
  font-size: 20px;
  color: var(--text);
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 25px;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`

export const ListItem = styled('div')`
  display: flex;
  align-items: flex-start;
  padding-left: 15px;
  font-family: var(--regular);
  font-size: 18px;
  color: var(--text);
  margin-bottom: 25px;
  border-top: 1px solid ${(p) => (p.line ? '#CACFD1' : 'transparent')};
  padding-top: ${(p) => (p.line ? '15px' : '0')};
  @media (max-width: 1200px) {
    font-size: 14px;
  }

  img {
    height: 17px;
    margin-right: 23px;
    margin-top: 10px;
  }
`
