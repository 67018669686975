import styled from 'styled-components'

export const Button = styled("div")`
  font-family: var(--medium);
  font-size: 0.875em;
  color: var(--orange);
  cursor: pointer;
  text-decoration: underline;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const Block = styled("div")`
  width: 100%;
  border: 1px solid var(--orange);
  display: ${(props) => (props.visible ? "flex" : "none")};
  justify-content: space-between;
  padding: 1em 6.25em;
`;

export const Item = styled("div")`
  font-family: var(--regular);
  font-size: 0.875em;
  color: var(--text);
  display: flex;
  flex-direction: column;
  text-align: center;
  span {
    font-family: var(--bold);
    margin-bottom: 0.7em;
  }
`;
