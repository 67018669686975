import { message } from 'antd'
import 'antd/dist/antd.css'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
  Redirect,
  Route,
  Router,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import Cookies from 'universal-cookie'
import { api } from './api'
import FullScreenLoader from './components/common/FullScreenLoader'
import TopLine from './components/TopLine/topLine'
import './css/global.css'
import {
  checkUrlAndWriteToLS,
  getReadStatuses,
  verifyToken,
} from './helpers/helpers'
import { useStore } from './mobx-store/context'
import NotFound from './pages/404'
import AboutPage from './pages/About'
import AddMember from './pages/Auth/AddMember/addMember'
import Billing from './pages/Auth/Billing/billing'
import BusinessInfo from './pages/Auth/BusinessInfo/businessInfo'
import ConfirmEmail from './pages/Auth/ConfirmEmail'
import Pricing from './pages/Auth/Pricing/pricing'
import Register from './pages/Auth/Register/register'
import Step1 from './pages/Auth/ResetPass/step1'
import Step2 from './pages/Auth/ResetPass/step2'
import Step3 from './pages/Auth/ResetPass/step3'
import StartDate from './pages/Auth/StartDate/startDate'
import TopicAndJur from './pages/Auth/TopicsAndJur/topic'
import Dashboard from './pages/Dashboard/dashboard'
import EmailConfirmForGA from './pages/EmailConfirm'
import HideMeAnalytics from './pages/HideMeAnalytics'
import HomeCompliance from './pages/Home/Compliance/home'
import Home from './pages/Home/Main/index'
import HomeResource from './pages/Home/Resource/index'
import PayForm from './pages/Payments/payForm'
import PendingBills from './pages/PendingBills'
import AddTeamMemberProfile from './pages/Profile/pages/AddTeamMember'
import BillingInfo from './pages/Profile/pages/Billing'
import BusinessInfoProfile from './pages/Profile/pages/BusinessInfo'
import CurrentPlan from './pages/Profile/pages/CurrentPlan'
import EditProfile from './pages/Profile/pages/EditProfile'
import EditTeamMemberProfile from './pages/Profile/pages/EditTeamMember'
import EmailSettings from './pages/Profile/pages/Email'
import ManageDictionaries from './pages/Profile/pages/ManageDictionaries/index'
import ManageTeamMembers from './pages/Profile/pages/ManageTeam'
import PasswordAndSecurity from './pages/Profile/pages/PasswordAndSecurity'
import ProductSettings from './pages/Profile/pages/ProductSettings'
import ResourceRoot from './pages/ResourceMain/index'
import PricingResource from './pages/ResourceMain/Register/Main'
import TermsAndPrivacy from './pages/Terms'
import TrialEnd from './pages/Tracking/NotPaid/trialOverAdmin'
import Tracking from './pages/Tracking/tracking'
import { noEmailConfirm, notAuth, notShowMenu } from './urls'

const cookies = new Cookies()

const routes = [
  {
    key: 2,
    auth: false,
    path: '/register',
    render: () => <Register />,
    exact: false,
  },
  {
    key: 3,
    auth: false,
    path: '/login',
    render: () => <Register login />,
    exact: false,
  },
  {
    key: 3,
    auth: false,
    path: '/businessInfo/:id?',
    disableWhenRegisterPassed: true,
    render: () => <BusinessInfo />,
    exact: false,
  },
  {
    key: 4,
    auth: false,
    path: '/addMember/:type(resource|main)?',
    // disableWhenRegisterPassed: true,
    render: () => <AddMember />,
    exact: false,
  },
  {
    key: 4,
    auth: false,
    path: '/pricing',
    disableWhenRegisterPassed: true,
    render: () => <Pricing />,
    exact: false,
  },
  {
    key: 5,
    auth: false,
    path: '/topicsAndJur',
    disableWhenRegisterPassed: true,
    render: () => <TopicAndJur />,
    exact: false,
  },
  {
    key: 6,
    auth: false, // todo
    path: '/select-pay-method',
    render: () => <PayForm />,
    exact: false,
  },
  {
    key: 7,
    auth: false,
    path: '/tracking/:tabName?/:id?',
    render: () => <Tracking />,
    exact: false,
  },
  {
    key: 8,
    auth: false,
    path: '/billing/:type(tracking|resources)?',
    render: () => <Billing />,
    exact: false,
  },
  {
    key: 9,
    auth: false,
    path: '/startDate',
    disableWhenRegisterPassed: true,
    render: () => <StartDate />,
    exact: false,
  },
  {
    key: 10,
    auth: true,
    path: '/dashboard',
    render: () => <Dashboard />,
    exact: false,
  },
  {
    key: 11,
    auth: false,
    path: '/reset-password-enter-email',
    render: () => <Step1 />,
    exact: false,
  },
  {
    key: 13,
    auth: false,
    path: '/reset-password-email',
    render: () => <Step2 />,
    exact: false,
  },
  {
    key: 14,
    auth: false,
    path: '/reset-password-confirm',
    render: () => <Step3 />,
    exact: false,
  },
  {
    key: 15,
    auth: true,
    path: '/edit-profile',
    render: () => <EditProfile />,
    exact: false,
  },
  {
    key: 15,
    auth: true,
    path: '/password-and-security',
    render: () => <PasswordAndSecurity />,
    exact: false,
  },
  {
    key: 15,
    auth: true,
    path: '/profile-email',
    render: () => <EmailSettings />,
    exact: false,
  },
  {
    key: 16,
    auth: true,
    path: '/product-settings/:type(tracking|resource)?',
    render: () => <ProductSettings />,
    exact: false,
  },
  {
    key: 17,
    auth: true,
    path: '/current-plan/:type(tracking|resource)?',
    render: () => <CurrentPlan />,
    exact: false,
  },
  {
    key: 18,
    auth: true,
    path: '/business-info',
    render: () => <BusinessInfoProfile />,
    exact: false,
  },
  {
    key: 19,
    auth: true,
    path: '/manage-team-members',
    render: () => <ManageTeamMembers />,
    exact: false,
  },
  {
    key: 20,
    auth: true,
    path: '/add-team-members-profile/:type(tracking|resource)?',
    render: () => <AddTeamMemberProfile />,
    exact: false,
  },
  {
    key: 21,
    auth: true,
    path: '/edit-team-members-profile/:id/:type(tracking|resource)',
    render: () => <EditTeamMemberProfile />,
    exact: false,
  },
  {
    key: 22,
    auth: true,
    path: '/billing-info',
    render: () => <BillingInfo />,
    exact: false,
  },
  {
    key: 23,
    path: '/home',
    render: () => <Home />,
  },
  {
    key: 3323,
    path: '/home-compliance',
    render: () => <HomeCompliance />,
  },
  {
    key: 33212,
    path: '/home-resource',
    render: () => <HomeResource />,
  },
  {
    key: 2312,
    path: '/terms-and-privacy',
    render: () => <TermsAndPrivacy />,
  },
  {
    key: 24,
    auth: true,
    path: '/resource',
    render: () => <ResourceRoot />,
  },
  {
    key: 29,
    auth: false,
    path: '/confirm-email',
    render: () => <ConfirmEmail />,
  },
  {
    key: 30,
    auth: false,
    path: '/resource-pricing',
    render: () => <PricingResource />,
  },
  {
    key: 25,
    path: '/about',
    render: () => <AboutPage />,
  },
  {
    key: 26,
    path: '/email-confirmed',
    render: () => <EmailConfirmForGA />,
  },
  {
    key: 27,
    path: '/analytic/hide-me',
    render: () => <HideMeAnalytics />,
  },
  {
    key: 29,
    path: '/company_error/:type',
    render: () => <TrialEnd />,
  },
  {
    key: 30,
    path: '/pending-bills',
    render: () => <PendingBills />,
  },
  {
    key: 31,
    path: '/manage-dictionaries/:type(topics|lines_of_business|jurisdiction)',
    render: () => <ManageDictionaries />,
  },
]

const App = observer(() => {
  const { pathname, search } = useLocation()
  const store = useStore()
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    checkUrlAndWriteToLS(pathname, search) // Also, redirects to login
  }, [])

  useEffect(() => {
    history.listen((newLocation) => {
      if (newLocation.pathname === '/reset-password-confirm/') {
        history.go(1)
      }
    })
  }, [])

  useEffect(() => {
    if (
      !noEmailConfirm.includes('/' + pathname.split('/')[1]) &&
      store.user.length &&
      !store.user[0]?.is_confirmed
    ) {
      history.push('/confirm-email')
    }
  }, [store.user, pathname])

  useEffect(() => {
    message.destroy()
  }, [pathname])

  const getCompanyId = () => api('/companies/', {}, 'GET')

  message.config({
    duration: 10,
  })

  useEffect(() => {
    if (cookies.get('pbm_token') && !store.company.length) {
      getCompanyId().then((data) => {
        if (data.errors) {
          cookies.remove('pbm_token')
          setLoading(false)
          store.reset()
          history.push('/home')
        } else {
          getReadStatuses()
          store.getUser()
          store.getTariffInfo()
          store.setSingleValue('company', data)
          store.setSingleValue(
            'maxUsers',
            data.tariff === 'total_tract' ? 10 : 5
          )
          store.loadSelect('companyOptions', () =>
            store.getSelectedJurisdictions()
          )
          setLoading(false)
          if (history.location.pathname === '/') {
            if (
              data[0].started_from_resource &&
              !data[0].is_registration_passed
            ) {
              return history.push('/resource')
            }
            history.push('/tracking')
          }
        }
      })
    } else {
      if (!notAuth.includes('/' + pathname.split('/')[1])) {
        history.push('/home')
      }
      cookies.remove('pbm_token')
      setLoading(false)
      store.reset()
    }
  }, [])

  if (loading) return <FullScreenLoader />

  const pages = (
    <Switch>
      {routes.map(
        ({ key, auth, path, exact, render, disableWhenRegisterPassed }) => {
          return auth ? (
            <AuthRoute
              path={path}
              render={render}
              exact={exact}
              key={key}
              store={store}
            />
          ) : (
            <CustomRoute
              path={path}
              render={render}
              exact={exact}
              key={key}
              disableWhenRegisterPassed={disableWhenRegisterPassed}
              store={store}
            />
          )
        }
      )}
      <Route component={NotFound} />
    </Switch>
  )

  return (
    <Router history={history}>
      {notShowMenu.includes('/' + pathname.split('/')[1]) ? (
        pages
      ) : (
        <>
          <TopLine />
          {pages}
        </>
      )}
    </Router>
  )
})

export default App

const CustomRoute = observer(
  ({ path, exact, render, disableWhenRegisterPassed, store }) => {
    if (disableWhenRegisterPassed && store.company[0]?.is_registration_passed) {
      cookies.remove('pbm_token')
      store.reset()
      return <Redirect to={'/login'} />
    }

    return <Route path={path} render={render} exact={exact} />
  }
)

const redirectToWarning = (store) => (
  <Redirect
    to={`/company_error/${
      store.company[0].payment_status === 'trial_is_over'
        ? store.company[0].payment_status
        : 'payment_' + store.company[0].payment_status
    }`}
  />
)

const checkIfShouldRedirect = (store) => {
  if (
    ['trial_is_over', 'error', 'cancelled'].includes(
      store.company?.[0]?.payment_status
    ) &&
    store.user?.[0]?.member_type !== 'admin'
  )
    return (
      <Redirect
        to={`/company_error/${
          store.company[0].payment_status === 'trial_is_over'
            ? store.company[0].payment_status
            : 'payment_' + store.company[0].payment_status
        }`}
      />
    )
}

const AuthRoute = ({ path, exact, render, store }) => {
  if (!verifyToken()) return <Redirect to={'/home'} />
  if (checkIfShouldRedirect(store)) return redirectToWarning(store)

  return <Route path={path} exact={exact} render={render} />
}
