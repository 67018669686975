import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../api'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../helpers/helpers'
import useDidMountEffect from '../../../../hooks/useEffectNotOnPageLoad'
import useModal from '../../../../hooks/useModal'
import { useStore } from '../../../../mobx-store/context'
import { Label } from '../right.style'
import List from './List'
import Modal from './Modal'
import { Placeholder, Wrap } from './style'

const Tasks = observer(
  ({
    data,
    getCurrentLegislative,
    postFile,
    isMyTasks,
    currentPage,
    loadingFile,
    complianceItem,
    getTasks,
    tasks,
    activeTab,
  }) => {
    const store = useStore()
    const { control, handleSubmit, errors, reset, getValues } = useForm()
    const { visible, handleClose, handleOpen } = useModal()
    const [shouldComplete, setShouldComplete] = useState(false)
    const [temporaryFiles, setTemporaryFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const params = useParams()
    const history = useHistory()

    const ID = data.id

    let search = new URL(document.location).searchParams
    let taskID = search.get('taskID')

    const onComplete = (values) => {
      api(
        `/tasks/${taskID || store.currentLegislative.id}/complete/`,
        { text: values.description },
        'POST'
      ).then((data) => {
        setLoading(false)

        if (!data.errors) {
          temporaryFiles.length > 0 &&
            temporaryFiles.map((item) => {
              postFile(null, taskID, item)
            })
          setTemporaryFiles([])
          getCurrentLegislative(ID, null, null, true)
          getTasks()
          store.loadActivity(store.currentLegislative.id, 1)
          handleClose()
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
          })
          history.push(
            `/tracking/${isMyTasks ? 'myTasks' : 'main'}/?compliance_item=${
              complianceItem || ID
            }`
          )
        }
        setShouldComplete(false)
      })
    }

    const onSubmit = (values) => {
      setLoading(true)
      if (shouldComplete) return onComplete(values)
      const url = taskID
        ? `/tasks/${taskID || store.currentLegislative.id}/`
        : '/tasks/'

      api(
        url,
        {
          legislative: store.currentLegislative.id,
          ...values,
        },
        taskID ? 'PATCH' : 'POST'
      ).then((data) => {
        setLoading(false)

        if (!data.errors) {
          handleClose()
          getCurrentLegislative(ID, null, null, true)
          store.loadActivity(ID, 1)
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
          })
          getTasks()
          history.push(
            `/tracking/${isMyTasks ? 'myTasks' : 'main'}?compliance_item=${
              complianceItem || params.id
            }`
          )
        }
      })
    }

    const addFiles = (files) => {
      setTemporaryFiles(files)
      reset({ ...getValues(), file: null })
    }

    useEffect(() => {
      getTasks()
    }, [store.currentLegislative])

    useDidMountEffect(() => {
      if (!visible) {
        reset({ file: null })
      }
    }, [visible])

    return (
      <Wrap>
        <Label>Tasks</Label>
        {visible && (
          <Modal
            control={control}
            onClose={() => {
              handleClose()
              reset({})
              setShouldComplete(false)
              setTemporaryFiles([])
            }}
            onSubmit={handleSubmit(onSubmit)}
            errors={errors}
            complete={shouldComplete}
            postFile={postFile}
            taskID={taskID}
            tasks={tasks}
            complianceItem={complianceItem}
            loadingFile={loadingFile}
            redCircleDate={data?.compliance_date}
            temporaryFiles={temporaryFiles}
            setTemporaryFiles={addFiles}
            loading={loading}
            getTasks={getTasks}
            activeTab={activeTab}
          />
        )}
        {!data.assignee && (
          <Placeholder>Assign a Compliance Owner.</Placeholder>
        )}
        {tasks.length > 0 && (
          <List
            tasks={tasks}
            complianceItem={complianceItem}
            isMyTasks={isMyTasks}
            taskID={taskID}
            onModalOpen={(data) => {
              reset(data)
              handleOpen()
            }}
            onComplete={() => {
              setShouldComplete(true)
              handleOpen()
            }}
            data={data}
            getTasks={getTasks}
          />
        )}

        {data.assignee &&
          checkRole(
            [store.user?.[0]?.member_type],
            ['owner', 'admin', 'compliance_owner']
          ) &&
          data.status !== 'closed' && (
            <ButtonOrange
              text={'Assign a Task'}
              onClick={() => {
                reset({
                  assignee: null,
                  due_date: null,
                  description: null,
                })
                handleOpen()
              }}
            />
          )}
      </Wrap>
    )
  }
)

export default Tasks
