import React from 'react'
import BillPeriodButtons from '../../../../components/common/BillPeriodButtons/buttons'
import Logo from '../../../../components/common/Logo/logo'
import StatusBar from '../../../../components/common/StatusBar/status'
import { Row } from './style'
import Total from './Total'

const TopLine = ({ total, active, setPeriod, annualDiscount, tariff }) => {
  const totalValue =
    active === 'month' ? total : total * 12 - total * 12 * annualDiscount

  return (
    <div>
      <Row style={{ position: 'relative', paddingTop: 30 }}>
        <Row
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            position: 'absolute',
            left: -180,
          }}
        >
          <Logo style={{ height: 52 }} />
          <StatusBar
            style={{ marginLeft: '1em', marginBottom: '1em' }}
            step={2}
          />
        </Row>
        <Row>
          <BillPeriodButtons
            paymentPeriod={active}
            setActiveButton={(active) => setPeriod(active)}
            styleContainer={{ borderColor: 'var(--text)' }}
            styleButton={{
              color: 'var(--text)',
              fontFamily: 'var(--medium)',
            }}
            activeTextColor={'#fff !important'}
            activeColor={'var(--text)'}
          />

          <Total total={totalValue} active={active} />
        </Row>
      </Row>
    </div>
  )
}

export default TopLine
