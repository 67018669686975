export const data = [
  {
    key: 0,
    title: 'ComplianceSource',
    titleColor: 'var(--blue)',
    description: (
      <>
        Captures new <span>laws</span>, regulations, and subregulatory guidance
        when adopted.
      </>
    ),
    listDescription: 'Platform to comply with new regulatory requirements.',
    link: '/home-compliance',
    list: [
      {
        key: 0,
        title: 'New requirements uploaded to dashboard',
      },
      {
        key: 1,
        title: 'Line of business identification',
      },
      {
        key: 2,
        title: 'Summaries by topic ',
      },
      {
        key: 3,
        title: 'Online centralized collaboration',
      },
      {
        key: 4,
        title: 'Track status',
      },
      {
        key: 5,
        title: 'Email notifications/reminders',
      },
      {
        key: 6,
        title: 'Archive closed assessments',
      },
    ],
  },
  {
    key: 0,
    title: 'ReSource',
    titleColor: 'var(--green)',
    description: (
      <>
        Captures <span>existing</span> laws and regulations.
      </>
    ),
    listDescription:
      'Platform to view summaries of existing regulatory requirements.',
    link: '/home-resource',
    list: [
      {
        key: 123,
        title: <span style={{ fontFamily: 'var(--bold)' }}>For Use When:</span>,
        noIcon: true,
      },
      {
        key: 0,
        title: 'Creating/Responding to RFPs',
      },
      {
        key: 1,
        title: 'Creating P&Ps',
      },
      {
        key: 2,
        title: 'Onboarding new business',
      },
      {
        key: 3,
        title: 'Developing new programs',
      },
      {
        key: 4,
        title: 'Line of business identification',
        line: true,
      },
      {
        key: 5,
        title: 'View various requirements across a single jurisdiction',
      },
      {
        key: 6,
        title: 'View single requirement across multiple jurisdictions',
      },
    ],
  },
]
