import React from 'react'
import Footer from '../Compliance/Footer'
import Demo from './Demo'
import Header from './Header'
import Tools from './Tools/index'

const Home = () => {
  return (
    <div>
      <Header />
      <Tools />

      <Demo />
      <Footer />
    </div>
  )
}

export default Home
