import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import closeIcon from '../../../../../../assets/images/close-grey.svg'
import deleteFileIcon from '../../../../../../assets/images/delete-orange.svg'
import Button from '../../../../../../components/common/Button/button'
import ButtonOrange from '../../../../../../components/common/ButtonOrange'
import { useStore } from '../../../../../../mobx-store/context'
import { Text } from '../../../right.style'
import { getFiles } from '../index'
import { Overlay, Title, Wrap } from '../style'

const ModalCompleted = ({ onClose, data, tasks, taskID, getTasks }) => {
  const store = useStore()
  const [files, setFiles] = useState([])

  const deleteFile = (id) => {
    store.deleteDocument(id, getTasks())
  }

  useEffect(() => {
    if (tasks.length > 0) {
      setFiles(getFiles(tasks, taskID))
    }
  }, [tasks])

  return (
    <div>
      <Overlay />
      <Wrap>
        <img src={closeIcon} alt='close-icon' onClick={onClose} id='close' />
        <Title>Completed Task By:</Title>

        <Title
          style={{
            fontSize: 18,
            marginTop: 20,
            marginBottom: 0,
          }}
        >
          {store.getMember(data?.assignee)}
        </Title>
        <span>
          <Moment format={'lll'}>{data?.comments?.[0]?.created}</Moment>
        </span>

        <Title style={{ fontSize: 14, marginTop: 20, marginBottom: 0 }}>
          Task Comment
        </Title>
        <Text style={{ margin: 0 }}>{data?.comments?.[0]?.text}</Text>

        {files?.length && (
          <>
            <Title style={{ fontSize: 14, marginTop: 20, marginBottom: 0 }}>
              Uploaded Document
            </Title>
            {files.map((item) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ButtonOrange text={item.name} link={item.link} defaultLink />
                <img
                  src={deleteFileIcon}
                  alt={'delete-icon'}
                  onClick={() => deleteFile(item.id)}
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                />
              </div>
            ))}
          </>
        )}

        <Button
          text={'Close'}
          style={{ marginLeft: 'auto', marginTop: 20 }}
          onClick={onClose}
        />
      </Wrap>
    </div>
  )
}

export default ModalCompleted
