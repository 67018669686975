import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../../../../api'
import PageSizeChanger from '../../../../../components/common/PageSizeChanger'
import CustomPagination from '../../../../../components/common/Pagination/pagination'
import { getError } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import Item from './Item'
import { Row, Title, Wrap } from './style'

const Bills = observer(({ styleWrap }) => {
  const store = useStore()
  const params = useParams()
  const [bills, setBills] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const deleteBill = (id) => {
    api(`/legislative /${id}/`, { assignee: null }, 'PATCH').then((data) => {
      if (!data.errors) {
        getBills(currentPage, pageSize)
        message.success('Assignee removed')
      } else {
        message.error(getError(data))
      }
    })
  }

  const getBills = (page = 1, pageSize = 10) => {
    api(
      `/legislative/?assignee=${params.id}&page=${page}&page_size=${pageSize}`,
      {},
      'GET'
    ).then((data) => {
      if (!data.errors) {
        setBills(data)
      } else {
        message.error(getError(data))
      }
    })
  }

  const onPageChange = (page) => {
    getBills(page)
    setCurrentPage(page)
  }

  const onPageSizeChange = (pageSize) => {
    getBills(1, pageSize)
    setCurrentPage(1)
    setPageSize(pageSize)
  }

  useEffect(() => {
    getBills()
  }, [store.member])

  return (
    <Wrap style={styleWrap}>
      <Title>
        Bills Assigned to this User
        <span>({bills?.count || 0})</span>
      </Title>
      {bills?.results?.map((item, index) => (
        <Item key={item.id} data={item} index={index} onDelete={deleteBill} />
      ))}
      <Row>
        <CustomPagination
          total={bills?.count}
          current={currentPage}
          pageSize={pageSize}
          onChange={onPageChange}
        />
        {bills?.results?.length > 0 && (
          <PageSizeChanger
            onChange={onPageSizeChange}
            minPageSize={pageSize}
            total={bills?.count}
          />
        )}
      </Row>
    </Wrap>
  )
})

export default Bills
