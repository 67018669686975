import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React from 'react'
import { Item, Wrap } from './style'

const TopLine = observer(({ type = 'snapshot', isProfile, viewOnly }) => {
  return (
    <Wrap isProfile={isProfile} viewOnly={viewOnly}>
      {!isProfile && <Item>Select</Item>}
      <Item style={{ justifyContent: 'center' }}>
        {type === 'snapshot' ? (
          <>
            Amount
            <br />
            Per Month
          </>
        ) : (
          <>
            Amount/Mo
            <br />
            PerJurisd.
          </>
        )}
      </Item>
      <Item>Guides</Item>
      <Item>Jurisdictions</Item>
    </Wrap>
  )
})

TopLine.propTypes = {
  type: PropTypes.string,
}

export default TopLine
