import styled from 'styled-components'

export const TopLine = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #c4cacc;
  margin-bottom: 20px;
`

export const Title = styled('div')`
  font-family: var(--semiBold);
  font-size: 24px;
  color: var(--blue);
`
