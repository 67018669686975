import Cookies from 'universal-cookie'

let retries = 0

export const api = async (url, body, method, noAuth, file) => {
  const cookies = new Cookies()

  const csrftoken = cookies.get('csrftoken')
  let token = cookies.get('pbm_token')

  let options = {
    method,
    headers: file
      ? {
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrftoken,
        }
      : !noAuth
      ? {
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        }
      : {
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        },
  }

  if (
    method === 'PATCH' ||
    method === 'POST' ||
    method === 'DELETE' ||
    method === 'PUT'
  ) {
    options = {
      ...options,
      body: file ? body : JSON.stringify(body),
    }
  }

  return fetch(`${process.env.REACT_APP_API_DEV}${url}`, options).then(
    (res) => {
      if (!res.ok) {
        if (res.status === 400) {
          if (res.headers.get('Content-Type') === 'application/json') {
            return res
              .json()
              .then((data) => ({ errors: data, status: res.status }))
          }
        }
        if (res.status === 401) {
          // return removeCookiesAndGoToRegister()
          // return api(url, body, method, noAuth);
        }

        if (res.status === 403) {
          return { errors: { trial_ended: ['Trial ended'], status: 403 } }
        }

        if (res.status === 500) {
          return { errors: { server: ['Server error'] } }
        }

        return res.json().then((data) => ({ errors: data, status: res.status }))
      }

      retries = 0

      if (res.status === 204) {
        return { deleted: true }
      }
      return res.json()
    }
  )
}

export const getCompanyId = async () => {
  const cookies = new Cookies()

  const csrftoken = cookies.get('csrftoken')
  const token = cookies.get('pbm_token')

  return await fetch(`${process.env.REACT_APP_API_DEV}/companies/`, {
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
      'X-CSRFToken': csrftoken,
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    if (!res.ok) {
      return res
        .json()
        .then((data) => ({ error: true, ...data, status: res.status }))
    }
  })
}

export const removeCookiesAndGoToRegister = () => {
  const cookies = new Cookies()
  retries = 0

  const allCookies = cookies.getAll()

  for (let key in allCookies) {
    if (key === 'pbm_token') {
      cookies.remove(key)
    }
  }

  window.location.href = '/home'
}
