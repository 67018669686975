import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Button from '../../../../../components/common/Button/button'
import { checkRole } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import AssignedItems from './AssignedItems'
import { blocksResource, blocksTracking } from './static'

const Right = ({ memberType, control, onSubmit, loading }) => {
  const store = useStore()
  const params = useParams()

  const isResource = params.type === 'resource'

  const member = store.member

  const blocks = isResource ? blocksResource : blocksTracking

  const roleCheck = isResource
    ? true
    : checkRole([memberType], ['admin', 'compliance_owner'])

  useEffect(() => {
    if (!store.resources) store.getResourceTopic()
  }, [])

  return (
    <>
      {roleCheck &&
        blocks.map((item) =>
          item.type === 'lineOfBusinessesMembers' &&
          !store.company[0]?.is_premium ? null : (
            <Controller
              control={control}
              name={item.name}
              defaultValue={[]}
              key={item.key}
              render={({ onChange, value }) => (
                <AssignedItems
                  items={value}
                  type={item.type}
                  title={item.title}
                  onChange={(value) => onChange(value)}
                  disabled={!member?.is_active}
                  findItemVar={item.findItemVar}
                />
              )}
            />
          )
        )}
      <Button
        text={'Save User'}
        style={{ backgroundColor: '#91C03E', padding: '0 3.25em' }}
        onClick={onSubmit}
        loading={loading}
        disabled={!member?.is_active}
      />
    </>
  )
}

export default Right
