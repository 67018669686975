import React from 'react'
import { Title } from '../Filters/style'

const TopLine = () => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr) 1.3fr',
        borderBottom: '1px solid #c4cacc',
        padding: '5px 0',
      }}
    >
      <Title>Bill Name:</Title>
      <Title>Jurisdiction:</Title>
      <Title>Status:</Title>
      <Title>Date:</Title>
      <div />
    </div>
  )
}

export default TopLine
