import styled from 'styled-components'
import { Wrap } from '../Resource/RightSide/style'

export const Container = styled('div')`
  width: 1319px;
  margin: 0 auto;
  padding: 0 10px;
`

export const TableWrap = styled(Wrap)`
  .ant-table-cell {
    border-right: 0;
    font-family: var(--medium);
  }
  .ant-table-row,
  .ant-table-thead {
    height: 45px;
  }
  .ant-table-container {
    border: 0;
  }
  .ant-table-row {
    &:nth-child(even) {
      background-color: rgba(34, 81, 148, 0.05);
    }
  }
`
