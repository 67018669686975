import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useHistory } from 'react-router-dom'
import { api } from '../../../../../../api'
import ButtonOrange from '../../../../../../components/common/ButtonOrange/index'
import { useStore } from '../../../../../../mobx-store/context'
import Item from '../../../../../ResourceMain/ResourceOptions/Guides/Item'
import TopLine from '../../../../../ResourceMain/ResourceOptions/Guides/TopLine'
import { Title } from '../../../../components/common/style'
import BillingSchedule from '../../bollingSchedule'
import Info from '../../info'

const ResourceTab = observer(() => {
  const store = useStore()
  const history = useHistory()
  const [guides, setGuides] = useState([])
  const tariffInfo = store.resourceTariffInfo

  const currentTariff = store.currentTariff

  const getPlanCost = () => {
    if (tariffInfo?.payment_period === 'year') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(
        (tariffInfo.month_price * 12 * (1 - tariffInfo?.discount)).toFixed(2)
      )
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(tariffInfo?.month_price)
    }
  }

  const leftData = [
    {
      key: 0,
      title: 'Plan Admin',
      text:
        store.members
          ?.filter((item) => item.member_type === 'admin')
          ?.map((item) => ` ${item.first_name} ${item.last_name}`)
          .toString() || '-',
    },
    {
      key: 1,
      title: 'Team Size',
      text: `${
        store.members?.filter((user) => user.have_access_to_resource)?.length
      } Members`,
    },
  ]
  const rightData = [
    {
      key: 0,
      title: 'Plan Cost',
      text: currentTariff && getPlanCost(),
    },
    {
      key: 1,
      title: 'Next Billing Date',
      text: (
        <Moment format={'MM/DD/YY'}>
          {tariffInfo?.payment_status === 'trial'
            ? tariffInfo.trial_end
            : tariffInfo?.next_payment_date}
        </Moment>
      ),
    },
    {
      key: 2,
      title: 'Billing Schedule',
      text:
        tariffInfo?.payment_period === 'month'
          ? 'Month'
          : 'Annual (Discount Applied)',
    },
  ]

  useEffect(() => {
    if (!store.members.length) store.loadMembers()
    if (!store.billingCards) store.getBillingCards()
    if (!store.jurs) store.loadSelect('companyOptions')
    if (!tariffInfo) store.getResourceTariffInfo()

    store.loadSelect('memberOptions')
  }, [])

  useEffect(() => {
    api('/resources/selected_guides/', {}, 'GET').then((data) => {
      if (!data.errors) {
        setGuides(data.items)
      }
    })
  }, [])

  return (
    <>
      <Title>
        ReSource | {guides.length} Guides{' '}
        {tariffInfo?.annual_discount_percent > 0 &&
          ` | Annual Discount (-${tariffInfo.annual_discount_percent}%)`}
        {tariffInfo?.guide_discount_percent > 0 &&
          ` | Guide Discount (-${tariffInfo.guide_discount_percent}%)`}
      </Title>
      <Info leftData={leftData} rightData={rightData} />
      <Title>ReSource</Title>
      <ButtonOrange
        text={'Need to change your plan?'}
        style={{
          fontFamily: 'var(--medium)',
          marginBottom: 15,
          textDecoration: 'underline',
        }}
        onClick={() => {
          history.push('/resource/options')
        }}
      />
      <div
        style={{
          borderBottom: '1px solid #eaeaea',
          paddingBottom: 60,
          marginBottom: 15,
        }}
      >
        <TopLine isProfile viewOnly />
        {guides.map((item, index) => (
          <Item item={item} index={index + 1} viewOnly />
        ))}
      </div>
      <BillingSchedule />
    </>
  )
})

export default ResourceTab
