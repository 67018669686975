import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useStore } from '../../mobx-store/context'
import Filters from './Filters'
import List from './List'
import TopLine from './List/topLine'
import { Description, Title, Wrap } from './style'

const PendingBills = observer(() => {
  const store = useStore()

  useEffect(() => {
    if (!store.bills) store.getBills()
  }, [])

  return (
    <Wrap>
      <div style={{ position: 'sticky', top: 90, backgroundColor: '#fff' }}>
        <Title>Pending Bills</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          ligula felis, mattis id ullamcorper a, ultricies eu enim. Nunc tempus
          dui quis mauris suscipit, in bibendum metus dictum.{' '}
        </Description>
        <Filters />
        <TopLine />
      </div>
      <List />
    </Wrap>
  )
})
export default PendingBills
