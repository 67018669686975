import { Empty } from 'antd'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../../mobx-store/context'
import Item from './Item/index'
import { Wrap } from './style'

const List = observer(() => {
  const store = useStore()

  return (
    <Wrap>
      {!store.bills && <Empty />}
      {store.bills?.map((item) => (
        <Item item={item} key={item.id} />
      ))}
    </Wrap>
  )
})

export default List
