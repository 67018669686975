import React from 'react'
import benefitsImg from '../../../../assets/images/Benefits@2x.svg'
import { ContainerGlobal, TitleGlobal } from '../styles'
import Item from './Item'
import { LeftSide, RightSide, Wrap } from './style'

const benefits = [
  {
    key: 0,
    title: 'Alerts when new laws pass',
    description:
      'Daily Updates. Compliance tasks are automatically created as new laws, regulations, and other regulatory guidance are issued.',
  },
  {
    key: 1,
    title: 'Streamline compliance process',
    description:
      'Enhance collaboration. Assign compliance assessments/tasks and maintain all  communications and documentation in a single channel.',
  },
  {
    key: 2,
    title: 'Compliance dashboard',
    description:
      'Automated tracking. Sort compliance tasks, including by topic, jurisdiction, compliance date, adopted date, and who it has been assigned too.',
  },
  {
    key: 3,
    title: 'Line of business identification',
    description:
      'Know applicability. Know if a new law or regulation applies to your line of business - Fully insured commercial, self-insured, Medicare, Medicaid, etc.',
  },
  {
    key: 4,
    title: 'Specific to the PBM industry',
    description:
      'Save time. No more weeding through bills and regulations that are not relevant to your business. No more searching the internet for subregulatory guidance.',
  },
  {
    key: 5,
    title: 'Robust summaries',
    description:
      'Understand requirements. Robust summaries of new requirements are provided by topic, allowing them to be easily understood and assigned for compliance assessment.',
  },
]

const Benefits = () => {
  return (
    <Wrap>
      <ContainerGlobal style={{ paddingTop: 70 }}>
        <LeftSide>
          <TitleGlobal>
            Compliance <br /> Made Easy
          </TitleGlobal>
          <img src={benefitsImg} alt='svg-image' />
        </LeftSide>
        <RightSide>
          {benefits.map((item) => (
            <Item
              key={item.key}
              title={item.title}
              description={item.description}
            />
          ))}
        </RightSide>
      </ContainerGlobal>
    </Wrap>
  )
}

export default Benefits
