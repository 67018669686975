import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Button from '../../../../components/common/Button/button'
import Input from '../../../../components/common/Input'
import { useStore } from '../../../../mobx-store/context'
import { Row, Wrap } from './style'

const Filters = observer(({ tabKey, jurs, resources }) => {
  const { control, handleSubmit, setValue } = useForm()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const location = useLocation()

  const isGuides = params.type === 'guides'

  const getOptions = (type) => {
    const options = store.resourceFilters?.[type]

    return options && [['all', 'All'], ...options]
  }

  const onSubmit = (values) => {
    const filteredValues = {}

    store.setSingleValue('resourceFiltersValues', {
      applies_to: values.applies_to,
      line_of_business: values.line_of_business,
      requirements: values.requirements,
    })

    for (let key in values) {
      if (values[key] !== 'all') filteredValues[key] = values[key]
    }

    const data = isGuides
      ? store.resourcesCurrentTopic?.rows
      : store.resourcesTable

    const filtered = data.filter((tableItem) => {
      const item = isGuides ? tableItem : tableItem.rows[0]

      if (
        (values.requirements === 'all'
          ? true
          : values.requirements === item?.requirements) &&
        (values.line_of_business === 'all'
          ? true
          : item.line_of_business?.find(
              (item) => item === values.line_of_business
            )) &&
        (values.applies_to === 'all'
          ? true
          : item.applies_to?.find((item) => item === values.applies_to))
      )
        return item
    })

    isGuides
      ? store.setSingleValue('resourceFilteredTopics', filtered)
      : store.setFilteredResources(filtered)
  }

  useEffect(() => {
    if (jurs && resources) {
      if (params.type === 'jurisdictions') {
        const id =
          (jurs.find((item) => item.id === +params.id) && +params.id) ||
          jurs?.[0]?.id

        store.getJurisdiction(id)
        setValue('topicsJurs', id)
        setResourceSelect(id)
      } else {
        const id =
          (resources.find((item) => item.id === +params.id) && +params.id) ||
          resources?.[0]?.id

        store.getResourceTopic(id)
        setValue('topicsJurs', id)
        setResourceSelect(id)
      }
    }
  }, [tabKey])

  const setResourceSelect = (id) => {
    store.setSingleValue('resourcesSelect', {
      ...store.resourcesSelect,
      value: id,
      type: tabKey === 0 ? 'jurisdictions' : 'guides',
      [tabKey === 0 ? 'juris' : 'guides']: id,
    })
  }

  const onViewAcrossFixClick = () => {
    store.setResourcePrevLink([...store.resourcePrevLink, location.pathname])

    history.push(
      `/resource/guides-jurisdictions-list/${params.type}/null/${store.resourcesSelect?.value}`
    )
  }

  const filters = () => (
    <>
      <Input
        name={'requirements'}
        control={control}
        type={'select'}
        options={getOptions('requirements')}
        selectVars={{ value: 0, name: 1 }}
        label={'Requirements'}
        smallLabel
        defaultValue={'all'}
        loading={!store.resourceFilters?.requirements}
        handleChange={handleSubmit(onSubmit)}
        allowClear={false}
        styleRow={{ maxWidth: 100 }}
      />
      <Input
        name={'line_of_business'}
        control={control}
        type={'select'}
        options={getOptions('line_of_business')}
        selectVars={{ value: 0, name: 1 }}
        label={'Line of Business'}
        smallLabel
        defaultValue={'all'}
        loading={!store.resourceFilters?.line_of_business}
        handleChange={handleSubmit(onSubmit)}
        allowClear={false}
        styleRow={{ maxWidth: 250 }}
      />
      <Input
        name={'applies_to'}
        control={control}
        type={'select'}
        options={getOptions('applies_to')}
        selectVars={{ value: 0, name: 1 }}
        label={'Applies To'}
        smallLabel
        defaultValue={'all'}
        loading={!store.resourceFilters?.applies_to}
        handleChange={handleSubmit(onSubmit)}
        allowClear={false}
        styleRow={{ maxWidth: 250 }}
      />
      {isGuides && (
        <Button
          text={'View Across Jurisd.'}
          style={{
            height: 20,
            fontSize: 14,
            padding: '0 10px',
            marginBottom: 10,
          }}
          onClick={onViewAcrossFixClick}
          type='white'
        />
      )}
    </>
  )

  const onChange = (id) => {
    setResourceSelect(id)
    const url = id
      ? `/resource/table/${params.type || 'jurisdiction'}/${id}`
      : `/resource/table/${params.type || 'jurisdiction'}`

    history.push(url)
    tabKey === 0 ? store.getJurisdiction(id) : store.getResourceTopic(id)
  }

  return (
    <Wrap>
      <Row>
        <Input
          name={'topicsJurs'}
          control={control}
          type={'select'}
          options={tabKey === 0 ? jurs : resources}
          showSearch={tabKey === 0}
          label={
            tabKey === 0 ? 'Select a Jurisdiction' : 'Select a ReSource Guide'
          }
          loading={!store.jurs?.length}
          placeholder={
            tabKey === 0 ? 'Select a Jurisdiction' : 'Select a Guide'
          }
          handleChange={onChange}
          styleRow={{ maxWidth: 300 }}
          allowClear={false}
        />
        {store.resourceFilters && isGuides
          ? store.resourceFilteredTopics && filters()
          : store.resourcesTable?.length > 0 && filters()}
      </Row>
    </Wrap>
  )
})

export default Filters
