import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import { getError } from '../../../../helpers/helpers'
import AddNewModal from '../../../Tracking/CreateNew/Modal'
import Item from './item'
import SortIcon from './sortIcon'
import { Description, Title } from './style'

const Tab = () => {
  const params = useParams()
  const [items, setItems] = useState([])
  const [sorter, setSorter] = useState(null)
  const [abrSorter, setAbrSorter] = useState(null)
  const [visible, setVisible] = useState(false)

  const type = params.type || 'topics'

  const sorting = (a, b) => {
    const sortVar = sorter ? 'name' : 'code'
    if (!sorter && !abrSorter) return items
    if (sorter === 'ascend' || abrSorter === 'ascend')
      return a[sortVar].localeCompare(b[sortVar])
    return -1 * a[sortVar].localeCompare(b[sortVar])
  }

  const sorterItems = [...items]
    .sort(sorting)
    ?.filter((item) => !item.is_deprecated)

  const getItems = () => {
    api(types[type].url, {}, 'GET').then((data) => {
      if (data.errors) return message.error(getError(data))

      setItems(data)
    })
  }

  useEffect(() => {
    getItems()
  }, [type])

  return (
    <div style={{ paddingBottom: 50 }}>
      <Title>{types[type].title}</Title>
      <Description>{types[type].description}</Description>
      {visible && (
        <AddNewModal
          type={type}
          onSuccess={() => {
            setVisible(false)
            getItems()
          }}
          onClose={() => setVisible(false)}
        />
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 20,
          paddingBottom: 15,
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        }}
      >
        <Title
          style={{
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSorter(sorterTypes[sorter])
            setAbrSorter(null)
          }}
        >
          {types[type].tableTitle}
          <SortIcon sorter={sorter} />
        </Title>
        {type === 'jurisdiction' && (
          <Title
            style={{
              fontSize: 14,
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setSorter(null)
              setAbrSorter(sorterTypes[abrSorter])
            }}
          >
            Abbreviation
            <SortIcon sorter={abrSorter} />
          </Title>
        )}
        <Button
          text='Add New'
          style={{ width: 72, height: 27, fontSize: 12 }}
          onClick={() => setVisible(true)}
        />
      </div>
      {sorterItems.map((item) => (
        <Item key={item.key} update={getItems} {...item} />
      ))}
    </div>
  )
}

export default Tab

const types = {
  topics: {
    title: 'Topics',
    url: '/topics/',
    tableTitle: 'Topic Name',
    description:
      'If your plan has PBMSource information you will see Topics below that can’t be edited. However you’ll be able to add new Topics and they wil be unique to your account. No other PBMSource clients will see or edit the Topics names you add.',
  },
  lines_of_business: {
    title: 'Lines of Business',
    url: '/lines_of_business/',
    tableTitle: 'Line of Business Name',
    description:
      'If your plan has PBMSource information you will see Lines of Business below that can’t be edited. However you’ll be able to add new Lines of Business and they wil be unique to your account. No other PBMSource clients will see or edit the Lines of Business names you add.',
  },
  jurisdiction: {
    title: 'Jurisdictions',
    url: '/jurisdictions/',
    tableTitle: 'Jurisdiction Name',
    description:
      'You’ll be able to add new Jurisdictions and they wil be unique to your account. No other PBMSource clients will see or edit the Jurisdictions names you add.',
  },
}

const sorterTypes = {
  null: 'ascend',
  ascend: 'descend',
  descend: null,
}
