import { observer } from 'mobx-react-lite'
import React from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import Item from './Item'
import { Row, Wrap } from './style'
import TopLine from './TopLine'

const discounts = {
  6: {
    discount: 25,
  },
  11: {
    discount: 50,
  },
  16: {
    discount: 65,
  },
}

export const getClosestNumber = (items) => {
  if (!items?.length) return 6
  const goal = items?.filter((item) => item.status !== 'paid')?.length
  const closest = Object.keys(discounts).reduce((prev, curr) => {
    const diff = Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev

    if (diff <= goal) return +curr

    return diff
  })

  return closest
}

export const getDiff = (items) => {
  const filtered = items?.filter((item) => item.status !== 'paid')
  return getClosestNumber(filtered) - filtered?.length
}

const Guides = observer(
  ({
    store,
    primaryJurisdictions,
    toggleItem,
    allowNext,
    isTotal,
    totalLineOfBusiness,
    items,
    onLineOfBusinessSelect,
    onJurisdictionChange,
    filterByCategory,
    onSubmit,
    staticItems,
    paidJurs,
  }) => {
    const checkedItems = items?.filter((item) => item.checked)

    const discountText =
      checkedItems?.length >= 16
        ? 'You have 65% Off'
        : `Select ${getDiff(checkedItems)} More Guide for 
    ${discounts[getClosestNumber(checkedItems)]?.discount}% Off`

    return (
      <Wrap>
        <Row>
          <Label>
            2. Select Guides{' '}
            <div
              style={{
                fontSize: 16,
                color: '#F2A122',
                marginLeft: 5,
                fontFamily: 'var(--bold)',
              }}
            >
              {discountText}
            </div>
          </Label>
          <Button text={'Next'} disabled={!allowNext} onClick={onSubmit} />
        </Row>
        {/* <TotalSource totalItemComponent={totalItemComponent} store={store} /> */}
        <TopLine
          type={'SelectSource'}
          store={store}
          filterByCategory={(value) => filterByCategory(value)}
        />
        {items?.map((item) => {
          return (
            <Item
              item={item}
              staticItems={staticItems}
              key={item.id}
              primaryJurisdictions={primaryJurisdictions}
              toggleItem={(guide_id, action) => toggleItem(guide_id, action)}
              onLineOfBusinessSelect={(guide_id, action) =>
                onLineOfBusinessSelect(guide_id, action)
              }
              onJurisdictionChange={(guide_id, arr) =>
                onJurisdictionChange(guide_id, arr)
              }
              isTotal={isTotal}
              totalLineOfBusiness={totalLineOfBusiness}
              paidJurs={paidJurs}
            />
          )
        })}
      </Wrap>
    )
  }
)

export default Guides
