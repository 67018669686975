import React, { useState } from 'react'
import { DocumentRight, DocumentWrap } from './right.style'
import download from '../../../assets/images/download.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import Validation from '../../../components/common/Validation/validation'
import Button from '../../../components/common/Button/button'
import { useStore } from '../../../mobx-store/context'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'

const Document = observer(({ item, index, onDeleted, legislative }) => {
  const store = useStore()
  const [show, setShow] = useState(false)

  const file = item?.file_name?.split('/')[item?.file_name.split('/').length -
  1]

  return (
    <>
      <DocumentWrap grey={index % 2 === 0}>
        <div>
          {file}
          {item.task &&
          <div style={{
            fontFamily: 'var(--regular)',
            fontSize: 14,
            color: '',
          }}>
            Task - {item.task.description}
          </div>
          }
        </div>
        <DocumentRight>
          <a href={item?.file} target={'_blank'} download>
            Download
          </a>
          <div onClick={() => setShow(true)}>
            Delete
          </div>
        </DocumentRight>
      </DocumentWrap>
      {show && (
        <Validation
          text={
            <div style={{ display: 'flex' }}>
              <Button
                text={'Cancel'}
                onClick={() => setShow(false)}
                style={{ marginRight: '.5em' }}
              />
              <Button
                text={'Delete'}
                onClick={() => {
                  store.deleteDocument(item?.id, () => {
                    onDeleted(item.file_name)
                    store.loadActivity(legislative, 1, null)
                  })
                  setShow(false)
                }}
                style={{ marginLeft: '.5em' }}
                border
              />
            </div>
          }
          upperText={
            <>
              Are You sure that you want to delete{' '}
              <span style={{ color: 'var(--blue)' }}>{file}</span>?
              This cannot be undone.
            </>
          }
          noLineThrough
        />
      )}
    </>
  )
})

export default Document
