import { Table as AntTable } from 'antd'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useStore } from '../../../mobx-store/context'
import Title from '../components/Title'
import { None, ViewButton } from '../Resource/RightSide/style'
import { TableWrap } from './style'

const Right = observer(({ currentItem }) => {
  const params = useParams()
  const history = useHistory()
  const store = useStore()
  const location = useLocation()

  const isGuides = params.type === 'guides'

  const getJurisdictionCode = (id) => {
    return store.resourceFilters?.jurisdictions.find(
      (item) => item[0] === id
    )[1]
  }

  const getArrItems = (ids, type) => {
    return ids
      ?.map(
        (item) =>
          store.resourceFilters[type]?.find((newItem) => newItem[0] === item)[1]
      )
      .join(', ')
  }

  const row = isGuides
    ? store.resourcesCurrentTopic.rows.find(
        (item) => item.jurisdiction === +params.topicId
      )
    : store.resourcesCurrent?.rows[0]

  const onClick = (record, summary) => {
    if (summary && summary !== 'Not Specified') {
      store.getJurisdiction(record.jurisdiction)
      history.push(
        `/resource/details/${currentItem.code}/${
          isGuides ? record.id : params.topicId
        }/${params.selectId}/${params.type}/${
          isGuides ? record.jurisdiction : record.id
        }`
      )
      store.setResourcePrevLink([...store.resourcePrevLink, location.pathname])
    }
  }

  const columns = [
    {
      title: 'Jurisdictions',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      align: 'left',
      width: 60,
      render: (id) => getJurisdictionCode(id),
      sorter: {
        compare: (a, b) =>
          getJurisdictionCode(a.jurisdiction).localeCompare(
            getJurisdictionCode(b.jurisdiction)
          ),
      },
    },
    {
      title: 'Differentiator',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 300,
      sorter: {
        compare: (a, b) => a.description.localeCompare(b.description),
      },
    },
    {
      title: 'Summary',
      dataIndex: 'row_data',
      key: 'row_data',
      align: 'left',
      width: 316,
      render: (_, record) => {
        const summary = record?.row_data?.custom_fields_data?.find(
          (item) => item?.code === currentItem?.code
        )?.summary
        return summary && summary !== 'Not Specified' ? (
          <ViewButton onClick={() => onClick(record, summary)}>
            {summary}
          </ViewButton>
        ) : (
          <None>Not Specified</None>
        )
      },
      sorter: {
        compare: (a, b) => {
          const summaryA = a?.row_data?.custom_fields_data?.find(
            (item) => item?.code === currentItem?.code
          )?.summary

          const summaryB = b?.row_data?.custom_fields_data?.find(
            (item) => item?.code === currentItem?.code
          )?.summary

          return summaryA.localeCompare(summaryB)
        },
      },
    },
  ]

  return (
    <div>
      <Title noJurisdiction title={currentItem?.name} />
      <div style={{ fontFamily: 'var(--regular)', color: 'var(--text)' }}>
        {' '}
        Select a summary to read more details
      </div>
      <TableWrap style={{ marginTop: 15 }}>
        <AntTable
          columns={columns}
          dataSource={toJS(store.resourceFilteredTopics)}
          pagination={false}
        />
      </TableWrap>
    </div>
  )
})

export default Right
