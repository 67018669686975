import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Input from '../../../components/common/Input'
import { getDescription, getJurisdictionCode } from '../../../helpers/helpers'
import List from '../components/List'
import { Item } from '../components/List/style'
import { Text } from '../components/style'

const Left = observer(({ params, store, active, setActive }) => {
  const history = useHistory()
  const { control } = useForm()

  const isGuides = params.type === 'guides'

  const hasJurisdictionContent = (currentItem) => {
    const info = currentItem.row_data.custom_fields_data.find(
      (item) => item.code === params.code
    )

    return (
      info?.detail &&
      info?.detail !== 'Not Specified' &&
      info?.summary &&
      info?.summary !== 'Not Specified'
    )
  }

  const onClick = () => store.getResourceTopic(params.itemId)

  const onChage = (id) => {
    if (isGuides) {
      store.getResourceTopic(id)
      history.push(
        `/resource/guides-jurisdictions-list/${params.type}/null/${id}`
      )
    } else {
      const jurId = store.resourcesCurrentTopic.rows.find(
        (item) => item.id === id
      )?.jurisdiction
      store.getJurisdiction(jurId)
      history.push(
        `/resource/details/${params.code}/${params.itemId}/${params.selectId}/${params.type}/${id}`
      )
    }
  }

  useEffect(() => {
    if (!store.resourcesCurrentTopic)
      store.getResourceTopic(params.itemId, null, () => {
        history.push('/resource/table/jurisdictions')
      })
  }, [])

  useEffect(() => {
    if (store.resourcesCurrentTopic)
      if (
        !store.resourcesCurrentTopic?.rows.find(
          (item) => +item.id === +params.jursId
        )
      ) {
        // history.push('/resource/table/jurisdictions')
        return
      }
  }, [store.resourcesCurrentTopic])

  return (
    <div>
      <Input
        name={'topicJurs'}
        control={control}
        type={'select'}
        options={isGuides ? store.resources : store.jurs}
        customOptions={
          isGuides
            ? null
            : store.resourcesCurrentTopic?.rows.map((item) => (
                <Select.Option value={item.id}>
                  {getDescription(store.jurs, item.jurisdiction)}
                </Select.Option>
              ))
        }
        label={`Select a ${isGuides ? 'ReSource Guide' : 'Jurisdiction'}`}
        defaultValue={isGuides ? +params.selectId : +params.jursId}
        handleChange={onChage}
      />
      <Text style={{ margin: '10px 0' }}>Select a Requirement</Text>
      {active && (
        <List
          options={
            isGuides
              ? store.resourcesCurrentTopic?.rows?.map((item) => (
                  <Item
                    key={item.id}
                    cursor={item.requirements}
                    active={item.id === active?.id}
                    id={item.id === active?.id && 'active-item'}
                    onClick={() => {
                      if (item.requirements && hasJurisdictionContent(item)) {
                        setActive(item)
                        history.push(
                          `/resource/details/${params.code}/${item.id}/${params.selectId}/${params.type}`
                        )
                      }
                    }}
                  >
                    {getJurisdictionCode(item.jurisdiction, store, 1)}
                    <span
                      style={{
                        color:
                          (!item.requirements ||
                            !hasJurisdictionContent(item)) &&
                          '#bbb',
                        width: 80,
                        textAlign: 'center',
                      }}
                    >
                      {item.requirements && hasJurisdictionContent(item)
                        ? 'Yes'
                        : 'No'}
                    </span>
                  </Item>
                ))
              : store.resourceFiltered
          }
          active={active}
          customOptions={isGuides}
          setActive={(item) => setActive(item)}
          handleClick={onClick}
          page='details'
        />
      )}
    </div>
  )
})

export default Left
