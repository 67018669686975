import { default as PropTypes, default as React, useState } from 'react'
import ModalTerms from '../../TopicsJursList/Right/Item/Modal'
import { Text } from '../style'
import { Jurisdiction, Wrap } from './style'

const Title = ({ jurisdiction, title, noJurisdiction }) => {
  const [visible, setVisible] = useState(false)
  return (
    <Wrap>
      {visible && <ModalTerms onClose={() => setVisible(false)} />}
      {!noJurisdiction && (
        <Jurisdiction>{jurisdiction?.substring(0, 2)}</Jurisdiction>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Text>{title}</Text>
      </div>
    </Wrap>
  )
}

Title.propTypes = {
  jurisdictions: PropTypes.string,
  title: PropTypes.string,
  noJurisdiction: PropTypes.bool,
}

export default Title
