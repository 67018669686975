import { observer } from 'mobx-react-lite'
import React from 'react'
import { Edit, ItemContent, ItemTop, ItemWrap, SubItem } from './summary.style'

const Item = observer(({ item, isResource }) => {
  return (
    <ItemWrap>
      <ItemTop
        orange={
          item.code === 'annual_discount' || item.code === 'guide_discount'
        }
      >
        {item.label}{' '}
        <span>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(item?.sum)}
        </span>
      </ItemTop>

      <ItemContent>
        <div>{item.description}</div>
      </ItemContent>

      {item.items &&
        item.items.map((subItem) => (
          <SubItem>
            {subItem.label}
            <span>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(subItem.sum)}
            </span>
          </SubItem>
        ))}

      {item.code !== 'discount' && (
        <Edit
          to={
            isResource
              ? '/resource/options'
              : item.code === 'pre_registration'
              ? '/startDate'
              : '/pricing'
          }
        >
          Edit
        </Edit>
      )}
    </ItemWrap>
  )
})

export default Item
