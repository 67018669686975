import React from 'react'
import checkIcon from '../../../../../assets/images/check-home.svg'
import Button from '../../../../../components/common/Button/button'
import { Description } from '../style'
import { ListDescription, ListItem, Title } from './style'

const Tool = ({
  title,
  titleColor,
  description,
  listDescription,
  list,
  link,
}) => {
  return (
    <div
    // style={{
    //   display: 'flex',
    //   flexDirection: 'column',
    //   justifyContent: 'space-between',
    // }}
    >
      <div>
        <Title style={{ color: titleColor }}>{title}</Title>
        <Description>{description}</Description>
        <ListDescription>{listDescription}</ListDescription>
        {list.map(({ title, examples, key, noIcon, line }) => (
          <ListItem key={key} line={line}>
            {!noIcon && <img src={checkIcon} alt='check-icon' />}
            <div>
              <div>{title}</div>
              {examples && (
                <div style={{ marginTop: 10, display: 'flex' }}>
                  Examples:{' '}
                  <div style={{ marginLeft: 15, fontStyle: 'italic' }}>
                    {examples.map(({ title, key }, index) => (
                      <div
                        key={key}
                        style={{
                          marginBottom: index !== examples.length - 1 ? 10 : 0,
                        }}
                      >
                        {title}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </ListItem>
        ))}
      </div>
      <Button
        text='Learn More'
        to={link}
        style={{ height: 46, marginTop: 10 }}
      />
    </div>
  )
}

export default Tool
