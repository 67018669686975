import { toJS } from 'mobx'
import { Observer } from 'mobx-react-lite'
import flag from '../../../assets/images/flag.svg'
import {
  getDate,
  getDescription,
  getStatusColor,
} from '../../../helpers/helpers'
import { getString } from '../Right/right'
import { statuses } from './table'
import {
  FirstLetterUppercase,
  Render,
  Status,
  TableHeaderTitle,
} from './table.style'

const getDataIndex = ({ field, isMyTasks, activeTab }) => {
  if (isMyTasks && activeTab !== 'closed') return ['legislative_company', field]
  return field
}

export const getRecord = ({ record, isMyTasks, activeTab }) =>
  isMyTasks && activeTab !== 'closed' ? record.legislative_company : record

const columnsComplianceDate = (params) => ({
  title: <TableHeaderTitle>Compliance Date</TableHeaderTitle>,
  dataIndex: 'compliance_date',
  key: 'compliance_date',
  align: 'left',
  sorter: true,
  width: 200,
  sortOrder:
    (params.sorter?.columnKey === 'compliance_date' && params.sorter.order) ||
    null,
  render: (text) => <Render>{getDate(text)}</Render>,
  ...(params.compliance_date || {}),
})

const columnsCore = (params) => [
  {
    title: <TableHeaderTitle>Reference Number</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'reference_number', ...params }),
    key: 'reference_number',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'reference_number' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text) => <Render>{text}</Render>,
    ...(params.reference_number || {}),
  },

  {
    title: <TableHeaderTitle>Topic</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'topics', ...params }),
    key: 'topics',
    sorter: true,
    width: 200,
    sortOrder:
      (params.sorter?.columnKey === 'topics' && params.sorter.order) || null,
    render: (_, record) => (
      <Render>
        <FirstLetterUppercase>
          {getString(
            params.store.topics,
            getRecord({ record, ...params })?.topics
          )}
        </FirstLetterUppercase>
      </Render>
    ),
    ...(params.topics || {}),
  },
  {
    title: <TableHeaderTitle>Juris</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'jurisdiction', ...params }),
    key: 'jurisdiction',
    width: 200,
    align: 'center',
    render: (text) => (
      <Render>{getDescription(params.store.jurs, text)}</Render>
    ),
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    ...(params.jurisdiction || {}),
  },
  ...(params.lob
    ? [
        {
          title: <TableHeaderTitle>Line of Business</TableHeaderTitle>,
          dataIndex: getDataIndex({ field: 'line_of_business', ...params }),
          key: 'line_of_business',
          width: 300,
          align: 'center',
          render: (text) => text,
          sorter: true,
          sortOrder:
            (params.sorter?.columnKey === 'jurisdiction' &&
              params.sorter.order) ||
            null,
          ...(params.line_of_business || {}),
        },
      ]
    : []),
]

const columnWithStatus = (params) => ({
  title: <TableHeaderTitle>Status</TableHeaderTitle>,
  dataIndex: 'status',
  key: 'status',
  width: '100px',
  sorter: true,
  sortOrder:
    (params.sorter?.columnKey === 'status' && params.sorter.order) || null,
  render: (text, record) => {
    return (
      <Observer>
        {() => (
          <Render
            style={{
              display: 'grid',
              gridTemplateColumns: '10px 1fr',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <Status bg={getStatusColor(text, getRecord({ record, ...params }))}>
              <div />
            </Status>
            {statuses?.[text]?.[0]}
          </Render>
        )}
      </Observer>
    )
  },
  ...(params.status || {}),
})

const columnsWithDue = ({ sorter, isMyTasks, activeTab }) => ({
  title: (
    <TableHeaderTitle>{isMyTasks ? 'Due Date' : 'Asgmt Due'} </TableHeaderTitle>
  ),
  dataIndex: isMyTasks ? 'due_date' : 'primary_due_date',
  key: isMyTasks ? 'due_date' : 'primary_due_date',
  align: 'left',
  sorter: true,
  sortOrder:
    (sorter?.columnKey === (isMyTasks ? 'due_date' : 'primary_due_date') &&
      sorter.order) ||
    null,
  render: (text, record) => (
    <Render
      style={{
        display: 'grid',
        gridTemplateColumns: isMyTasks ? '1fr' : '10px 1fr',
        alignItems: 'center',
        gap: 10,
      }}
    >
      {!isMyTasks && (
        <Status
          bg={getStatusColor(
            getRecord({ record, isMyTasks, activeTab })?.status,
            getRecord({ record, isMyTasks, activeTab })
          )}
        >
          <div />
        </Status>
      )}

      {getDate(text)}
    </Render>
  ),
})

const columnFlag = (params) => ({
  title: (
    <TableHeaderTitle>
      <img src={flag} alt='flag-icon' />
    </TableHeaderTitle>
  ),
  dataIndex: 'is_starred',
  key: 'is_starred',
  align: 'center',
  width: 50,
  sorter: true,
  sortOrder:
    (params.sorter?.columnKey === 'is_starred' && params.sorter.order) || null,
  render: (text) => (
    <Render style={{ display: 'flex', justifyContent: 'center' }}>
      {text && <img src={flag} alt='flag-icon' />}
    </Render>
  ),
  ...(params.is_starred || {}),
})

export const columnsCommon = (params) => [
  ...columnsCore(params),
  columnsComplianceDate(params),
  toJS(params.store.searchableObject?.primary_due_date_before)
    ? columnsWithDue(params)
    : columnWithStatus(params),
  columnFlag(params),
]

export const columnsTasks = (params) => [
  ...columnsCore(params),
  columnsWithDue(params),
  columnWithStatus(params),
  columnFlag(params),
]
