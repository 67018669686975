import { Table as AntTable } from 'antd'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useStore } from '../../../../mobx-store/context'
import { None } from '../../Resource/RightSide/style'
import { TableWrap } from '../style'
import './style.css'

const Table = observer(() => {
  const history = useHistory()
  const store = useStore()
  const params = useParams()
  const location = useLocation()

  const isGuides = params.type === 'guides'

  const getJurisdictionCode = (id) => {
    return store.resourceFilters?.jurisdictions.find(
      (item) => item[0] === id
    )[1]
  }

  const getArrItems = (ids, type) => {
    return (
      ids
        ?.map(
          (item) =>
            store.resourceFilters[type]?.find(
              (newItem) => newItem[0] === item
            )[1]
        )
        .join(', ') || ''
    )
  }

  const columns = [
    {
      title: 'ReSource Guides',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      align: 'left',
      width: 200,
      render: (text) => <div style={{ paddingLeft: 20 }}>{text}</div>,
    },

    {
      title: 'Requirement',
      dataIndex: 'rows',
      key: 'requirements',
      render: (text) => text && (text[0].requirements ? 'Yes' : 'No'),
      sorter: {
        compare: (a, b) => a.rows[0].requirements - b.rows[0].requirements,
      },
      align: 'center',
    },
    {
      title: 'Line of Business',
      dataIndex: 'rows',
      key: 'line_of_business',
      render: (text, record) =>
        text && text[0].line_of_business && text[0].line_of_business.length
          ? getArrItems(text[0].line_of_business, 'line_of_business')
          : record.rows[0].requirements
          ? 'Not Specified'
          : '',
      sorter: {
        compare: (a, b) => {
          return getArrItems(
            a.rows[0].line_of_business,
            'line_of_business'
          )?.localeCompare(
            getArrItems(b.rows[0].line_of_business, 'line_of_business')
          )
        },
      },
      align: 'center',
    },
    {
      title: 'Applies To',
      dataIndex: 'rows',
      key: 'applies_to',
      render: (text, record) =>
        text && text[0]?.applies_to.length ? (
          getArrItems(text[0]?.applies_to, 'applies_to')
        ) : record.rows[0].requirements ? (
          <None>Not Specified</None>
        ) : (
          ''
        ),
      sorter: {
        compare: (a, b) =>
          getArrItems(a.rows[0].applies_to, 'applies_to').localeCompare(
            getArrItems(b.rows[0].applies_to, 'applies_to')
          ),
      },
      align: 'center',
    },

    {
      title: 'Governing Agency',
      dataIndex: 'rows',
      key: 'governing_agency',
      render: (text, record) =>
        text &&
        (!text[0].governing_agency ||
          text[0].governing_agency === 'Not Specified') ? (
          record.rows[0].requirements ? (
            <None>Not Specified</None>
          ) : (
            ''
          )
        ) : (
          text[0].governing_agency
        ),
      sorter: {
        compare: (a, b) =>
          a.rows[0].governing_agency.localeCompare(b.rows[0].governing_agency),
      },
      align: 'center',
    },
  ]

  const columnsTopic = [
    {
      title: 'Juris.',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      render: (text) => (
        <div style={{ paddingLeft: 20 }}>{getJurisdictionCode(text)}</div>
      ),
      sorter: {
        compare: (a, b) =>
          getJurisdictionCode(a.jurisdiction).localeCompare(
            getJurisdictionCode(b.jurisdiction)
          ),
      },
      align: 'left',
    },
    {
      title: 'Differentiator:',
      dataIndex: 'description',
      key: 'description',
      render: (text) =>
        text.substring(0, 55) + (text.length > 55 ? '...' : '') || '-',
      align: 'left',
    },
    {
      title: 'Requirement',
      dataIndex: 'requirements',
      key: 'requirements',
      render: (text) => (text ? 'Yes' : 'No'),
      sorter: {
        compare: (a, b) => a.requirements - b.requirements,
      },
      align: 'center',
    },
    {
      title: 'Line of Business',
      dataIndex: 'line_of_business',
      key: 'line_of_business',
      render: (text, record) =>
        text?.length ? (
          getArrItems(text, 'line_of_business')
        ) : record.requirements ? (
          <None>Not Specified</None>
        ) : (
          ''
        ),
      sorter: {
        compare: (a, b) =>
          getArrItems(a.line_of_business, 'line_of_business')?.localeCompare(
            getArrItems(b.line_of_business, 'line_of_business')
          ),
      },
      align: 'center',
    },
    {
      title: 'Applies To',
      dataIndex: 'applies_to',
      key: 'applies_to',
      render: (text, record) =>
        text?.length ? (
          getArrItems(text, 'applies_to')
        ) : record.requirements ? (
          <None>Not Specified</None>
        ) : (
          ''
        ),
      sorter: {
        compare: (a, b) =>
          getArrItems(a.applies_to, 'applies_to').localeCompare(
            getArrItems(b.applies_to, 'applies_to')
          ),
      },
      align: 'center',
    },

    {
      title: 'Governing Agency',
      dataIndex: 'governing_agency',
      key: 'governing_agency',
      render: (text, record) =>
        !text || text === 'Not Specified' ? (
          record.requirements ? (
            <None>Not Specified</None>
          ) : (
            ''
          )
        ) : (
          text
        ),
      width: 200,
      sorter: {
        compare: (a, b) => a.governing_agency.localeCompare(b.governing_agency),
      },
      align: 'center',
    },
  ]

  const onRowClick = (record) => {
    return {
      onClick: () => {
        if (!record.requirements && !record.rows?.[0]?.requirements) return
        store.setSingleValue('resourcesCurrent', record)
        if (isGuides) {
          history.push(
            `/resource/guides-jurisdictions-list/${params.type}/${
              record.id || record.jurisdiction
            }/${store.resourcesSelect?.value}/${record.jurisdiction}`
          )
        } else {
          history.push(
            `/resource/guides-jurisdictions-list/${params.type}/${
              record.id || record.jurisdiction
            }/${store.resourcesSelect?.value}`
          )
        }
        store.setResourcePrevLink([
          ...store.resourcePrevLink,
          location.pathname,
        ])
      },
    }
  }

  return (
    <TableWrap>
      <AntTable
        dataSource={
          isGuides
            ? toJS(store.resourceFilteredTopics)
            : toJS(store.resourceFiltered)
        }
        columns={isGuides ? columnsTopic : columns}
        pagination={false}
        rowClassName={(record) =>
          (record.requirements || record.rows?.[0]?.requirements) &&
          'active-row'
        }
        onRow={onRowClick}
      />
    </TableWrap>
  )
})

export default Table
