import styled from 'styled-components'

export const Container = styled("div")`
  width: 100%;
  height: 100vh;
  display: flex;
  padding-top: 150px;
  justify-content: center;
`;

export const Grid = styled("div")`
  display: grid;
  grid-template-columns: 220px 1fr;
  width: 1000px;
`;
