import React, { useState } from 'react'
import { Block, Button, Item } from './moreInfo.style'
import Moment from 'react-moment'
import { getDate } from '../../../../helpers/helpers'

const MoreInfo = ({ data }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(!visible)}>
        {visible ? "<< Show Less Information" : "Show More Information >>"}
      </Button>
      <Block visible={visible}>
        <Item>
          <span>Adopted Date</span>
          <Moment format={"MM/DD/YYYY"}>{data?.adopted_date}</Moment>
        </Item>
        <Item>
          <span>Effective Date</span>
          {getDate(data?.effective_date)}
        </Item>
        <Item>
          <span>Compliance Date</span>
          {getDate(data?.compliance_date)}
        </Item>
        <Item>
          <span>End Date</span>
          {data?.end_date}
        </Item>
      </Block>
    </>
  );
};

export default MoreInfo;
