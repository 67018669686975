import React, { useMemo } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { getJurisdictionCode } from '../../../helpers/helpers'
import { Text } from '../components/style'
import Title from '../components/Title'
import { Wrap } from './style'

const Right = ({ store, params, currentItem }) => {
  const isGuides = params.type === 'guides'

  const info = useMemo(() => {
    return (
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.id === +params.jursId
      ) ||
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.id === +params.itemId
      ) ||
      store.resourcesCurrentTopic?.rows?.find(
        (item) => item.jurisdiction === +params.selectId
      )
    )?.row_data?.custom_fields_data?.find((item) => item.code === params.code)
  }, [store.resourcesCurrentTopic, params])

  const name = store.resourcesCurrentTopic?.guide_data?.custom_fields?.find(
    (item) => item.code === params.code
  )?.name

  return (
    <Wrap>
      <Title
        jurisdiction={
          isGuides
            ? getJurisdictionCode(currentItem?.jurisdiction, store)
            : store.jurs?.find((item) => item.id === +params.selectId)?.name
        }
        title={name}
        showButton
      />
      <Text style={{ fontFamily: 'var(--bold)', marginTop: 35 }}>Summary</Text>
      <Text>{info?.summary}</Text>
      <Text style={{ fontFamily: 'var(--bold)', marginTop: 35 }}>
        Detailed Description
      </Text>
      <Text style={{ paddingRight: 15 }}>
        {ReactHtmlParser(info?.detail?.replace(/\n/g, '<br />'))}
      </Text>
    </Wrap>
  )
}

export default Right
