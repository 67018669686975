import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import registerImage from '../../assets/images/register-bg.svg'
import Logo from '../../components/common/Logo/logo'
import Container from '../../components/containers/container'
import { Description, FormWrap, Right, Title, Wrap } from './authForm.style'
import Form from './Form/form'

const AuthForm = ({ login }) => {
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DEV}/users/?format=api`, {
      method: 'GET',
    })
  }, [])

  return (
    <Container wide>
      <Wrap>
        <FormWrap>
          <Logo large />
          <Title>{login ? 'Login to Your Account' : 'Register'}</Title>
          <Description>
            <span style={{ fontFamily: 'var(--medium)' }}>
              {login ? "Don't have an account yet? " : 'Already registered? '}
              <Link
                to={login ? '/register' : '/login'}
                style={{ color: 'var(--orange)', textDecoration: 'underline' }}
              >
                {login ? 'Sign Up' : 'Sign In'}
              </Link>
            </span>
          </Description>
          <Form login={login} />
        </FormWrap>
        <Right>
          <img src={registerImage} alt='#' />
        </Right>
      </Wrap>
    </Container>
  )
}

export default AuthForm
