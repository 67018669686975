import React, { useState } from 'react'
import { CheckboxWrap, Text, Wrap } from './agreement.style'
import { Checkbox } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../mobx-store/context'
import ButtonOrange from '../ButtonOrange'
import AgreementPopup from '../../../pages/Auth/Agreement/agreement'

const Agreement = observer(({ onChange, checked }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      {visible && <AgreementPopup onClick={() => setVisible(false)}/>}
      <Wrap>
        <CheckboxWrap>
          <Checkbox checked={checked} onChange={() => onChange(!checked)}/>
        </CheckboxWrap>
        <Text>
          By signing up, you agree to the{' '}
          <ButtonOrange
            style={{ display: 'contents', fontSize: '1em' }}
            text={'Terms of Service, Privacy Policy'}
            link={'/terms-and-privacy'}
          />
          .
        </Text>
      </Wrap>
    </>
  )
})

export default Agreement
