import { Spin } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { api } from '../../../../../api'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { checkRole, tariffs } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import { Row, Title } from '../../../components/common/style'
import AutoRenewal from '../autoRenewal'
import { Text } from '../style'
import Table from '../table'

const ComplianceTab = observer(() => {
  const store = useStore()
  const company = store.company[0]

  useEffect(() => {
    if (!store.tariffInfo) store.getTariffInfo()
  }, [])

  const members = store.members.filter((item) => item.have_access_to_tracking)

  const planName = `${tariffs[company?.tariff]} ${
    company?.is_premium ? ' + Pro Line of Business' : ''
  }`

  const patchAutoRenewal = (checked) => {
    api(
      `/companies/${store.company[0].id}/`,
      {
        auto_renew_subscription: checked,
      },
      'PATCH'
    ).then((data) => {
      if (!data.error) {
        store.setSingleValue('company', [data])
      }
    })
  }

  if (!company) return <Spin />

  return (
    <>
      <Row Row style={{ marginTop: '1em' }}>
        <Title>{planName}</Title>
        {checkRole([store.user?.[0]?.member_type], ['admin']) && (
          <ButtonOrange
            text={'Edit Your Plan'}
            link={'/current-plan'}
            style={{ marginLeft: '60px' }}
          />
        )}
      </Row>
      <Text style={{ marginTop: '10px' }}>
        {store.company[0]?.payment_period === 'month' ? 'Month' : 'Annual'}{' '}
        Subscription:{' '}
        <span style={{ fontFamily: 'var(--medium)' }}>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(getPrice(store))}
          /{store.company[0]?.payment_period === 'month' ? 'mo' : 'yr'}
        </span>
      </Text>
      <Text>{members?.length} Active Users</Text>
      {checkRole([store.user?.[0]?.member_type], ['admin']) && (
        <AutoRenewal
          checked={company?.auto_renew_subscription}
          onCheck={patchAutoRenewal}
          firstPaymentDate={company?.first_payment_date}
          nextDate={company?.next_year_date}
        />
      )}

      {store.members.length > 0 ? (
        <Table data={members} isProductSetting />
      ) : (
        <Spin />
      )}
    </>
  )
})

export default ComplianceTab

export const getPrice = (store) => {
  const company = store.company?.[0]

  if (company?.payment_period === 'month') {
    return company.month_price
  } else {
    return (
      company.month_price * 12 -
      company.month_price * 12 * store.tariffInfo?.discount
    )
  }
}
