import { Spin } from 'antd'
import { useGA4React } from 'ga-4-react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import Input from '../../../../components/common/Input'
import { useStore } from '../../../../mobx-store/context'
import { labelStyle } from '../right'
import { Block, Row } from '../right.style'

const CommentForm = observer(({ legislative }) => {
  const ga4React = useGA4React()
  const store = useStore()
  const { control, handleSubmit, reset, getValues, watch } = useForm()
  const [loading, setLoading] = useState(false)

  watch('content')

  const onSubmit = (value) => {
    const { content } = value

    setLoading(true)
    ga4React?.gtag('event', `comment`)

    reset({
      content: '',
    })

    api(
      '/legislative_comments/',
      {
        legislative,
        content: content.replace('\n', '<br/>\n'),
      },
      'POST'
    ).then((data) => {
      if (!data.errors) {
        store.setComment({
          ...data,
          first_name: store.user[0].first_name,
          last_name: store.user[0].last_name,
          descendants: [],
        })
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    store.loadMembers()
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block style={{ marginTop: '1em' }}>
        <Row style={{ alignItems: 'center' }}>
          <Input
            type={'mention'}
            control={control}
            name={'content'}
            placeholder={'Emilie Hester'}
            label={'Comment'}
            options={store.membersMentions}
            style={{ minHeight: '4.75em' }}
            styleContainer={{ marginRight: '1em' }}
            labelStyle={labelStyle}
            transparent
            showSearch
          />
          <Button
            text={loading ? <Spin /> : 'Post comment'}
            style={{ marginTop: '1.6em' }}
            disabled={!store.user.length || loading || !getValues('content')}
            loading={loading}
          />
        </Row>
      </Block>
    </form>
  )
})

export default CommentForm
