import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { api } from '../../../api'
import Button from '../../../components/common/Button/button'
import { getError } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { columnsCommon } from '../Table/columns'
import { Header, HeaderTitle, Render, TableStyled } from '../Table/table.style'
import ExpandItem from './expandItem'
import { Button as StyledButton, ExportText, Grid } from './style'
import TopLine from './topLine'

const pageSize = 20

const Reporting = () => {
  const store = useStore()
  const [data, setData] = useState([])
  const [searchString, setSearchString] = useState(null)
  const [sorter, setSorter] = useState(null)
  const [page, setPage] = useState(1)
  const [totals, setTotals] = useState(null)
  const [staticTotals, setStaticTotals] = useState(null)
  const [active, setActive] = useState(null)
  const [expanded, setExpanded] = useState([])
  const [loading, setLoading] = useState(false)

  const getData = () => {
    setLoading(true)
    api(
      `/legislative/reporting_groups/?page_size=${pageSize}&page=${page}` +
        (searchString
          ? '&' + store.getSearchableString({ ...searchString })
          : ''),
      {},
      'GET'
    ).then((data) => {
      setLoading(false)
      if (data.errors) return message.error(getError(data))

      setData(data)
    })
    api(
      `/legislative/reporting_totals/?${store.getSearchableString({
        ...searchString,
      })}`,
      {},
      'GET'
    ).then((data) => {
      if (data.errors) return message.error(getError(data))

      if (!staticTotals) setStaticTotals(data)
      setTotals(data)
    })
  }

  const getFile = () => {
    const cookies = new Cookies()
    const csrftoken = cookies.get('csrftoken')
    let token = cookies.get('pbm_token')

    fetch(
      `${
        process.env.REACT_APP_API_DEV
      }/legislative/reporting_groups_csv/?${store.getSearchableString({
        ...searchString,
      })}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `reporting_groups.csv`)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }

  const onSort = (sorter, page) => {
    setPage(page)
    const mutatedDirection = {
      ascend: '',
      descend: '-',
    }
    if (sorter.order) {
      setSorter(sorter)
      setSearchString({
        ...searchString,
        ordering: mutatedDirection[sorter.order] + sorter.columnKey,
      })
    } else {
      const obj = searchString || {}

      delete obj.ordering

      setSearchString({ ...searchString, ...obj })
      setSorter(sorter)
    }
  }

  const onSubmit = (values) => {
    setSearchString({ ...searchString, ...values })
    setPage(1)
  }

  const onFilter = (key) => {
    setActive(key === active ? null : key)
    if (key === active)
      return setSearchString({ ...searchString, status: null })
    setSearchString({ ...searchString, status: key })
  }

  useEffect(() => {
    getData()
  }, [searchString, page])

  return (
    <>
      <TopLine onSubmit={onSubmit} totals={staticTotals} />
      <Grid>
        <div>
          <Header style={{ marginTop: 15 }}>
            <HeaderTitle>Compliance Item Reporting</HeaderTitle>
          </Header>
          <div style={{ border: '1px solid #c4cacc' }}>
            <TableStyled
              rowKey={'group_number'}
              tableLayout='fixed'
              columns={columns({ sorter, store }).sort(
                (a, b) => a.order - b.order
              )}
              dataSource={data?.groups}
              onChange={(pagination, _, sorter) => {
                onSort(sorter, pagination.current)
                setPage(pagination.current)
              }}
              loading={loading}
              expandedRowKeys={expanded}
              expandable={{
                expandedRowRender: (record) => <ExpandItem data={record} />,
                rowExpandable: (record) => record.legislatives?.length > 1,
                expandIcon: ({ expanded, onExpand, record }) =>
                  record.legislatives?.length > 1 ? (
                    expanded ? (
                      <div
                        style={{ width: 34, fontSize: 24 }}
                        onClick={(e) => {
                          onExpand(record, e)
                          setExpanded([])
                        }}
                      >
                        -
                      </div>
                    ) : (
                      <div
                        style={{ width: 34, fontSize: 24 }}
                        onClick={(e) => {
                          onExpand(record, e)
                          setExpanded([record.group_number])
                        }}
                      >
                        +
                      </div>
                    )
                  ) : (
                    ''
                  ),
              }}
              pagination={{
                position: ['bottomLeft'],
                pageSize,
                hideOnSinglePage: true,
                total: data?.page_count * pageSize,
                current: page,
                showSizeChanger: false,
              }}
              size='small'
            />
          </div>
        </div>
        <div>
          {buttons.map((item) => (
            <StyledButton
              onClick={() => totals[item.key] && onFilter(item.key)}
              active={active === item.key}
              disabled={!totals?.[item.key]}
              color={item.color}
            >
              {item.title}{' '}
              <span style={{ color: item.color }}> {totals?.[item.key]}</span>
            </StyledButton>
          ))}
          <Button
            text='Export'
            onClick={getFile}
            style={{ width: '100%', marginTop: 15 }}
          />
          <ExportText>
            Will export all filtered <br /> results to a csv.
          </ExportText>
        </div>
      </Grid>
    </>
  )
}

export default Reporting

const buttons = [
  {
    key: 'unassigned',
    title: 'Unassigned',
    color: 'var(--blue)',
  },
  {
    key: 'in_progress',
    title: 'In Progress',
    color: 'var(--green)',
  },
  {
    key: 'closed',
    title: 'Closed',
    color: '#969FA2',
  },
]

const columns = ({ sorter, store }) =>
  columnsCommon({
    store,
    reference_number: {
      width: store.company?.[0]?.is_premium ? '20%' : '25%',
      order: 1,
      render: (text, record) => {
        if (record.legislatives?.length > 1) return <Render>{text}</Render>
        return (
          <Render>
            {text}{' '}
            <Link
              to={
                record.status === 'Closed'
                  ? `/tracking/closed?compliance_item=${record.legislative_ids[0]}`
                  : `/tracking/main?compliance_item=${record.legislative_ids[0]}`
              }
              style={{
                textDecoration: 'underline',
                color: 'var(--text)',
                marginLeft: 5,
              }}
              target='_blank'
            >
              View Details
            </Link>
          </Render>
        )
      },
    },
    jurisdiction: {
      render: (text, record) => text,
      sorter: false,
      width: store.company?.[0]?.is_premium ? '15%' : '20%',
      order: 2,
    },
    status: {
      render: (text) => text,
      sorter: false,
      width: '10%',
    },
    topics: {
      sorter: false,
      render: (text, record) => text,
      width: store.company?.[0]?.is_premium ? '15%' : '20%',
      order: 3,
    },
    line_of_business: {
      sorter: false,
      render: (text, record) => text,
      width: '15%',
      order: 4,
    },
    compliance_date: {
      sorter: false,
      width: '20%',
      order: 5,
      render: (text) => text || 'See notes',
    },
    is_starred: {
      sorter: false,
      width: '5%',
      order: 6,
    },
    lob: store.company?.[0]?.is_premium,
    sorter,
  })
