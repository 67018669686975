import styled from 'styled-components'
import { TableWrap as Table, Text } from '../../components/style'

export const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
  div {
    max-width: 300px;
    ${Text} {
      font-size: 14px;
      &:first-child {
        font-family: var(--semiBold);
      }
    }
  }
`

export const TableWrap = styled(Table)`
  .ant-table-body {
    border: 1px solid #c4cacc;
    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
  thead {
    tr {
      th {
        border: 0 !important;
      }
      .ant-table-cell {
        &:last-child,
        &:first-child {
          border-right: 1px solid #c4cacc;
        }
        border-right: 0;
      }
    }
  }
`
