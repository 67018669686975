import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../../api'
import Input from '../../../../../components/common/Input'
import { checkRole, getError } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import Bills from '../Bills/index'
import AdminModal from './AdminModal'
import AccessModal from './Modal/modal'
import Placeholder from './Placeholder/placeholder'
import { Button } from './Placeholder/style'
import Right from './right'
import { data, rolesCompliance, rolesResource, varTypes } from './static'
import { Wrap } from './style'

const Tab = observer(
  ({
    control,
    errors,
    memberType,
    onSubmit,
    member,
    register,
    setValue,
    deactivateUser,
    isResource,
    tab,
    handleDependencies,
    setIsAccessRemoving,
    reset,
    getValues,
  }) => {
    const store = useStore()
    const params = useParams()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [isAccess, setIsAccess] = useState(false)
    const [adminModal, setAdminModal] = useState(false)

    const myType = store?.user?.[0]?.member_type

    const roles = isResource ? rolesResource : rolesCompliance

    const company = store.company[0]

    const isMemberTypeDisabled =
      checkRole([myType], ['member']) ||
      (myType === 'compliance_owner' && memberType === 'admin')

    const removeAccess = () => {
      api(
        `/members/${member.id}/`,
        {
          [varTypes[tab].variable]: false,
        },
        'PATCH'
      )
        .then((data) => {
          if (data.ok || data.id) {
            store.setSingleValue('member', data)
            setVisible(false)
            if (
              !data.have_access_to_resource &&
              !data.have_access_to_tracking
            ) {
              deactivateUser()
            }
            setIsAccessRemoving(false)
          } else {
            setVisible(false)
            handleDependencies(data)
          }
        })
        .catch((err) => getError(err))
    }

    const toggleAccess = (variable, value) => {
      register(variable)
      setValue(variable, value, { shouldDirty: true })
      setIsAccess(value)
      setVisible(false)
    }

    const onClose = () => {
      setVisible(false)
      setIsAccessRemoving(false)
    }

    const onCancelAdmin = () => {
      reset({
        ...getValues(),
        [isResource ? 'resource_role' : 'member_type']:
          member[isResource ? 'resource_role' : 'member_type'],
      })
      setAdminModal(false)
    }

    const handleShowModal = () => {
      setVisible(true)
      setIsAccessRemoving(true)
    }

    const onRoleChange = (value) => {
      if (value === 'admin') setAdminModal(true)
    }

    useEffect(() => {
      setIsAccess(false)
    }, [params.type])

    // if (
    //   (!isResource && !store.user[0]?.have_access_to_tracking) ||
    //   (isResource && !store.user[0]?.have_access_to_resource)
    // )
    //   return (
    //     <ResourceModal
    //       type={!isResource ? 'tracking' : 'resource'}
    //       to={'/edit-profile'}
    //       onClose={() => history.push('/edit-profile')}
    //     />
    //   )

    if (
      (isResource &&
        (!member?.have_access_to_resource ||
          !company?.have_access_to_resource) &&
        !isAccess) ||
      (!isResource && !member?.have_access_to_tracking && !isAccess)
    )
      return (
        <Placeholder
          toggleAccess={toggleAccess}
          loading={loading}
          control={control}
          myType={myType}
        />
      )

    return (
      <Wrap>
        {adminModal && (
          <AdminModal
            onClose={() => setAdminModal(false)}
            onCancel={onCancelAdmin}
          />
        )}
        {visible && (
          <AccessModal
            onConfirm={removeAccess}
            onClose={onClose}
            member={member}
          />
        )}
        <div>
          <Input
            name={data[params.type || 'tracking'].inputName}
            label={'Role'}
            error={errors && errors.member_type && 'This field is required'}
            type={'select'}
            control={control}
            validation={{ required: true }}
            disabled={isMemberTypeDisabled}
            transparent
            style={{ marginBottom: errors && errors.jurisdictions && '0' }}
            customOptions={roles.map(
              (item) =>
                checkRole(
                  [store.user?.[0]?.member_type],
                  item.roles_that_can_change_current_role
                ) && (
                  <Select.Option key={item.key} value={item.value}>
                    {item.name}
                  </Select.Option>
                )
            )}
            loading={false}
            handleChange={onRoleChange}
          />
          {isResource ? null : <Bills styleWrap={{ marginTop: 20 }} />}
          {checkRole([myType], ['admin']) && (
            <Button onClick={handleShowModal}>
              Remove User From {isResource ? 'ReSource' : 'ComplianceSource'}
            </Button>
          )}
        </div>
        <div>
          <Right
            memberType={memberType}
            control={control}
            onSubmit={onSubmit}
            loading={loading}
          />
        </div>
      </Wrap>
    )
  }
)

export default Tab
