import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import PlainText from '../../../../components/common/PlainText'
import { useStore } from '../../../../mobx-store/context'
import IncludingBlock from './IncludingBlock'
import PlansModal from './Modal'
import { BlockWrap } from './style'

const tariffsText = {
  topic_tract:
    'The most popular package with XYZ businesses, the All topics / One Jurisdiction plan is optimal for businesses with comprehensive service offerings in 1 jurisdiction.',
  juris_tract:
    'The most complete package with XYZ businesses, the One Topic / All jurisdictions plan is optimal for businesses with limited-service offerings nationwide.',
  total_tract:
    'The most complete package that we offer, the TotalTrack plan is optimal for businesses serving several jurisdictions and who provide comprehensive service offerings.',
  select_tract:
    'The most flexible package that we offer, the SelectTrack plan is optimal for businesses with limited service offerings and/or limited-service offerings.',
}

const PlanInfo = observer(({ visible, setVisible }) => {
  const store = useStore()
  const history = useHistory()
  const [currentPlace, setCurrentPlace] = useState()

  useEffect(() => {
    if (!store.tariffInfo || !store.currentTariff) store.getTariffInfo()
    if (history.location.hash === '#change') setVisible(true)
  }, [])

  useEffect(() => {
    if (store.tariffInfo) setCurrentPlace(store.currentTariff?.place || null)
  }, [store.tariffInfo])

  return (
    <>
      {visible && (
        <PlansModal
          data={store.tariffInfo?.tariffs || []}
          discount={store.tariffInfo?.discount || 1}
          can_change_all_tariffs={store.tariffInfo?.can_change_all_tariffs}
          currentPlace={currentPlace}
          onClose={() => {
            setVisible(false)
            history.push('/current-plan')
          }}
        />
      )}
      <BlockWrap
        style={{
          marginTop: 20,
        }}
      >
        <PlainText style={{ lineHeight: '1.2em' }}>
          {tariffsText[store.company[0].tariff]}{' '}
          <ButtonOrange
            text={'Need to change your plan?'}
            style={{ display: 'contents', fontFamily: 'var(--medium)' }}
            onClick={() => {
              setVisible(true)
              history.push('/current-plan#change')
            }}
          />
        </PlainText>
        <IncludingBlock
          min={store.currentTariff?.included_members}
          code={store.currentTariff?.code}
        />
      </BlockWrap>
    </>
  )
})

export default PlanInfo
