import { Spin } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import BillPeriodButtons from '../../../components/common/BillPeriodButtons/buttons'
import { Container, GreenLineWrap, PlanName, TopTotal } from './billing.style'

const GreenLine = ({ data, paymentPeriod, changePaymentPeriod }) =>
  data ? (
    <GreenLineWrap>
      <Container style={{ display: 'flex', alignItems: 'center' }}>
        <BillPeriodButtons
          styleContainer={{ margin: 0 }}
          activeTextColor={'var(--green)'}
          paymentPeriod={paymentPeriod}
          setActiveButton={(active) => changePaymentPeriod(active)}
        />
        <PlanName>{data.tariff_name}</PlanName>
        <TopTotal>
          <span>Your Total</span> <br />${data.full_month_price}{' '}
          <span style={{ fontSize: '.65em' }}>/month</span>
          <div
            style={{
              fontSize: '.45em',
              fontStyle: 'italic',
              marginTop: '-.8em',
            }}
          >
            {data.month_price_discount !== 0 &&
              `You save $${data.month_price_discount} a month!`}
          </div>
        </TopTotal>
      </Container>
    </GreenLineWrap>
  ) : (
    <Spin />
  )

GreenLine.propTypes = {
  data: PropTypes.object,
  paymentPeriod: PropTypes.string,
}

export default GreenLine
