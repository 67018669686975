import styled from 'styled-components'
import { DescriptionGlobal } from '../../Compliance/styles'

export const Wrap = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  img {
    min-width: 1200px;
    max-width: 1400px;
    width: 80vw;
  }
  @media (max-width: 1400px) {
    img {
      min-width: auto;
      max-width: auto;
      width: 90vw;
    }
  }
  @media (max-width: 650px) {
    padding: 0 15px;
    height: auto;
    padding-top: 150px;
  }
`

export const Description = styled(DescriptionGlobal)`
  font-family: var(--medium);
  font-size: 24px;
  text-align: left;
  line-height: 28px;
  margin-top: 20px;
  margin-bottom: 50px;
  @media (max-width: 1300px) {
    font-size: 18px;
    line-height: 26px;
  }
`
