import React from 'react'
import Input from '../../../components/common/Input'
import Validation from '../../../components/common/Validation/validation'
import Activities from './activities'
import CommentContainer from './Comment/commentContainer'
import CommentForm from './CommentForm/commentForm'
import MyEditor from './Details'
import Documents from './documents'
import { Block } from './right.style'

export const showTabs = (obj) => {
  return {
    details: {
      render: () => <MyEditor legislative={obj.data?.id} />,
    },
    comments: {
      render: () => (
        <>
          <CommentForm legislative={obj.data?.id} />

          <Block style={{ paddingBottom: '1.5em' }}>
            <CommentContainer legislative={obj.data?.id} />
          </Block>
        </>
      ),
    },
    documents: {
      render: () => (
        <>
          <Input
            type={'file'}
            control={obj.control}
            name={'file'}
            placeholder={''}
            handleChange={(e) => {
              obj.postFile(e)
            }}
            loading={obj.loading.file}
            styleContainer={{ marginTop: '1em' }}
            label={'Upload Document'}
            labelStyle={obj.labelStyle}
            transparent
          />

          <Documents legislative={obj.data.id} getTasks={obj.getTasks} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    activity: {
      render: () => <Activities legislative={obj.data.id} />,
    },
  }
}
