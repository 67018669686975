import { Checkbox, Select } from 'antd'
import { useGA4React } from 'ga-4-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import Button from '../../../components/common/Button/button'
import ButtonOrange from '../../../components/common/ButtonOrange'
import Input from '../../../components/common/Input'
import useDidMountEffect from '../../../hooks/useEffectNotOnPageLoad'
import { useStore } from '../../../mobx-store/context'
import { Bottom, Inputs, ShowMore, TopLine, Wrap } from './search.style'

const taskStatuses = [
  {
    key: 0,
    value: 'incomplete',
    name: 'Incomplete',
  },
  {
    key: 1,
    value: 'complete',
    name: 'Completed',
  },
]

const Search = observer(
  ({ auth, closed, isTrial, onSearch, isMyTasks, activeTab }) => {
    const store = useStore()
    const ga4React = useGA4React()
    const location = useLocation()
    const { control, handleSubmit, reset, setValue } = useForm({
      reValidateMode: 'onChange',
    })
    const [active, setActive] = useState(false)
    const [showSearchBlock, setSearchShowBlock] = useState(false)
    const [isDateRangeVisible, setIsDateRangeVisible] = useState(false)

    const type = isMyTasks ? 'searchableObjectTasks' : 'searchableObject'

    const membersAdmins = store.members
      .filter(
        (item) =>
          item.member_type !== 'member' &&
          item.is_active &&
          item.have_access_to_tracking
      )
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      ))

    const assigner = new URLSearchParams(location.search).get('assigner')

    const onSubmit = (values) => {
      onSearch()

      if (values.search === (store.searchableObject?.search || null)) {
        try {
          ga4React?.gtag('event', `search_tracking_extended`)
        } catch (e) {
          console.error('analytic search error', e)
        }
      }

      let searchObject = store[type]

      closed ? delete searchObject.actual : delete searchObject.status

      for (let key in values) {
        if (values[key]) {
          if (key === 'adopted_date' || key === 'primary_due_date') {
            const start = moment(values[key].split('±')[0]).format('YYYY-MM-DD')
            const end = moment(values[key].split('±')[1]).format('YYYY-MM-DD')
            searchObject[key + '_after'] = start
            searchObject[key + '_before'] = end
          } else {
            searchObject[key] = values[key]
          }
        } else {
          delete searchObject[key]
        }
      }

      if (isMyTasks) {
        store.setSingleValue('searchableObjectTasks', {
          ...searchObject,
        })
        store.loadTasks({ page: 1 })
      } else {
        store.setSingleValue('legislativesStatus', closed ? 'closed' : 'actual')

        store.setSingleValue('searchableObject', {
          ...searchObject,
        })

        store.loadLegislatives({ page: 1, page_size: isTrial ? 10 : 20 })
      }
    }

    const shouldShowSearchBlock = () => {
      if (!store[type]) return setSearchShowBlock(false)

      const keysLength = Object.keys(store[type]).length

      if (keysLength === 1 && store[type].ordering) {
        return setSearchShowBlock(false)
      }
      if (keysLength > 0) {
        setSearchShowBlock(true)
      } else {
        setSearchShowBlock(false)
      }
    }

    const handleReset = () => {
      reset({
        topics: null,
        line_of_business: null,
        search: '',
        reference_number: '',
        reference_type: null,
        adopted_date: '',
        jurisdiction: null,
        status: null,
        assignee: null,
        task_assignee: null,
        primary_due_date: null,
      })

      store.setSingleValue('legislativesStatus', closed ? 'closed' : 'actual')

      store.setSingleValue('searchableObjectTasks', {})
      store.setSingleValue('searchableObject', {})

      store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
        page: 1,
        page_size: isTrial ? 10 : 20,
      })
    }

    useEffect(() => {
      store.loadSelect('currentMemberOptions')
      reset(store.searchableObject)
      if (!store.membersMentions.length || !store.members.length)
        store.loadMembers()
    }, [store.searchableObject])

    // Check if searchableObject or tab changes and detect if we should show search block
    useEffect(() => {
      shouldShowSearchBlock()
    }, [store.searchableObject, store.searchableObjectTasks])

    useEffect(() => {
      reset({
        ...store[type],
      })
    }, [active])

    useEffect(() => {
      if (assigner && !isMyTasks)
        store.setSingleValue('searchableObject', {
          ...store.searchableObject,
          assigner: +assigner,
        })
      let search = new URL(document.location).searchParams
      let assignee = search.get('assignee')

      if (assignee) {
        store.setSingleValue('searchableObject', {
          ...store.searchableObject,
          assignee: +assignee,
        })
      } else {
        const search = store.searchableObject

        if (search.assignee) {
          store.setSingleValue('searchableObject', null)
        }
      }
    }, [])

    useDidMountEffect(() => {
      setIsDateRangeVisible(false)
      handleReset()
    }, [activeTab])

    useEffect(
      () => () => {
        store.setSingleValue('searchableObject', {})
        store.setSingleValue('searchableObjectTasks', {})
        store.setSingleValue('legislativesStatus', 'actual')
      },
      []
    )

    useEffect(() => {
      store.getLegislativeOptions()
    }, [])

    const defaultDateRange = store.searchableObject?.adopted_date_after
      ? store.searchableObject?.adopted_date_after +
        '±' +
        store.searchableObject?.adopted_date_before
      : null

    const defaultPrimary = store.searchableObject?.primary_due_date_after
      ? store.searchableObject?.primary_due_date_after +
        '±' +
        store.searchableObject?.primary_due_date_before
      : null

    return (
      <Wrap>
        <TopLine>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              type={'text'}
              name={'search'}
              control={control}
              placeholder={'Search by Word'}
              transparent
              style={{ height: 40, width: 313 }}
              validation={{
                validate: () => true,
              }}
            />
            <Button
              text={'Search'}
              type={'main'}
              style={{ marginLeft: '0.625em' }}
              blue
              onClick={handleSubmit(onSubmit)}
            />
            {(showSearchBlock || store.searchableObject?.search) && (
              <ButtonOrange
                text={'Clear'}
                style={{ marginLeft: '.5em' }}
                onClick={() => handleReset()}
              />
            )}
          </div>
          {auth ? (
            <ShowMore style={{ textDecoration: 'none' }}>
              <Checkbox style={{ marginRight: '.1em' }} /> Only with Documents
            </ShowMore>
          ) : (
            <ShowMore
              onClick={() => {
                setActive(!active)
                setIsDateRangeVisible(false)
              }}
            >
              {active || showSearchBlock > 0
                ? '<< Less Search Options'
                : 'More Search Options >>'}
            </ShowMore>
          )}
        </TopLine>
        <Bottom
          active={active || showSearchBlock}
          isDateRangeVisible={isDateRangeVisible}
        >
          <Inputs style={{ marginBottom: '1em' }}>
            <Input
              type={'select'}
              name={'topics'}
              control={control}
              placeholder={'Select Topic'}
              options={store.legislative_options?.topics?.filter((item) =>
                item.is_system ? item.enabled : true
              )}
              label={'Topic'}
              handleChange={handleSubmit(onSubmit)}
              showDeprecatedLabel
              showDeprecated
              showSearch
              smallLabel
              transparent
            />
            <Input
              type={'select'}
              name={'line_of_business'}
              control={control}
              placeholder={'Select Line of Business'}
              disabled={!store.company[0]?.is_premium}
              label={'Line of Business'}
              handleChange={handleSubmit(onSubmit)}
              options={store.legislative_options?.line_of_business?.filter(
                (item) => (item.is_system ? item.enabled : true)
              )}
              showDeprecatedLabel
              showDeprecated
              showSearch
              smallLabel
              transparent
            />
          </Inputs>
          <Inputs>
            <Input
              type={'text'}
              name={'reference_number'}
              control={control}
              placeholder={'###ABCD'}
              label={'Reference Number'}
              handleChange={handleSubmit(onSubmit)}
              smallLabel
              transparent
              validation={{
                validate: () => true,
              }}
            />
            <Input
              type={'select'}
              name={'reference_type'}
              control={control}
              options={store.reference_types}
              placeholder={'###ABCD'}
              label={'Reference Type'}
              handleChange={handleSubmit(onSubmit)}
              smallLabel
              transparent
            />
          </Inputs>
          <Inputs>
            <Input
              type={'date_range'}
              name={'adopted_date'}
              control={control}
              placeholder={'Select Adopted Date'}
              label={'Adopted Date'}
              handleChange={handleSubmit(onSubmit)}
              defaultValue={defaultDateRange}
              isSearchOpened={active || showSearchBlock}
              smallLabel
              transparent
              reset={() => {
                setValue('adopted_date', null)
              }}
              handleShowDateRange={(value) => setIsDateRangeVisible(value)}
            />
            <Input
              type={'select'}
              name={'jurisdiction'}
              control={control}
              placeholder={'Select Jurisdiction'}
              options={store.legislative_options?.jurisdictions?.filter(
                (item) => (item.is_system ? item.enabled : true)
              )}
              label={'Jurisdiction'}
              handleChange={handleSubmit(onSubmit)}
              showDeprecatedLabel
              showDeprecated
              showSearch
              smallLabel
              transparent
            />
          </Inputs>
          <Inputs>
            <Input
              type={'select'}
              name={'status'}
              control={control}
              options={store.statuses?.filter((item) => item[0] !== 'closed')}
              placeholder={'Select Current Status'}
              label={'Current Status'}
              disabled={closed}
              handleChange={handleSubmit(onSubmit)}
              selectVars={{ value: 0, name: 1 }}
              smallLabel
              transparent
            />
            <Input
              type={'select'}
              name={isMyTasks ? 'compliance_owner' : 'assignee'}
              control={control}
              placeholder={'Select'}
              handleChange={handleSubmit(onSubmit)}
              label={'Compliance Owner'}
              customOptions={membersAdmins}
              showSearch
              smallLabel
              transparent
            />
          </Inputs>
          <Inputs>
            {isMyTasks ? (
              <Input
                type={'select'}
                name={'task_status'}
                control={control}
                customOptions={taskStatuses.map((item) => (
                  <Select.Option value={item.value} key={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
                placeholder={'Select'}
                handleChange={handleSubmit(onSubmit)}
                label={'Task Status'}
                smallLabel
                transparent
              />
            ) : (
              <Input
                type={'select'}
                name={'task_assignee'}
                control={control}
                customOptions={store.members
                  .filter(
                    (item) => item.is_active && item.have_access_to_tracking
                  )
                  .map((item) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.first_name + ' ' + item.last_name}
                    </Select.Option>
                  ))}
                placeholder={'Select'}
                handleChange={handleSubmit(onSubmit)}
                label={'Has a Task Assigned To'}
                smallLabel
                transparent
                showSearch
              />
            )}
            {!isMyTasks && (
              <Input
                type={'date_range'}
                name={'primary_due_date'}
                control={control}
                placeholder={'Select Adopted Date'}
                label={'Primary Due Date'}
                handleChange={handleSubmit(onSubmit)}
                defaultValue={defaultPrimary}
                isSearchOpened={active || showSearchBlock}
                dateRangeStyle={{ bottom: 0 }}
                smallLabel
                transparent
                reset={() => {
                  setValue('primary_due_date', null)
                  const search = store[type]

                  delete search.primary_due_date_before
                  delete search.primary_due_date_after

                  store.setSingleValue(type, search)
                }}
                handleShowDateRange={(value) => setIsDateRangeVisible(value)}
                noLimit
              />
            )}
          </Inputs>
        </Bottom>
      </Wrap>
    )
  }
)

export default Search
