export const data = {
  tracking: {
    inputName: 'member_type',
  },
  resource: {
    inputName: 'resource_role',
  },
}

export const rolesCompliance = [
  {
    key: 'admin',
    value: 'admin',
    name: 'Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'Team Member',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
  {
    key: 'compliance_owner',
    value: 'compliance_owner',
    name: 'Compliance Owner',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
]

export const rolesResource = [
  {
    key: 'admin',
    value: 'admin',
    name: 'Admin',
    roles_that_can_change_current_role: ['admin'],
  },
  {
    key: 'member',
    value: 'member',
    name: 'Team Member',
    roles_that_can_change_current_role: ['compliance_owner', 'admin'],
  },
]

export const blocksTracking = [
  {
    key: 0,
    name: 'topics',
    type: 'topicsMembers',
    title: 'Assigned Topics',
  },
  {
    key: 1,
    name: 'jurisdictions',
    type: 'jursMembers',
    title: 'Assigned Jurisdictions',
  },
  {
    key: 2,
    name: 'line_of_business',
    type: 'lineOfBusinessesMembers',
    title: 'Assigned Lines of Business',
  },
]

export const blocksResource = [
  {
    key: 0,
    name: 'resource_guides',
    type: 'resources',
    title: 'Assigned Guides',
    findItemVar: 'guide_name_id',
  },
  {
    key: 1,
    name: 'resource_jurisdictions',
    type: 'selectedJurisdictions',
    title: 'Assigned Jurisdictions',
  },
]

export const varTypes = {
  tracking: {
    variable: 'have_access_to_tracking',
  },
  resource: {
    variable: 'have_access_to_resource',
  },
}
