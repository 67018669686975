export const notShowMenu = [
  '/register',
  '/login',
  '/pricing',
  '/addMember',
  '/businessInfo',
  '/topicsAndJur',
  '/billing',
  '/startDate',
  '/reset-password-enter-email',
  '/reset-password-email',
  '/reset-password-confirm',
  '/agreement',
  '/terms-and-privacy',
  '/confirm-email',
  '/resource-pricing',
  '/resource',
  '/email-confirmed',
  '/analytic',
  '/company_error',
]

export const notAuth = [
  '/register',
  '/login',
  '/step1',
  '/reset-password-enter-email',
  '/reset-password-email',
  '/reset-password-confirm',
  '/home',
  '/terms-and-privacy',
  '/confirm-email',
  '/resource-pricing',
  '/about',
  '/email-confirmed',
  '/analytic',
  '/home-compliance',
  '/home-resource',
]

export const noEmailConfirm = [
  '/login',
  '/register',
  '/reset-password-enter-email',
  '/reset-password-email',
  '/reset-password-confirm',
  '/agreement',
  '/terms-and-privacy',
  '/confirm-email',
  '/home',
  '/email-confirmed',
  '/analytic',
  '/home-compliance',
]
