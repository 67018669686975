import React, { useEffect, useState } from 'react'
import { Block, DocumentsPlaceholder, Label } from './right.style'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../mobx-store/context'
import { Spin } from 'antd'
import CustomPagination from '../../../components/common/Pagination/pagination'
import Document from './document'
import Validation from '../../../components/common/Validation/validation'

const Documents = observer(({ legislative, getTasks }) => {
  const store = useStore()
  const [page, setPage] = useState(1)
  const [successDeleted, setSuccessDeleted] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (legislative) store.loadDocuments(legislative, '1', setLoading(false))
  }, [legislative])

  useEffect(() => {
    setTimeout(() => {
      setSuccessDeleted(null)
    }, 5000)
  }, [successDeleted])

  if (loading) return <Spin/>

  if (!store.documents.results) return null

  const documents = store.documents.results

  return (
    <Block style={{ marginTop: '1em', paddingBottom: '1em' }} bordered>
      <Label>Documents</Label>
      {documents.length <= 0 && (
        <DocumentsPlaceholder>
          There are no documents associated with this item yet.
        </DocumentsPlaceholder>
      )}
      {documents.map((item, index) => (
        <Document
          item={item}
          index={index}
          key={item?.id}
          onDeleted={(file) => {
            setSuccessDeleted(file)
            getTasks()
          }}
          legislative={legislative}
        />
      ))}
      {successDeleted && (
        <Validation
          noLineThrough
          text={<span>{successDeleted} was deleted</span>}
        />
      )}

      <CustomPagination
        total={store.documents.count}
        pageSize={'10'}
        current={page}
        onChange={(page) => {
          store.loadDocuments(legislative, page)
          setPage(page)
        }}
      />
    </Block>
  )
})

export default Documents
