import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TopLine from '../../../components/TopLine/topLine'
import { useStore } from '../../../mobx-store/context'
import Header from '../components/Header'
import { Layout } from '../components/style'
import { Container, Wrap } from '../Resource/style'
import Left from './left'
import Right from './right'

const ViewAcrossJuris = observer(() => {
  const params = useParams()
  const store = useStore()
  const [currentItem, setCurrentItem] = useState(null)

  const isGuides = params.type === 'guides'

  useEffect(() => {
    if (!store.lineOfBusiness) store.loadSelect('companyOptions')
    if (!store.resourcesCurrent) store.getJurisdiction(+params.selectId)
    if (!store.resourceFilters) store.getResourceFilters()
    if (!store.lineOfBusiness) store.loadSelect('companyOptions')
  }, [])

  useEffect(() => {
    if (store.resourceFiltered) {
      store.setSingleValue(
        'resourcesCurrent',
        store.resourceFiltered?.find((item) => item.id === +params.topicId)
      )
    }
  }, [store.resourceFiltered])

  useEffect(() => {
    if (params.code)
      setCurrentItem(
        store.resourcesCurrentTopic?.guide_data?.custom_fields?.find(
          (item) => item?.code === params.code
        )
      )
  }, [store.resourcesCurrentTopic])

  return (
    <>
      <TopLine />
      <Wrap>
        <Container>
          <Header goBack />
          <Layout>
            <Left
              onItemChoose={(item) => setCurrentItem(item)}
              currentItemCode={currentItem?.code}
            />
            {isGuides
              ? currentItem &&
                store.lineOfBusinesses &&
                store.resourcesCurrentTopic && (
                  <Right currentItem={currentItem} />
                )
              : currentItem &&
                store.lineOfBusinesses &&
                store.resourcesCurrent &&
                store.resourceFilters && <Right currentItem={currentItem} />}
          </Layout>
        </Container>
      </Wrap>
    </>
  )
})

export default ViewAcrossJuris
