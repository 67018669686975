import styled from 'styled-components'

export const Wrap = styled('div')`
  margin-top: 1em;
`

export const TopLine = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ShowMore = styled('div')`
  color: var(--orange);
  text-decoration: underline;
  font-family: var(--medium);
  font-size: 0.875em;
  cursor: pointer;

  .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1) translate(-65%, -40%);
  }
`

export const Bottom = styled('div')`
  max-height: ${(props) => (props.active ? '420px' : '0')};
  overflow-y: ${p => p.isDateRangeVisible ? 'visible' : 'hidden'};
  transition: all 0.2s ease-in-out;
  border: 1px solid ${(props) => (props.active
    ? 'var(--orange)'
    : 'transparent')};
  padding: ${(props) => (props.active ? '1em' : 0)};
  margin: 1em 0;
`

export const Inputs = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-bottom: 1em;
`
