import React from 'react'
import { Wrap } from './skipButton.style'

const SkipButton = ({ mainText, secondaryText, to, style, inline }) => {
  return (
    <Wrap to={to} style={style} inline={inline}>
      <span>{mainText}</span>
      <span>{secondaryText}</span>
    </Wrap>
  )
}

export default SkipButton
