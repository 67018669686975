import { message } from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../../../api'
import editIcon from '../../../../assets/images/create_24px.svg'
import { getError } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import AddNewModal from '../../../Tracking/CreateNew/Modal/index'
import RemoveModal from './removeModal'

const Item = ({ name, company, id, update, code, is_deprecated }) => {
  const store = useStore()
  const params = useParams()
  const [visible, setVisible] = useState(false)
  const [visibleRemove, setVisibleRemove] = useState(false)
  const companyId = store.company?.[0]?.id

  const isActive = companyId === company && !is_deprecated

  const onRemove = () => {
    api(
      `/${
        params.type === 'jurisdiction' ? 'jurisdictions' : params.type
      }/${id}/`,
      {},
      'DELETE'
    ).then((data) => {
      if (data.errors) return message.error(getError(data))

      update()
    })
  }

  return (
    <>
      {visibleRemove && (
        <RemoveModal
          onRemove={() => {
            onRemove()
            setVisibleRemove(false)
          }}
          name={name}
          onClose={() => setVisibleRemove(false)}
        />
      )}
      {visible && (
        <AddNewModal
          type={params.type}
          data={{ name, id, code }}
          onClose={() => {
            setVisible(false)
            update()
          }}
          onRemove={() => {
            setVisibleRemove(true)
            setVisible(false)
          }}
          edit
        />
      )}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns:
            params.type === 'jurisdiction' ? '1fr 200px 1fr' : '1fr 1fr',
          alignItems: 'center',
          fontFamily: 'var(--regular)',
          fontSize: 12,
          height: 50,
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          padding: '0 10px',
          opacity: isActive ? 1 : 0.5,
        }}
      >
        <div>{name}</div>
        {params.type === 'jurisdiction' && (
          <div style={{ textAlign: 'center' }}>{code}</div>
        )}
        <div style={{ textAlign: 'right' }}>
          <img
            src={editIcon}
            alt='edit'
            style={{
              cursor: isActive ? 'pointer' : 'not-allowed',
            }}
            onClick={isActive ? () => setVisible(true) : null}
          />
        </div>
      </div>
    </>
  )
}

export default Item
