import styled from 'styled-components'
import { CalendarWrap } from '../../../pages/Auth/StartDate/startDate.style'

export const Wrap = styled('div')`
  position: absolute;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  justify-content: space-between;
  border: 1px solid var(--orange);
  z-index: 999;
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin-top: 10px;

  .react-calendar {
    width: 100%;
  }

  ${CalendarWrap} {
    width: 350px;
  }
`

export const Close = styled('img')`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`
