import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import Moment from 'react-moment'
import { api } from '../../../../api'
import documentIcon from '../../../../assets/images/description_24px.svg'
import Button from '../../../../components/common/Button/button'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { getDescription, getError } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { Text, Wrap } from './style'

const Item = observer(({ item, secondary, active }) => {
  const store = useStore()
  const [versions, setVersions] = useState([])

  const toggleShow = () => {
    if (versions.length) return setVersions([])
    api(`/pending_bills/${item.id}/versions/`, {}, 'GET').then((data) => {
      if (data.errors) return message.error(getError(data))

      setVersions(data.versions)
    })
  }

  const toggleAlert = () => {
    if (secondary) return

    api(`/pending_bills/${item.id}/toggle_subscribe/`, {}, 'POST').then(
      (data) => {
        if (!data.errors) {
          store.getBills()
        }
      }
    )
  }

  return (
    <>
      <Wrap active={versions.length || active}>
        <Text
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 15,
          }}
          as='a'
          target='_blank'
          href={item.file}
        >
          {item.name} <img src={documentIcon} alt='document' />
        </Text>
        <Text>{getDescription(store.jurs, item.jurisdiction)}</Text>
        <Text>{item.status}</Text>
        <Text>
          <Moment format={'MM/D/YYYY'}>{item?.date}</Moment>
        </Text>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: 15,
          }}
        >
          {!secondary && (
            <ButtonOrange
              text={versions.length ? 'Hide History' : 'Show History'}
              style={{ marginRight: 80, color: 'var(--text)' }}
              onClick={toggleShow}
            />
          )}

          <Button
            text='Alert Me'
            style={{
              backgroundColor: item.subscribed
                ? '#70B461'
                : secondary
                ? '#C3CACC'
                : '#969FA2',
              padding: '0 15px',
              cursor: secondary ? 'not-allowed' : 'pointer',
            }}
            onClick={toggleAlert}
          />
        </div>
      </Wrap>
      {versions.map((item) => (
        <Item key={item.id} item={item} active secondary />
      ))}
    </>
  )
})

export default Item
