import React from 'react'
import { data } from './data'
import { Description, Grid, Title, Wrap } from './style'
import Tool from './Tool'

const Tools = () => {
  return (
    <Wrap>
      <Title>Tools to save time and money on regulatory compliance. </Title>
      <Description>
        Take advantage of business opportunities knowing what applies and, just
        as importantly, what doesn't apply. Avoid customer dissatisfaction and
        regulatory enforcement actions.{' '}
      </Description>
      <Grid>
        {data.map((item) => (
          <Tool key={item.key} {...item} />
        ))}
      </Grid>
    </Wrap>
  )
}

export default Tools
