import { message } from 'antd'
import React from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../../../api'
import Button from '../../../../../components/common/Button/button'
import Input from '../../../../../components/common/Input'
import { getError } from '../../../../../helpers/helpers'
import { Wrap } from './style'

const ScheduleInput = ({ border, style }) => {
  const { control, handleSubmit } = useForm()

  const onSubmit = (values) => {
    api('/resources/schedule_demo/', values, 'POST', true)
      .then((data) => {
        if (data.errors) return message.error(getError(data))

        message.success('Message sent')
      })
      .catch((err) => console.log(err))
  }

  return (
    <Wrap border={border} style={style} onSubmit={handleSubmit(onSubmit)}>
      <Input
        type='email'
        name='email'
        control={control}
        validation={{ required: true }}
        style={{ border: 0 }}
        placeholder='yourname@mail.com'
      />
      <Button text='Schedule a Demo' style={{ height: 'calc(100% - 12px)' }} />
    </Wrap>
  )
}

export default ScheduleInput
