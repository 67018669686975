export const topInputs = [
  {
    key: 0,
    name: 'reference_number',
    label: 'Reference Number/Name',
    type: 'text',
    validation: { required: true },
  },
  {
    key: 1,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    type: 'select',
    placeholder: 'Select Jurisdiction',
    options: 'jurisdictions',
    validation: { required: true },
    addNew: true,
    showSearch: true,
    showDeprecated: true,
  },
  {
    key: 2,
    name: 'topics',
    label: 'Topic',
    type: 'multiSelect',
    options: 'topics',
    validation: { validate: (value) => value?.length > 0, required: true },
    addNew: true,
    allowClear: true,
    showDeprecated: true,
  },
  {
    key: 3,
    name: 'line_of_business',
    label: 'Line of Business',
    type: 'multiSelect',
    options: 'line_of_business',
    validation: { validate: (value) => value?.length > 0, required: true },
    addNew: true,
    allowClear: true,
    showDeprecated: true,
  },
  {
    key: 4,
    name: 'adopted_date',
    label: 'Adopted Date',
    type: 'date',
    validation: { required: true },
    placeholder: '00/00/00',
    noLimit: true,
  },
  {
    key: 5,
    name: 'effective_date',
    label: 'Effective Date',
    type: 'date',
    placeholder: '00/00/00',
    noLimit: true,
  },
  {
    key: 6,
    name: 'compliance_date',
    label: 'Compliance Date',
    type: 'date',
    placeholder: '00/00/00',
    noLimit: true,
  },
  {
    key: 7,
    name: 'end_date',
    label: 'End Date',
    type: 'date',
    placeholder: '00/00/00',
    noLimit: true,
  },
]
