import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import hamburger from '../../assets/images/hamburger.svg'
import { useStore } from '../../mobx-store/context'
import ResourceModal from '../../pages/Profile/components/ResourceAccessModal/resourceModal'
import Logo from '../common/Logo/logo'
import {
  Hamburger,
  LogOut,
  Menu,
  MenuItem,
  Right,
  Submenu,
  Wrap,
} from './topLine.style'
import UserItem from './User'

const cookies = new Cookies()

const menu = [
  {
    key: 0,
    title: 'ComplianceSource',
    link: '/tracking',
  },
  {
    key: 1,
    title: 'ReSource',
    link: '/resource/table/jurisdictions',
  },
]

const TopLine = observer(() => {
  const store = useStore()
  const { pathname } = useLocation()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [visible, setVisible] = useState(false)
  const history = useHistory()

  const isHome =
    pathname === '/home' ||
    pathname === '/about' ||
    pathname === '/home-compliance' ||
    pathname === '/home-resource'

  const onClick = (item) => {
    setShowMobileMenu(false)
    if (
      item.key === 0 &&
      (!store.company[0].have_access_to_tracking ||
        (!store.user?.[0]?.have_access_to_tracking &&
          store.user?.[0]?.member_type === 'admin'))
    ) {
      return setVisible(true)
    }

    if (
      item.key === 1 &&
      store.user[0].member_type !== 'admin' &&
      !store.user[0].have_access_to_resource
    ) {
      return history.push('/edit-profile')
    }

    history.push(item.link)
  }

  useEffect(() => {
    if (store.user.length <= 0 && !isHome) store.getUser()
    if (cookies.get('pbm_token') && !store.resourceTariffInfo)
      store.getResourceTariffInfo()
  }, [])

  return (
    <Wrap>
      {visible && (
        <ResourceModal
          type='tracking'
          to='/pricing'
          onClose={() => setVisible(false)}
        />
      )}
      <Logo style={{ height: isHome ? 55 : 45, width: 'auto' }} />
      <Right active={showMobileMenu}>
        {cookies.get('pbm_token') && (
          <Menu>
            {menu.map((item) => (
              <MenuItem as={'div'} onClick={() => onClick(item)} key={item.key}>
                {item.title} {item.submenu && <span>▼</span>}
                {item.submenu && (
                  <Submenu>
                    {item.submenu.map((subItem, index) => (
                      <MenuItem
                        to={subItem.link}
                        key={subItem.key}
                        onClick={() => setShowMobileMenu(false)}
                        style={{
                          padding: '1em 2em',
                          borderBottom:
                            index !== item.submenu.length - 1 &&
                            '1px solid #e5e5e5',
                          marginLeft: 0,
                          height: 30,
                        }}
                      >
                        {subItem.title}
                      </MenuItem>
                    ))}
                  </Submenu>
                )}
              </MenuItem>
            ))}
          </Menu>
        )}

        <UserItem
          isHome={isHome}
          cookies={cookies}
          store={store}
          closeMobileMenu={() => setShowMobileMenu(false)}
        />

        {store.user[0] && (
          <LogOut
            to={'/login'}
            onClick={() => {
              cookies.remove('pbm_token')
              store.reset()
            }}
          >
            Log out
          </LogOut>
        )}
      </Right>
      <Hamburger
        src={hamburger}
        alt={'menu-icon'}
        onClick={() => setShowMobileMenu(!showMobileMenu)}
        active={showMobileMenu}
      />
    </Wrap>
  )
})

export default TopLine
