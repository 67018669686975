import { Checkbox as CheckboxAnt } from 'antd'
import React from 'react'
import { Controller } from 'react-hook-form'
import Checkbox from '../../../../components/common/Checkbox'
import { checkRole } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { Title } from '../../components/common/style'
import {
  complianceOwnerOptions,
  resourceOptions,
  taskOwnerOptions,
} from './static'

const StyledTitle = ({ children }) => (
  <Title
    style={{
      fontSize: '16px',
      margin: '10px 0',
      color: '#304659',
      fontFamily: 'var(--bold)',
    }}
  >
    {children}
  </Title>
)

const NotificationOptions = ({ control }) => {
  const store = useStore()

  return (
    <>
      <Controller
        control={control}
        name='notifications_compliance_source'
        render={({ onChange }) => (
          <>
            <Title>ComplianceSource</Title>
            <Title style={{ fontSize: '16px', marginTop: 10 }}>
              Get notifications for assigned compliance items.
            </Title>

            <CheckboxAnt.Group
              onChange={onChange}
              defaultValue={store.user[0].notifications_compliance_source}
            >
              {checkRole(
                [store.user?.[0].member_type],
                ['admin', 'compliance_owner']
              ) && (
                <div style={{ paddingLeft: 5 }}>
                  <StyledTitle>Compliance Owner</StyledTitle>
                  {complianceOwnerOptions.map(({ label, value }) => (
                    <Checkbox label={label} value={value} key={value} />
                  ))}
                </div>
              )}
              <div style={{ paddingLeft: 5 }}>
                <StyledTitle>Task Owner</StyledTitle>
                {taskOwnerOptions.map(({ label, value }) => (
                  <Checkbox label={label} value={value} key={value} />
                ))}
              </div>
              <Title style={{ marginTop: 20 }}>ReSource</Title>
              <Title style={{ fontSize: '16px', margin: '10px 0' }}>
                Get notifications when ReSource guides are updated.
              </Title>
              {resourceOptions.map(({ label, value }) => (
                <Checkbox
                  label={label}
                  value={value}
                  key={value}
                  style={{ paddingLeft: 5 }}
                />
              ))}
            </CheckboxAnt.Group>
          </>
        )}
      />
    </>
  )
}

export default NotificationOptions
