import React from 'react'
import image from '../../../../assets/images/mb_pro_main_home.png'
import { TitleGlobal } from '../../Compliance/styles'
import { Description, Wrap } from './style'

const Header = () => {
  return (
    <Wrap>
      <div>
        <TitleGlobal style={{ color: 'var(--blue)' }}>
          Reinventing Compliance for the
          <br /> PBM Industry
        </TitleGlobal>
        <Description>
          Build and grow your business working within the patchwork <br />
          of state and federal requirements.{' '}
        </Description>
      </div>
      <img src={image} alt='laptop' />
    </Wrap>
  )
}

export default Header
