import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Header, Item, Scrollable, Wrap } from './style'

const List = observer(
  ({
    options,
    setActive,
    customOptions,
    noHeader,
    active,
    loading,
    handleClick,
    page,
  }) => {
    const history = useHistory()
    const params = useParams()
    const scrollable = useRef()

    const isGuides = params.type === 'guides'

    const hasItemSummaryDetail = (item, code) => {
      const findItem = item.rows.find((item) =>
        page === 'details'
          ? item.id === +params.jursId
          : item.jurisdiction === +params.selectId
      )

      if (!code) return findItem?.requirements

      const content = findItem?.row_data?.custom_fields_data?.find(
        (item) => item.code === code
      )

      return (
        (content?.summary !== 'Not Specified' && content?.summary) ||
        (content?.detail !== 'Not Specified' && content?.detail)
      )
    }

    const onClick = (item) => {
      if (
        +item.id !== +params.id &&
        item.rows[0].requirements &&
        hasItemSummaryDetail(item, params.code)
      ) {
        setActive(item)
        handleClick && handleClick()
        if (params.code) {
          history.push(
            `/resource/details/${params.code}/${item.id}/${params.selectId}/${params.type}/${params.jursId}`
          )
        }
      }
    }

    useEffect(() => {
      const container = scrollable.current

      const activeItemOffset =
        container.querySelector('#active-item')?.offsetTop

      if (activeItemOffset) {
        container.scroll({
          top: activeItemOffset,
          left: 0,
          behavior: 'smooth',
        })
      }
    }, [])

    return (
      <Wrap>
        {!noHeader && (
          <Header border={isGuides}>
            <span>{isGuides ? 'Jurisdictions' : 'Guides'}</span>{' '}
            <span>Requirement</span>
          </Header>
        )}
        <Scrollable ref={scrollable}>
          {!options && <Spin style={{ margin: '15px 50%' }} />}
          {customOptions
            ? options
            : options?.map((item) => (
                <Item
                  key={item.id}
                  active={
                    hasItemSummaryDetail(item, params.code) &&
                    (+params.itemId === item.id || +params.id === item.id)
                  }
                  id={item.id === active?.id && 'active-item'}
                  onClick={() => onClick(item)}
                  disabled={!hasItemSummaryDetail(item, params.code)}
                >
                  <span>{item.name}</span>
                  <span style={{ width: 80, textAlign: 'center' }}>
                    {hasItemSummaryDetail(item, params.code) ? 'Yes' : 'No'}
                    {item.id === active?.id && loading && (
                      <Spin size='small' style={{ marginLeft: 10 }} />
                    )}
                  </span>
                </Item>
              ))}
        </Scrollable>
      </Wrap>
    )
  }
)

List.propTypes = {
  options: PropTypes.array,
}

export default List
