import styled from 'styled-components'

export const Wrap = styled('div')`
  .ant-table-container {
    border: 1px solid #c4cacc;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-family: var(--bold) !important;
    }

    tr {
      th {
        background-color: #fff;
      }
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 6px;
  }

  .ant-table-cell {
    border-right: 1px solid #c4cacc;
    font-family: var(--regular);
    font-size: 14px;
    color: var(--text);
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #c4cacc;
  }
`

export const ViewButton = styled('div')`
  color: #4b75b1;
  text-decoration: underline;
  cursor: pointer;
`

export const Name = styled('div')`
  font-family: var(--medium);
  font-size: 21px;
  color: var(--text);
  margin-bottom: 20px;
`

export const None = styled('div')`
  color: #969fa2;
  font-family: var(--regular);
`

export const TableTitle = styled('div')`
  font-size: 15px;
  font-family: var(--regular);
  color: var(--text);
  margin-bottom: 5px;
`
