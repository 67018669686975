import React from 'react'
import { Title, Wrap } from './style'
import notFoundImg from '../../assets/images/404.svg'
import Button from '../../components/common/Button/button'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

const NotFound = () => {
  return (
    <Wrap>
      <img src={notFoundImg} alt="404-image" />
      <Title>Sorry, it looks like this page doesn’t exist.</Title>
      <Button
        text={cookies.get("pbm") ? "ComplianceSource" : "Return Home"}
        to={cookies.get("pbm") ? "/tracking" : "/home"}
        style={{ backgroundColor: "orange" }}
      />
    </Wrap>
  );
};

export default NotFound;
