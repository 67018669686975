import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from '../../../../mobx-store/context'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container'
import TabsNav from '../../components/TabsNav/tabsNav'
import ComplianceTab from './tabs/complianceTab'
import ResourceTab from './tabs/resourceTab'

const tabs = {
  tracking: {
    button: 'Compliance Source',
    component: <ComplianceTab />,
    link: '/product-settings/tracking',
    isDisabled: (user) => !user?.have_access_to_tracking,
  },
  resource: {
    button: 'Resource',
    component: <ResourceTab />,
    link: '/product-settings/resource',
    isDisabled: (user) => !user?.have_access_to_resource,
  },
}

const ProductSettings = observer(() => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState()
  const user = store.user?.[0]

  const tab =
    params.type ||
    (user?.member_type === 'admin' || user?.resource_role === 'admin'
      ? 'tracking'
      : 'resource')

  useEffect(() => {
    setActive(tab)
  }, [params.type, store.user])

  useEffect(() => {
    if (!store.members.length) store.loadMembers()
  }, [])

  return (
    <PageWrap>
      <PageTitle>Product Settings</PageTitle>
      <TabsNav
        tabs={tabs}
        active={tab}
        tabsParams={store.user?.[0]}
        onRedirectTo={(page) => history.push(`/product-settings/${page}`)}
      />
      {tabs[tab].component}
    </PageWrap>
  )
})

export default ProductSettings
