export const complianceOwnerOptions = [
  {
    value: 'new_legislatives',
    label: 'New compliance items in tracking',
  },
  {
    value: 'assigned',
    label: 'When assigned to a compliance item',
  },
  {
    value: 'status_changed',
    label: 'Compliance item status change',
  },
  {
    value: 'documents',
    label: 'Uploaded or deleted documents',
  },
  {
    value: 'legislative_is_due',
    label: 'Compliance item is due',
  },
  {
    value: 'task_completion',
    label: 'Task completion',
  },
]

export const taskOwnerOptions = [
  {
    value: 'assigned_task',
    label: 'Assigned new task',
  },
  {
    value: 'task_is_due',
    label: 'Task is due',
  },
]

export const resourceOptions = [
  {
    value: 'resource_new_topic',
    label: 'New ReSource Topics',
  },
  {
    value: 'resource_updated_assigned_guide',
    label: 'Updates to assigned ReSource guides',
  },
]

export const notificationTime = [
  {
    value: 'right_away',
    label: 'Right away',
  },
  {
    value: 'end_of_day',
    label: 'At the end of the day',
  },
  {
    value: 'never',
    label: 'Never',
  },
]
