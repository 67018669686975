import React from 'react'
import { Desc, Image, Title, Wrap } from './style'
import manImg from '../../../../assets/images/man.svg'
import { Link } from 'react-router-dom'

const Empty = () => {
  return (
    <Wrap>
      <Image src={manImg}/>
      <div>
        <Title>We’ll Let You Know When <br/> We See Something!</Title>
        <Desc>
          There are no new compliance items to track at the moment! Check
          your <Link to={'/profile-email'}>email preferences</Link> to make sure
          you’ll recieve an update when
          there is!
        </Desc>
      </div>
    </Wrap>
  )
}

export default Empty
