import { message, Popconfirm } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { api } from '../../../api'
import deleteIcon from '../../../assets/images/delete-bin.svg'
import editIcon from '../../../assets/images/edit.svg'
import Button from '../../../components/common/Button/button'
import { Label } from '../../../components/common/Input/input.style'
import { getError } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import {
  MemberItem,
  MemberRight,
  MembersWrap,
  Scrollable,
} from './addMember.style'

const MembersList = observer(({ handleEdit, handleDelete, isResource }) => {
  const store = useStore()
  const history = useHistory()

  const members = isResource
    ? [
        ...store.addedMembers?.filter((item) => item.have_access_to_resource),
        ...store.members?.filter((item) => item.have_access_to_resource),
      ]
    : [
        ...store.addedMembers?.filter((item) => item.have_access_to_tracking),
        ...store.members?.filter((item) => item.have_access_to_tracking),
      ]

  const filteredMembers = members.filter(
    (filterItem) =>
      !store.addedMembers.find((item) => item.id === filterItem.id)
  )

  const buttonCheck =
    store.addedMembers?.length > 0 || filteredMembers.length > 0

  const [loading, setLoading] = useState(false)

  const deleteFromResourse = (id) => {
    const filtered = store.addedMembers.filter((item) => item.id !== id)

    store.setSingleValue('addedMembers', filtered)
  }

  const confirm = (id, existing) => {
    if (isResource) return deleteFromResourse(id)

    if (existing) {
      api(
        `/members/${id}/`,
        {
          ...(isResource
            ? { have_access_to_resource: false }
            : { have_access_to_tracking: false }),
        },
        'PATCH'
      ).then((data) => {
        if (data.errors) return message.error(getError(data))
        store.loadMembers()
        message.success('User was successfully deleted')
        handleDelete()
      })
    } else {
      handleDelete()

      store.deleteAddedMember(id)
    }
  }

  const onSubmit = () => {
    if (isResource) return history.push('/resource/billing')

    setLoading(true)

    if (!store.addedMembers.length) {
      history.push('/startDate')
      return
    }

    api(
      '/members/confirm_team/',
      {
        members: store.addedMembers,
      },
      'POST'
    ).then((data) => {
      if (!data.errors) {
        store.setSingleValue('addedMembers', [])
        store.setSingleValue('members', [])
        setLoading(false)
        history.push(isResource ? '/resource/billing' : '/startDate')
      } else {
        if (data?.[0]?.email) {
          alert(data[0].email)
        }
      }
    })
  }

  if (!store.company.length || !store.user.length) return null

  return (
    <MembersWrap>
      <Label style={{ marginBottom: '.5em' }}>Your Team Members</Label>
      <Scrollable>
        {store.addedMembers.map((item) => {
          return (
            <MemberItem key={item.id}>
              {item.first_name + ' ' + item.last_name}
              <MemberRight>
                <img
                  src={editIcon}
                  alt='edit-icon'
                  onClick={() => handleEdit(item.id, false)}
                />
                <Popconfirm
                  title='Are you sure to delete this user?'
                  onConfirm={() => confirm(item.id)}
                  okText='Yes'
                  cancelText='No'
                >
                  <img src={deleteIcon} alt='delete-icon' />
                </Popconfirm>
              </MemberRight>
            </MemberItem>
          )
        })}
        {filteredMembers.map((item) => {
          return (
            item.id !== store.user[0].id && (
              <MemberItem key={item.id}>
                <div>
                  {item.first_name} {item.last_name}{' '}
                </div>
                <MemberRight>
                  <img
                    src={editIcon}
                    alt='edit-icon'
                    onClick={() => handleEdit(item.id, true)}
                  />
                  <Popconfirm
                    title='Are you sure to delete this user?'
                    onConfirm={() => confirm(item.id, true)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <img src={deleteIcon} alt='delete-icon' />
                  </Popconfirm>
                </MemberRight>
              </MemberItem>
            )
          )
        })}
      </Scrollable>
      {buttonCheck && (
        <Button
          text={
            isResource
              ? 'Start ReSource'
              : 'Start Compliance Source Collaboration and Tracking'
          }
          style={{ margin: '3em auto 0' }}
          onClick={onSubmit}
          loading={loading}
        />
      )}
    </MembersWrap>
  )
})

export default MembersList
