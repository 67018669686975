import React, { useState } from 'react'
import Moment from 'react-moment'
import { useHistory, useParams } from 'react-router-dom'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../../helpers/helpers'
import useModal from '../../../../../hooks/useModal'
import { useStore } from '../../../../../mobx-store/context'
import ModalCompleted from '../Modal/ModalCompleted'
import { Item } from './style'

const List = ({
  tasks,
  onModalOpen,
  onComplete,
  taskID,
  complianceItem,
  data,
  isMyTasks,
  getTasks,
}) => {
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [currentTask, setCurrentTask] = useState()
  const { visible, handleOpen, handleClose } = useModal()

  const isCompleted = (item) => item.status === 'complete'

  return (
    <div>
      {visible && (
        <ModalCompleted
          data={currentTask}
          onClose={() => {
            handleClose()
            history.push(
              `/tracking/myTasks?compliance_item=${complianceItem || params.id}`
            )
          }}
          tasks={tasks}
          taskID={taskID}
          complianceItem={complianceItem}
          getTasks={getTasks}
        />
      )}
      <Item
        style={{
          fontFamily: 'var(--bold)',
        }}
      >
        <div>Assigned To:</div>
        <div>Task:</div>
        <div>Due:</div>
        <div>Status:</div>
      </Item>
      {tasks?.map((item) => {
        return (
          <Item>
            <div>{store.getMember(item.assignee)}</div>
            <div
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (isCompleted(item)) {
                  setCurrentTask(item)
                  handleOpen()
                } else {
                  onModalOpen(item)
                }

                history.push(
                  `/tracking/${
                    isMyTasks ? 'myTasks' : 'main'
                  }?compliance_item=${complianceItem || params.id}&taskID=${
                    item.id
                  }`
                )
              }}
            >
              {item.description}
            </div>
            <div>
              <Moment format={'MM/DD/YYYY'}>{item.due_date}</Moment>
            </div>
            <div
              style={{
                textDecoration: item.status === 'complete' && 'underline',
                cursor: item.status === 'complete' && 'pointer',
              }}
            >
              {isCompleted(item) ? (
                <div
                  style={{ fontFamily: 'var(--regular)', fontSize: '0.875em' }}
                  onClick={() => {
                    setCurrentTask(item)
                    handleOpen()
                    history.push(
                      `/tracking/${
                        isMyTasks ? 'myTasks' : 'main'
                      }?compliance_item=${complianceItem || params.id}&taskID=${
                        item.id
                      }`
                    )
                  }}
                >
                  Completed
                </div>
              ) : (
                (checkRole(
                  [store.user?.[0]?.member_type],
                  ['admin', 'owner', 'compliance_owner']
                ) ||
                  store.user?.[0]?.id === item.assignee) && (
                  <ButtonOrange
                    text={'Complete task'}
                    onClick={() => {
                      if (data.status === 'closed') return
                      onComplete()
                      history.push(
                        `/tracking/${
                          isMyTasks ? 'myTasks' : 'main'
                        }?compliance_item=${
                          complianceItem || params.id
                        }&taskID=${item.id}`
                      )
                    }}
                  />
                )
              )}
            </div>
          </Item>
        )
      })}
    </div>
  )
}

export default List
