import { Radio } from 'antd'
import { Controller } from 'react-hook-form'
import { useStore } from '../../../../mobx-store/context'
import { Title } from '../../components/common/style'
import { notificationTime } from './static'
import { CustomRadioGroup } from './style'

const NotificationsTime = ({ control }) => {
  const store = useStore()
  return (
    <>
      <Title>When would you like to receive notifications?</Title>
      <CustomRadioGroup style={{ paddingLeft: 5 }}>
        <Controller
          control={control}
          name='notifications_when'
          render={({ onChange }) => (
            <Radio.Group
              onChange={(e) => onChange(e.target.value)}
              defaultValue={store.user[0].notifications_when}
            >
              {notificationTime.map(({ label, value }) => (
                <Radio value={value} key={value}>
                  {label}
                </Radio>
              ))}
            </Radio.Group>
          )}
        />
      </CustomRadioGroup>
    </>
  )
}

export default NotificationsTime
