import React from 'react'
import { Wrap } from './style'

const ItemsShowing = ({ page, pageSize, total, style }) => {
  if (!total) return null

  const itemsDisplaying = page * pageSize - pageSize

  const itemsDisplayingMax =
    pageSize >= total ? total : +itemsDisplaying + +pageSize

  return (
    <Wrap style={style}>
      Displaying items {pageSize >= total ? 1 : itemsDisplaying || 1}-
      {itemsDisplayingMax < total ? itemsDisplayingMax : total} of {total}
    </Wrap>
  )
}

export default ItemsShowing
