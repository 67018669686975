import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import setting from '../../../../assets/images/settings_24px.svg'
import { checkRole } from '../../../../helpers/helpers'
import useDidMountEffect from '../../../../hooks/useEffectNotOnPageLoad'
import { useStore } from '../../../../mobx-store/context'
import { Item, Title, Wrap } from './style'

const list = [
  {
    key: 0,
    title: 'Edit Profile',
    to: '/edit-profile',
  },
  {
    key: 1,
    title: 'Password and Security',
    to: '/password-and-security',
    secondary: false,
  },
  {
    key: 2,
    title: 'Email Notifications',
    to: '/profile-email',
    secondary: false,
  },
  {
    key: 3,
    title: 'Product settings',
    to: '/product-settings',
    secondary: false,
    onlyAdmin: true,
    roles: ['admin', 'compliance_owner'],
  },
  {
    key: 7,
    title: 'Billing Info',
    to: '/billing-info',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin'],
  },
  {
    key: 4,
    title: 'Current Plan',
    to: '/current-plan',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin'],
  },
  {
    key: 5,
    title: 'Business info',
    to: '/business-info',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin'],
  },
  {
    key: 6,
    title: 'Manage The Team',
    to: '/manage-team-members',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin', 'compliance_owner'],
  },
  {
    key: 7,
    title: 'Manage Dictionaries',
    to: '/manage-dictionaries/topics',
    roles: ['admin', 'compliance_owner'],
    check: (store) => store.company?.[0]?.have_access_to_tracking,
    secondary: true,
  },
]

const Menu = observer(({ showAdding }) => {
  const store = useStore()
  const location = useLocation()
  const history = useHistory()

  const user = store.user?.[0]

  const disabledForUser = [
    '/product-settings',
    '/current-plan',
    '/business-info',
    '/manage-team-members',
    '/add-team-members-profile',
    '/billing-info',
  ]

  useDidMountEffect(() => {
    if (
      store.user[0] &&
      !checkRole([store.user[0]?.member_type], ['admin', 'compliance_owner']) &&
      disabledForUser.includes(location.pathname)
    ) {
      history.push('/edit-profile')
    }
  }, [store.user])

  return (
    <Wrap>
      <Title>
        <img src={setting} alt='setting-icon' /> Account Preferences
      </Title>
      {list.map((item) => {
        return (
          checkRole(
            [
              user?.[
                user?.have_access_to_tracking ? 'member_type' : 'resource_role'
              ],
            ],
            item.roles
          ) &&
          (item.hasOwnProperty('check') ? item.check(store) : true) && (
            <Item
              to={item.to}
              key={item.key}
              activeStyle={{ backgroundColor: '#fff', color: '#4E74A9' }}
              secondary={item.secondary ? 'true' : 'false'}
              disabled={item.disabled}
              style={{
                backgroundColor:
                  showAdding && item.title === showAdding[0] && '#225194',
              }} // в showAdding добавил вручную тайтл страницы с которой был редирект (на даном этапе выбра такое решение)
              isActive={(_, location) => {
                if (location.pathname.split('/')[1] === item.to.split('/')[1])
                  return true

                return false
              }}
            >
              {item.secondary && <span>•</span>} {item.title}
            </Item>
          )
        )
      })}
      {showAdding && showAdding[1] === 'add' && (
        <Item
          to={'/add-team-members-profile'}
          activeStyle={{
            backgroundColor: '#fff',
            color: '#4E74A9',
            fontSize: '12px',
            width: 'fit-content',
            marginLeft: 'auto',
            paddingRight: '10px',
          }}
        >
          <span>•</span> Add Team Members
        </Item>
      )}
      {showAdding && showAdding[1] !== 'add' && (
        <Item
          as='div'
          style={{
            backgroundColor: '#fff',
            color: '#4E74A9',
            fontSize: '12px',
            width: 'fit-content',
            marginLeft: 'auto',
            paddingRight: '10px',
          }}
        >
          <span>•</span> {showAdding[1].substring(0, 25) + '...'}
        </Item>
      )}
    </Wrap>
  )
})

export default Menu
