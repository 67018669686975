import React from 'react'
import { useStore } from '../../../../../mobx-store/context'
import { Name, Text, TopLine, Wrap } from './style'
import Moment from 'react-moment'
import parse from 'html-react-parser'

const Item = ({ data }) => {
  const store = useStore()

  return (
    <Wrap>
      <TopLine>
        <Name>{store.getMember(data?.modified_by)}</Name>{' '}
        <Moment format={'lll'}>{data?.modified}</Moment>
      </TopLine>
      <Text>
        {parse(data.details_html)}
      </Text>
    </Wrap>
  )
}

export default Item
