import React from 'react'
import { Content, ImageWrap, Rights, Text, TextTitle, Wrap } from './style'
import Logo from '../../components/common/Logo/logo'
import ButtonOrange from '../../components/common/ButtonOrange'
import { Title } from '../Auth/authForm.style'
import bg from '../../assets/images/bg-logo.png'
import { useLocation } from 'react-router-dom'

const TermsAndPrivacy = () => {
  const location = useLocation()

  return (
    <>
      <ImageWrap>
        <img src={bg} alt='bg-image' />
      </ImageWrap>
      <Wrap>
        <Logo large />
        <Content>
          <ButtonOrange
            text={'Back to Register'}
            link={location.state?.fromPopup ? '/register#popup' : '/register'}
            style={{ fontFamily: 'var(--bold)' }}
          />
          <Title>
            Terms and Conditions of Use, Privacy Policy, and Subscription <br />
            Agreement for PBMSource
          </Title>
          <Text>
            Effective Date: This Agreement is effective as of the date of your
            use of this website or the date of electronic acceptance, whichever
            is earlier.
            <br />
            <span style={{ color: '#DF7411' }}>
              PLEASE READ THESE TERMS AND CONDITIONS OF USE, PRIVACY POLICY, AND
              SUBSCRIPTION AGREEMENT CAREFULLY BEFORE USING THIS WEBSITE. THIS
              WEBSITE IS A SERVICE PROVIDED BY PBMSOURCE. BY USING THIS WEBSITE,
              INCLUDING ALL CONTENT, SERVICES, SOFTWARE AND RELATED INFORMATION,
              YOU ARE AGREEING TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS
              OF USE.
            </span>
          </Text>
          <Text>
            <TextTitle>USE OF SITE</TextTitle> The term “Site” means this
            website. The terms “You” or “Your” refers to any person who uses
            this Site, and if entered into on behalf of a company, use of these
            terms shall also include such entity. By entering this website, You
            agree to be bound by the terms of this Agreement. The terms “We”,
            “Our” or “Us” refers to PBMSource, LLC (“PBMSource”). By accessing,
            browsing and/or using this Site, You acknowledge that You have read,
            have understood, and agree to be bound by this Agreement and to
            comply with all applicable laws and regulations. PBMSource is
            intended for use within the United States, and PBMSource makes no
            representations if this Site is accessed outside of the United
            States. <br />
            <TextTitle>OPERATION OF SITE</TextTitle> ComplianceSource on
            PBMSource is intended to provide one source for identification,
            alerts, collaboration, tracking, and other tools for compliance with
            new laws, rules, and regulations related to the PBM industry.
            ReSource on PBMSource is intended to provide one source for
            identification and summaries of existing laws, rules, and
            regulations related to the PBM industry. As part of these services,
            PBMSource will be providing legislation from all 50 states and
            certain federal guidance. In addition, PBMSource may include
            suggested lines of business that the laws and regulations may relate
            to, along with categories for which the legislation may apply. All
            of this information is provided as guidance only, and You are
            encouraged to do Your own research and assessments.
            <p>
              PBMSource may also include links to various agencies and
              websites—the absence of a link does not mean that one does not
              exist. In addition, the link may change and PBMSource makes no
              representation as to the accuracy or whether or not the link is
              up-to-date.
            </p>
            You may sign up for laws, rules and regulations related to a
            particular category or in certain jurisdictions. PBMSource will
            provide the laws, rules, and regulations of which it is aware in
            such category and/or jurisdiction, but PBMSource makes no
            representation or warranty that there are not other laws, rules or
            regulations with respect to such category and/or jurisdiction.
            Further, in connection with ComplianceSource, while PBMSource will
            provide information on new laws, rules and regulations that are
            adopted during your subscription period for the categories and
            jurisdictions You signed up for, it does not mean that a law, rule
            or regulation will be passed for such category and/or jurisdiction
            during the time of Your subscription. Similarly, in connection with
            ReSource, while PBMSource will provide information on laws, rules
            and regulations in existence during your subscription period for the
            categories and jurisdictions You signed up for, it does not mean
            that a law, rule or regulation will be in existence for such
            category and/or jurisdiction during the time of Your subscription.
            For example, You may sign up for prompt pay in Texas. If there are
            not any prompt pay laws, rules or regulations
            passed/amended/existing in Texas during Your subscription period,
            Your plan will not show any laws, rules or regulations during Your
            subscription period.
            <TextTitle>APPLICABILITY</TextTitle> This Terms and Conditions of
            Use is entered into by PBMSource and You, and it is effective upon
            Your entry into this Site. To the extent that these Terms and
            Conditions of Use conflict with any other written agreement between
            You and PBMSource, such other document shall control. PBMSource may,
            in its sole discretion, modify or otherwise change this Terms and
            Conditions of Use, and such modifications are effective immediately
            upon entry into this Site. In some instances, PBMSource may notify
            You of changes to these Terms and Conditions of Use, but is under no
            obligation to do so. PBMSource may terminate this Terms and
            Conditions of Use at any time, and without refund of any payments,
            in its sole and absolute discretion.
            <TextTitle>NOT LEGAL ADVICE</TextTitle>
            PBMSource is not a law firm or a substitute for an attorney or law
            firm. The Site is for informational purposes. Your use of the Site
            does not constitute legal advice, nor does it create an
            attorney/client relationship. You must seek Your own legal advice.
            PBMSource encourages you to seek independent verification of any of
            the information provided.
            <TextTitle>LINKS TO THIRD PARTY SITES</TextTitle> This Site may
            contain links to other websites which are not under the control of
            PBMSource. PBMSource makes no representations to the accuracy,
            content or validity of such third-party sites. PBMSource is not
            responsible for the contents of any of the third-party sites, and
            such links are being provided to You solely as a convenience, and
            such inclusion does not mean that PBMSource endorses the links, or
            in any way represents the validity and/or accuracy of the
            information contained in the third-party sites.
            <TextTitle> ESTABLISHMENT OF ACCOUNT</TextTitle> As a requirement to
            enter certain parts of this Site, You are required to register. By
            entering this Site, You represent and warrant that You are at least
            eighteen (18) years of age, able to enter into a legally binding
            contract, and that the information You are submitting is accurate,
            current and complete and that You have authority to enter into these
            Terms and Conditions of Use. PBMSource may suspend Your account at
            any time, without prior notice, if it has reason to believe that the
            information You have submitted is not true and correct and/or You
            don’t have authority to enter into this Terms and Conditions of Use.
            If You are entering this Site on behalf of a company, You are
            representing and warranting that You have the authority to act on
            behalf of the company, and by entering this Site, You understand and
            acknowledge that You are binding the company to these terms and
            conditions. You also understand and acknowledge that by entering
            this Site, You have bound the company to pay any amounts due in
            relation to this Site. You are solely responsible for the activity
            that occurs in relation to Your account, whether authorized by You
            or not, and You agree to keep any information related to Your
            account secure and confidential, including any login and password
            information, and such information should not be shared with anyone.
            You are obligated to notify PBMSource of any breach or unauthorized
            use of Your account, and PBMSource will not be liable in the event
            of any breach, but You acknowledge that You (including You as an
            individual, and as a company) may be liable for any loss that
            PBMSource incurs related to the account.
            <TextTitle>SHARING OF INFORMATION</TextTitle>
            As part of this Site, You may have the opportunity to upload or
            otherwise share information on this Site. Such information will only
            be available to You and other users of Your company. By uploading
            such information, You acknowledge and agree that You will not:
            <ul>
              <li>
                Defame, abuse, harass, stalk, threaten or otherwise violate the
                legal rights (such as rights of privacy and publicity) of
                others.
              </li>
              <li>
                Publish, post, upload, distribute or disseminate any
                inappropriate, profane, defamatory, infringing, obscene,
                indecent or unlawful topic, name, material or information.
              </li>
              <li>
                Upload files that contain software or other material protected
                by intellectual property laws (or by rights of privacy of
                publicity) unless You own or control the rights thereto or have
                received all necessary consents.
              </li>
              <li>
                Upload files that contain viruses, corrupted files, or any other
                similar software or programs that may damage the operation of
                another's computer
              </li>
              <li>
                Otherwise use this Site and/or upload information that is
                contrary to the intended use of this Site.
              </li>
            </ul>
            <TextTitle>PRIVACY</TextTitle> PBMSource does use Your personal
            information for purposes of this Site. This information consists of
            such things as Your email address, name, company name, address, and
            bank account information. By using this Site, You agree to the
            collection and use of Your information in accordance with this
            Agreement. PBMSource does not sell, trade, rent or otherwise share
            personal identification with anyone. We may share any general
            information that is not related to any personal identification
            information with certain business partners, but We will not share
            any information that can be attributed to You. To the extent that
            the information collected by PBMSource is personally identifiable
            information (PII), PBMSource provides the following information:
            Cookies are small text files (that may be encrypted) placed on Your
            device as You browse certain sites. The cookies perform essential
            functions, but they can also gather and store significant amounts of
            data. PBMSource may use cookies to store certain information. You
            may block certain cookies but please be aware that by blocking such
            cookies, it may limit the information and access. Currently, this
            website may also collect user tokens that collect user ID
            information which may be stored locally on your computer.
            <p>
              If You want to request changes to the PII, please contact
              PBMSource at the following email:{' '}
              <a
                href='mailto:contact@pbmsource.com'
                style={{ color: 'var(--orange)' }}
              >
                contact@pbmsource.com
              </a>
              . If there are any changes to this Agreement and/or the privacy
              and cookie policy, PBMSource will send an email to all individuals
              registered on its Site.{' '}
            </p>
            <TextTitle> LIMITATION ON LIABILITY AND INDEMNIFICATION</TextTitle>
            IN NO EVENT WILL PBMSOURCE, ITS OFFICERS, DIRECTORS, AGENTS AND/OR
            EMPLOYEES BE LIABLE FOR ANY LOST PROFITS OR ANY INDIRECT,
            CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT
            OF, BASED ON, OR RESULTING FROM (A) OUR SITE, (B) THESE TERMS, (C)
            ANY BREACH OF THESE TERMS BY YOU OR A THIRD PARTY, (D) USE OF THE
            SITE, TOOLS OR SERVICES WE PROVIDE, OR ANY THIRD PARTY PROVIDER
            PROVIDES, RELATED TO THE BUSINESS WE OPERATE ON THE SITE, BY YOU OR
            ANY THIRD PARTY, (E) ANY USER-CONTRIBUTED CONTENT, (F) INTERACTION
            BETWEEN OUR SITE AND ANY THIRD PARTY SITE. THESE LIMITATIONS AND
            EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM
            (1) BREACH OF CONTRACT, (2) BREACH OF WARRANTY, (3) STRICT
            LIABILITY, (4) TORT, (5) NEGLIGENCE, OR (6) ANY OTHER CAUSE OF
            ACTION, TO THE MAXIMUM EXTENT SUCH EXCLUSION AND LIMITATIONS ARE NOT
            PROHIBITED BY APPLICABLE LAW. YOUR SOLE REMEDY IS TO DISCONTINUE
            USING THIS SITE, WHICH YOU MAY DO AT ANY TIME.
            <p style={{ textTransform: 'uppercase' }}>
              YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS PBMSOURCE, ITS
              AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
              AND AGENTS AGAINST ANY CLAIMS, CAUSES OF ACTION, DEMANDS,
              RECOVERIES, LOSSES, DAMAGES, JUDGMENTS, AWARDS, FINES, PENALTIES
              OR COSTS OR EXPESNES OF ANY KIND (INCLUDING REASONABLE ATTORNEYS’
              FEES) ARISING OUT OF OR RELATING TO YOUR VIOLATION OF THIS TERMS
              OF USE AND CONDITIONS AND SUBSCRIPTION AGREEMENT AND/OR OR YOUR
              USE OF THE SITE, INCLUDING, BUT NOT LIMITED TO, ANY USE OF THE
              SITE’S CONTENT, SERVICES, AND PRODUCTS OTHER THAN AS EXPRESSLY
              AUTHORIZED IN THIS AGREEMENT OR YOUR USE OF ANY INFORMATION
              OBTAINED FROM THE SITE.
            </p>
            <TextTitle> SUBSCRIPTION TERMS</TextTitle> If You subscribe to any
            of the services or products provided for on this Site, You agree to
            the conditions stated herein, including, without limitation, the
            following:
            <ol>
              <li>
                <span>Annual Subscription</span>. You acknowledge and agree that
                when signing up for access and use of the PBMSource website and
                the particular product and/or service to which you subscribe
                therein (e.g., ComplianceSource legislative and regulatory
                tracking service and the data contained therein) (the “Product”)
                (referred to herein as Your “<span>Subscription</span>”), the
                Subscription is an annual subscription. Your Subscription and
                the license granted hereunder will be effective as of the date
                Your payment is received and accepted by PBMSource (the “
                <span>Subscription Effective Date</span>”) and shall continue
                for one full year thereafter (“
                <span>Annual Subscription Period</span>
                ”). You further acknowledge and agree that Your Subscription may
                not be cancelled by You prior to the end of the Annual
                Subscription Period. You may upgrade your Subscription plan
                during the Term. You cannot change your Subscription plan to a
                lower-cost plan during the Term. You can only switch to a
                lower-cost plan at renewal.
              </li>
              <li>
                <span>Fees and Payment</span>. You agree to pay the rate as set
                forth in the payment schedule on the Site (the{' '}
                <span>“Subscription Fee”</span>) for the full Annual
                Subscription Period. For the monthly payment option, PBMSource
                will automatically charge the monthly payment amount in
                accordance with the payment schedule on the Site. If monthly
                payment is not received for any reason (e.g., credit card
                declined), Your access to the Product may be terminated
                effective immediately without notice to You unless and until
                payment is received in full. In all cases, You are responsible
                for the full amount of the Subscription Fee for the Annual
                Subscription Period, which shall become immediately due and
                payable in the event of a payment default by You. In connection
                with ReSource, as new Law Guides are added, the Subscription Fee
                for ReSource will be prorated so that the Subscription Fee for
                all Law Guides under ReSource are due on the same date.
              </li>
              <li>
                <span>Free Trial</span>. Free Trial. A free trial may be
                available for certain products on the Site (e.g.,
                <span style={{ textDecoration: 'none', fontStyle: 'italic' }}>
                  ComplianceSource
                </span>
                ). If You sign up for the free trial as set forth on the Site,
                You will only have access to limited data and information (e.g.,
                for ComplianceSource, You will only have access to ten (10)
                entries for the topic(s) and jurisdiction(s) You choose). Unless
                and until You sign up for a Subscription and pay the
                Subscription Fee, You will not have access to anything beyond
                the limited trial data and information. Access to the free trial
                and the data and information contained therein may be terminated
                by PBMSource at any time without notice to You
              </li>
              <li>
                <span>License</span>. In consideration of the payment of the
                applicable Subscription Fee and Your adherence to all provisions
                of this Agreement, PBMSource grants You a personal,
                non-exclusive, nontransferable, limited license to access and
                use the PBMSource website and the particular Product to which
                you have a Subscription (e.g.,{' '}
                <span style={{ textDecoration: 'none', fontStyle: 'italic' }}>
                  ComplianceSource, ReSource
                </span>
                ).
              </li>
              <li>
                <span>Account, Password and Security</span>. Your email and
                password that You establish will be how You sign into PBMSource.
                You are responsible for maintaining the confidentiality of Your
                password and account information, and You are responsible for
                all activities occurring under Your login id and password. You
                agree to immediately notify PBMSource of any unauthorized use of
                Your login id or password or of any other breach of security.
              </li>
              <li>
                <span>Restrictions</span>. You may not use, copy, modify, or
                transfer the Product, any form of the Product, or any of the
                data in the Product to others, in whole or in part, except for
                Your own internal business purposes only and as otherwise
                expressly provided in this Agreement. The license granted
                hereunder is personal to You, and any may not be transferred or
                assigned in whole or in part without the prior written consent
                of PBMSource. You agree (a) to abide by all applicable local,
                state, and national laws and regulations during use of the
                Product and/or the Site, (b) not to interfere with the use and
                enjoyment of the Product and/or Site by other users, (c) not to
                use the Product and/or Site for illegal purposes; (d) not to
                interfere with or disrupt the Product, Site, or servers or
                networks connected to the Product and/or Site; and (e) not to
                resell or share with third-parties the Product, Site, or use of
                or access to the Product and/or Site or the data therein
              </li>
              <li>
                <span>Ownership</span>. You acknowledge and agree that content,
                including, but not limited to, text, data, software,
                photographs, graphics, and other material contained in the Site,
                including the Product, is owned or licensed by PBMSource and is
                protected by copyrights, trademarks, service marks, and/or other
                proprietary rights and laws. You may not copy, reproduce,
                transmit, distribute or create derivative works of such content
                or information without the express written authorization of
                PBMSource. The Site may be used, but not sold, for internal
                purposes only and is provided for use only under the terms of
                this Agreement, and PBMSource reserves all rights not expressly
                granted to You.
              </li>
              <li>
                <span>Term</span>. The Subscription for the Product and license
                granted herein is for one (1) year beginning with the
                Subscription Effective Date (the “Term”) and will automatically
                renew for additional one (1) year periods unless either party
                elects not to renew the subscription upon written notice to the
                other party, such notice provided at least sixty (60) days prior
                to the end of the then current term.
              </li>
              <li>
                <span>Termination</span>. This Agreement may be terminated
                immediately without notice to You if You breach any term of this
                Agreement, including if You do not pay the fees due hereunder.
                Further, in the event of a termination or expiration of any
                agreement between PBMSource and a third-party content provider
                or licensor of all or a part of the Product, Your right to
                access and use the Product may also terminate or expire.
                Following the initial Term, PBMSource may modify the
                Subscription Fee payable hereunder on thirty (30) days prior
                written notice to You; provided that You may terminate this
                Agreement within such thirty (30) day period by providing
                written notice to PBMSource if You object to such modification.
                Upon termination or expiration of this Agreement for any reason,
                the license and Your access to the Products shall cease.
              </li>
              <li>
                <span>Governing Law, Jurisdiction and Venue</span>. This
                Agreement shall for all purposes be governed by and interpreted
                in accordance with the laws of the State of Arizona, without
                regard to applicable conflict of law rules. Any legal suit,
                action or proceeding arising out of or relating to this
                Agreement shall be commenced in a state court in Maricopa
                County, Arizona, and each party hereto irrevocably submits to
                the personal and exclusive jurisdiction and venue of any such
                court in any such suit, action or proceeding and waives any
                right which it may have to transfer or change the venue of any
                such suit, action or proceeding. The parties agree that the
                United Nations Convention on Contracts for the International
                Sale of Goods is specifically excluded from application to this
                Agreement.
              </li>
              <li>
                <span>Waiver</span>. No failure to enforce any term of this
                Agreement shall constitute a waiver of such term in the future.
              </li>
              <li>
                <span>Assignment</span>. Neither this Agreement nor any of Your
                rights or obligations hereunder may be assigned by You in whole
                or in part without the prior written approval of PBMSource. Any
                other attempted assignment shall be null and void.
              </li>
              <li>
                <span>Severability</span>. If any part of this Agreement is for
                any reason found to be invalid, illegal or unenforceable, the
                validity, legality and enforceability of the remaining
                provisions of this Agreement shall not be affected.
              </li>
              <li>
                <span>Notice</span>. Any notice required or permitted by this
                Agreement, unless otherwise specifically provided for in this
                Agreement, shall be in writing and shall be deemed given upon
                sending an email to the address which You use to sign into
                PBMSource.
              </li>
              <li>
                <span>Complete Agreement</span>. This Agreement is the complete
                and exclusive statement of the agreement between PBMSource and
                You, and supersedes any proposal or prior agreement, oral or
                written, and any other communications between the parties in
                relation to the subject matter of this Agreement. No waiver,
                alteration or modification of this Agreement shall be valid
                unless made in writing and signed by both parties.
              </li>
            </ol>
          </Text>
        </Content>
      </Wrap>
      <Rights>© PBMSource 2022 All rights reserved.</Rights>
    </>
  )
}

export default TermsAndPrivacy
