import styled from 'styled-components'

export const Wrap = styled('div')`
  display: grid;
  grid-template-columns: ${(p) =>
    p.viewOnly ? '100px 1fr 140px' : '100px 70px 1fr 140px'};
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  gap: 15px;
`

export const Item = styled('div')`
  font-family: var(--medium);
  font-size: 12px;
  color: var(--text);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  white-space: nowrap;
`
