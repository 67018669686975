import {
  ContentState,
  convertFromHTML,
  Editor,
  EditorState,
  RichUtils,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import 'draft-js/dist/Draft.css'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../../../api'
import boldIcon from '../../../../assets/images/bold.svg'
import italicIcon from '../../../../assets/images/italic.svg'
import underlineIcon from '../../../../assets/images/underline.svg'
import Button from '../../../../components/common/Button/button'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { DocumentsPlaceholder } from '../right.style'
import './DraftStyleDefault.css'
import Item from './Item'
import { Toolbar, ToolButton, Wrap, WrapEditor } from './style'

const styleMap = {
  BOLD: {
    fontFamily: 'var(--bold)',
  },
}

const RichTextExample = ({ legislative }) => {
  const store = useStore()
  const params = useParams()
  const [data, setData] = useState(null)
  const [active, setActive] = useState({
    BOLD: false,
    ITALIC: false,
    UNDERLINE: false,
  })
  const [visibleField, setVisibleField] = useState(false)
  const [loading, setLoading] = useState(false)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const isEmpty = !editorState.getCurrentContent().hasText()

  const ID = params.id || store.currentLegislative.id

  const checkIsOnlySpaces = () => {
    const content = editorState.getCurrentContent()
    const isEditorEmpty = !content.hasText()
    const currentPlainText = content.getPlainText()
    const lengthOfTrimmedContent = currentPlainText.trim().length
    return !isEditorEmpty && !lengthOfTrimmedContent
  }

  const onSubmit = () => {
    if (isEmpty || checkIsOnlySpaces()) return null

    setLoading(true)

    const value = stateToHTML(editorState.getCurrentContent())
    api(
      `/legislative-details/${ID}/`,
      {
        details_html: value,
      },
      'PUT'
    ).then((data) => {
      if (!data.errors) {
        setData(data)
        setLoading(false)
      }
    })
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      this.onChange(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  const transformText = (e, type) => {
    setActive({ ...active, [type]: !active[type] })

    // onMouseDown and e.preventDefault because editor losses focus if you use onClick
    e.preventDefault()

    let nextState = RichUtils.toggleInlineStyle(editorState, type)

    setEditorState(nextState)
  }

  useEffect(() => {
    api(`/legislative-details/${ID}/`, {}, 'GET').then((data) => setData(data))
  }, [legislative])

  useEffect(() => {
    if (data?.details_html) {
      const blocksFromHTML = convertFromHTML(data.details_html)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      setEditorState(EditorState.createWithContent(state))
      setVisibleField(false)
    } else {
      setEditorState(EditorState.createEmpty())
      setVisibleField(true)
    }
  }, [data])

  useEffect(() => {
    if (isEmpty)
      setActive({
        BOLD: false,
        ITALIC: false,
        UNDERLINE: false,
      })
  }, [isEmpty])

  return (
    <>
      {checkRole(
        [store.user?.[0]?.member_type],
        ['compliance_owner', 'owner', 'admin']
      ) &&
        visibleField && (
          <Wrap>
            <WrapEditor>
              <Toolbar>
                <ToolButton
                  onMouseDown={(e) => transformText(e, 'BOLD')}
                  active={active['BOLD']}
                >
                  <img src={boldIcon} alt='bold-icon' />
                </ToolButton>
                <ToolButton
                  onMouseDown={(e) => transformText(e, 'ITALIC')}
                  active={active['ITALIC']}
                >
                  <img src={italicIcon} alt='italic-icon' />
                </ToolButton>
                <ToolButton
                  onMouseDown={(e) => transformText(e, 'UNDERLINE')}
                  active={active['UNDERLINE']}
                >
                  <img src={underlineIcon} alt='underline-icon' />
                </ToolButton>
              </Toolbar>
              <Editor
                editorState={editorState}
                onChange={(editorState) => setEditorState(editorState)}
                handleKeyCommand={handleKeyCommand}
                placeholder={
                  'Type in relevant details for completing the compliance assessment.'
                }
                customStyleMap={{
                  ...editorState.getCurrentInlineStyle(),
                  ...styleMap,
                }}
              />
            </WrapEditor>
            <div style={{ textAlign: 'center' }}>
              <Button
                text={data?.details_html ? 'Save Details' : 'Add Details'}
                style={{ fontSize: 12 }}
                onClick={onSubmit}
                disabled={isEmpty || checkIsOnlySpaces()}
                loading={loading}
              />
              {data?.details_html && (
                <ButtonOrange
                  text={'Cancel'}
                  onClick={() => setVisibleField(false)}
                />
              )}
            </div>
          </Wrap>
        )}
      {data?.details_html && <Item data={data} />}
      {!visibleField &&
        checkRole(
          [store.user?.[0].member_type],
          ['admin', 'owner', 'compliance_owner']
        ) && (
          <Button
            text={'Edit Details'}
            onClick={() => setVisibleField(true)}
            style={{ marginTop: 15 }}
          />
        )}

      {!data?.details_html && (
        <DocumentsPlaceholder
          style={{
            marginTop: 15,
            borderBottom: '1px solid #bbb',
            paddingBottom: 15,
          }}
        >
          There are no details associated with this item yet.
        </DocumentsPlaceholder>
      )}
    </>
  )
}

export default RichTextExample
