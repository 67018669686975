import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../api'
import Button from '../../../components/common/Button/button'
import Input from '../../../components/common/Input'
import { getDescription, getDirtyFields } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { Title, Wrap } from './style'

const Filters = observer(() => {
  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    setValue,
  } = useForm()
  const store = useStore()
  const [savedSearch, setSavedSearch] = useState([])
  const [uniqueJurs, setUniqueJurs] = useState([])

  const getUniqueJurs = () => {
    if (!store.jurs) return
    const arr = [...savedSearch, ...store.jurs]

    const result = arr
      .map((item) => item?.id || item)
      .filter((item, index, final) => item && final.indexOf(item) === index)
      .map((item) => ({ id: item, name: getDescription(store.jurs, item) }))

    setUniqueJurs(result)
  }

  const getSearchableString = (values) => {
    let result = ''
    if (!values) return result
    const lastKey = Object.keys(values)[Object.keys(values).length - 1]

    for (let key in values) {
      if (!values[key]) continue

      if (key === 'date') {
        result += `date_after=${moment(values[key].split('±')[0]).format(
          'YYYY-MM-DD'
        )}&`
        if (values[key].split('±')[0])
          result += `date_before=${moment(values[key].split('±')[1]).format(
            'YYYY-MM-DD'
          )}&`
      } else {
        result += `${key}=${values[key]}${key === lastKey ? '' : '&'}`
      }
    }

    return result
  }

  const onSubmit = (values) => {
    api(
      `/pending_bills/?${getSearchableString(
        getDirtyFields(dirtyFields, values)
      )}`,
      {},
      'GET'
    ).then((data) => {
      if (!data.errors) {
        store.setSingleValue('bills', data.results)
        if (!values.jurisdiction) return
        api(
          '/saved_search/pending_bills/',
          { jurisdiction: values.jurisdiction },
          'POST'
        )
          .then((data) => {
            if (!data.errors) {
              setSavedSearch(data.jurisdiction.slice(-5).reverse())
            }
          })
          .catch((err) => console.log(err))
      }
    })
  }

  useEffect(() => {
    if (!store.jurs) store.loadSelect('companyOptions')
    api('/saved_search/pending_bills/', {}, 'GET').then((data) => {
      if (!data.errors) {
        setSavedSearch(
          (data.jurisdiction && data.jurisdiction.slice(-5).reverse()) || []
        )
      }
    })
  }, [])

  useEffect(() => {
    getUniqueJurs()
  }, [store.jurs, savedSearch])

  if (!store.jurs) return null

  return (
    <Wrap>
      <div style={{ display: 'flex' }}>
        <Input
          control={control}
          name='name'
          placeholder='Search by Bill Name'
          validation={{ validate: () => true }}
          handleClear={handleSubmit(onSubmit)}
        />
        <Button
          text='Search'
          style={{ marginLeft: 16 }}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
      <div style={{ display: 'flex', minWidth: 500 }}>
        <Title style={{ paddingTop: 32, marginRight: 15 }}>Filter By:</Title>
        <Input
          control={control}
          name='jurisdiction'
          type='select'
          placeholder='Select'
          options={uniqueJurs}
          styleContainer={{ marginRight: 15 }}
          label='Jurisdiction'
          handleChange={handleSubmit(onSubmit)}
          smallLabel
          showSearch
        />
        <Input
          control={control}
          name='date'
          type='date_range'
          placeholder='Select a Date Range'
          dateRangeStyle={{ right: 0 }}
          label='Date Range'
          handleChange={handleSubmit(onSubmit)}
          reset={() => setValue('date', null)}
          smallLabel
          isSearchOpened
          noLimit
        />
      </div>
    </Wrap>
  )
})

export default Filters
