import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import Moment from 'react-moment'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { useStore } from '../../../../../mobx-store/context'
import ActivityModal from '../../ManageTeam/Modal'
import { Info, Wrap } from './style'

const Right = observer(({ control, onSubmit, loading, memberType }) => {
  const store = useStore()
  const [visible, setVisible] = useState(false)

  const member = store.member

  return (
    <Wrap>
      {visible && (
        <ActivityModal
          userId={member.id}
          userName={`${member.first_name} ${member.last_name}`}
          onClose={() => setVisible(false)}
        />
      )}

      <Info>
        <div>
          User:
          <span>
            {member?.first_name} {member?.last_name}
          </span>
        </div>
        <div>
          Joined:
          <span>
            <Moment format={'M/DD/YY'}>{member?.date_joined}</Moment>
          </span>
        </div>
        <div>
          Status:
          <span>{member?.is_active ? 'Active' : 'Inactive'}</span>
        </div>
      </Info>
      {member && (
        <ButtonOrange
          text={`View ${member.first_name}’s Activity Log`}
          style={{ margin: '10px 0' }}
          onClick={() => setVisible(true)}
        />
      )}
    </Wrap>
  )
})

export default Right
