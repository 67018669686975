import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Input from '../../../../components/common/Input'
import {
  getDescription,
  getJurisdictionCode,
} from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import List from '../../components/List'
import { Item } from '../../components/List/style'
import { Text } from '../../components/style'

const Left = observer(({ active, setActive, setLoading, loading }) => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const store = useStore()
  const { control, reset } = useForm()

  const isGuides = params.type === 'guides'

  const jurs = store.user?.[0]?.resource_jurisdictions.map((item) => ({
    id: item,
    name: getDescription(store.jurs, item),
  }))

  const resources = store.resources?.filter((item) =>
    store.user?.[0]?.resource_guides.includes(item.guide_name_id)
  )

  useEffect(() => {
    reset({
      topicJurs: +params.selectId,
    })
  }, [location])

  useEffect(() => {
    const items = isGuides
      ? store.resourcesCurrentTopic?.rows
      : store.resourceFiltered

    const activeItem = items?.find((item) => {
      if (isGuides) {
        return +item.jurisdiction === +params.jurisdictionID
      } else {
        return +item.id === +params.id
      }
    })

    if (
      activeItem &&
      (activeItem?.rows?.[0]?.requirements || activeItem?.requirements)
    )
      return setActive(activeItem)

    const newActiveItem = items?.find((item) => {
      return isGuides ? item.requirements : item?.rows?.[0]?.requirements
    })

    if (
      newActiveItem &&
      !activeItem?.rows?.[0]?.requirements &&
      !activeItem?.requirements
    ) {
      history.push(
        `/resource/guides-jurisdictions-list/${params.type}/${
          newActiveItem.id
        }/${params.selectId}/${params.jurisdictionID || ''}`
      )
    }

    setActive(
      isGuides
        ? +activeItem?.jurisdiction === +params.id && activeItem?.requirements
          ? activeItem
          : newActiveItem
        : +activeItem?.id === +params.id && activeItem?.rows[0].requirements
        ? activeItem
        : newActiveItem
    )
  }, [store.resourcesCurrentTopic])

  const getJurisdictions = () => {
    return (
      store.resourcesCurrentTopic?.rows
        .map((e) => e.jurisdiction)

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter((e) => store.resourcesCurrentTopic?.rows[e])
        .map((e) => store.resourcesCurrentTopic?.rows[e])
    )
  }

  const handleSetActive = (item) => {
    if ((!item.requirements && !item.rows[0].requirements) || loading) return
    setActive(item)
    setLoading(true)
    history.push(
      `/resource/guides-jurisdictions-list/${params.type}/${item.id}/${params.selectId}`
    )

    store.getResourceTopic(item.id, () => {
      setLoading(false)
    })
  }

  return (
    <div>
      <Input
        name={'topicJurs'}
        control={control}
        label={`Select a ${
          store.resourcesSelect?.type === 'guides'
            ? 'ReSource Guides'
            : 'Jurisdiction'
        }`}
        type={'select'}
        defaultValue={+params.selectId || null}
        allowClear={false}
        handleChange={(id) => {
          isGuides ? store.getResourceTopic(id) : store.getJurisdiction(id)
          history.push(
            `/resource/guides-jurisdictions-list/${params.type}/${params.id}/${id}/${params.jurisdictionID}`
          )
        }}
        options={isGuides ? resources : jurs}
        showSearch={params.type !== 'guides'}
      />
      <Text style={{ marginTop: 25 }}>
        Select a {isGuides ? 'Jurisdiction' : 'ReSource Guides'}
      </Text>
      <List
        options={
          isGuides
            ? getJurisdictions()?.map((item) => (
                <Item
                  key={item.jurisdiction}
                  active={item.jurisdiction === active?.jurisdiction}
                  id={'active'}
                  onClick={() => {
                    if (!item.requirements) return
                    if (+item.id !== +params.id) {
                      setActive(item)
                      history.push(
                        `/resource/guides-jurisdictions-list/${params.type}/${item.id}/${params.selectId}/${item.jurisdiction}`
                      )
                    }
                  }}
                  cursor={item.requirements}
                  disabled={!item.requirements}
                >
                  {getJurisdictionCode(item.jurisdiction, store, 1)}
                  <span
                    style={{
                      color: !item.requirements && '#bbb',
                      width: 80,
                      textAlign: 'center',
                    }}
                  >
                    {item.requirements ? 'Yes' : 'No'}
                  </span>
                </Item>
              ))
            : toJS(store.resourceFiltered)
        }
        active={active}
        setActive={handleSetActive}
        customOptions={isGuides}
        loading={loading}
      />
    </div>
  )
})

export default Left
