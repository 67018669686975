import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 100%;
  opacity: ${(props) => (props.disabled ? '.5' : 1)};
`

export const Label = styled('div')`
  font-size: ${(props) => (props.smallLabel ? '.875em' : '1.25em')};
  color: #455154;
  font-family: var(--medium);
  margin-bottom: 0.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: ${(props) => !props.required && 'none'};
    color: var(--orange);
  }
`

export const ErrorText = styled('div')`
  color: var(--orange);
  font-size: 12px;
`

export const CustomClose = styled('span')``

export const InputWrap = styled('div')`
  background: ${(props) =>
    props.error
      ? 'linear-gradient(0deg, rgba(242, 161, 34, 0.1), rgba(242, 161, 34, 0.1)), #FFFFFF'
      : props.success
      ? 'rgba(152, 195, 62, 0.1)'
      : '#fff'};
  position: relative;
  width: 100%;
  border-radius: 7px;
  min-height: 37px;
  border: 1px solid
    ${(props) =>
      props.error
        ? 'var(--orange)'
        : props.success
        ? 'var(--green)'
        : '#C4CACC'};
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: ${(props) => props.error && '0 !important'};
  font-family: var(--regular);

  &:hover {
    ${CustomClose} {
      opacity: 1 !important;
    }
  }

  textarea {
    border: 0;
    width: 100%;
    padding: 10px;
    outline: none;
    background: transparent;

    &::placeholder {
      color: ${(props) =>
        props.placeholderColor ? props.placeholderColor : 'var(--text)'};
      font-size: 16px;
      font-family: var(--regular);
      opacity: 0.6;
    }
  }

  input[type='file'] {
    position: absolute;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }

  input {
    width: 100%;
    background: transparent;
    height: 100%;
    min-height: 37px;
    border: 0;
    outline: none !important;
    padding: 0 1em;
    border-radius: 7px;
    font-family: var(--regular);

    &::placeholder {
      color: ${(props) =>
        props.placeholderColor ? props.placeholderColor : 'var(--text)'};
      font-size: 16px;
      font-family: var(--regular);
      opacity: 0.6;
    }
  }

  .ant-select-dropdown {
    z-index: 99 !important;
  }

  .test-select-option {
    div {
      color: red;
    }
  }

  .ant-select-clear {
    right: 30px;
  }

  .ant-mentions {
    height: 4.75em;
    background: transparent;
    border-radius: 7px;
    border: 0;

    textarea {
      height: 100% !important;
      min-height: 46px !important;

      .ant-mentions-measure {
        background-color: transparent;
      }
    }
  }

  .ant-mentions-focused {
    box-shadow: none !important;
  }

  .ant-picker {
    width: 100%;

    input {
      padding-left: 0;
      font-size: 1.1em;
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    min-height: 37px;
    display: flex;
    align-items: center;
    font-family: var(--regular);
    color: ${(props) =>
      props.placeholderColor ? props.placeholderColor : 'var(--text)'};
    font-size: 16px;
  }

  .ant-select,
  .ant-picker {
    border: 0 !important;
    min-height: 37px;
  }

  .ant-select-selector,
  .ant-picker {
    min-height: 37px;
  }

  .ant-picker {
    height: 37px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input),
  .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
  }

  .ant-select-selector {
    background-color: #fff;
    border: 0 !important;
    font-family: var(--open-sans-reg);
  }

  .ant-select-selection-placeholder {
    color: ${(props) =>
      props.placeholderColor
        ? props.placeholderColor
        : props.transparent
        ? 'var(--text)'
        : 'var(--text)'};
    font-family: var(--regular);
    opacity: 0.6;
    font-size: 1.1em;
  }

  .ant-select-multiple .ant-select-selection-item {
    border: 0;
    background-color: transparent;
    color: ${(p) =>
      p.multiSelectItemColor ? p.multiSelectItemColor : 'var(--green)'};
    font-family: var(--medium);

    .anticon.anticon-close {
      color: ${(p) =>
        p.multiSelectItemColor ? p.multiSelectItemColor : 'var(--green)'};
    }
  }

  .ant-select-arrow {
    background-color: ${(props) =>
      props.colorArrow ? props.colorArrow : 'transparent'};
    color: ${(props) => props.colorArrow && '#fff'};
    height: calc(100% + 2px);
    top: 5px;
    right: -1px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
  }
`

export const Icon = styled('div')`
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--grey);
`

export const File = styled('div')`
  width: 100%;
  color: var(--text);
  font-size: 1em;
  padding-left: 1em;
`

export const Row = styled('div')`
  display: flex;
  width: 100%;
  position: relative;
`

export const ValidationIcon = styled('img')`
  width: 14px;
  margin-top: ${(props) =>
    props.smallLabel ? '2.3em' : props.label ? '3em' : '.8em'};
  margin-left: 0.5em;
  position: ${(props) => (props.relativeIcon ? 'relative' : 'absolute')};
  right: ${(props) => (props.relativeIcon ? 'auto' : '-20px')};
  display: none;
`

export const TextInputWrap = styled('div')`
  display: flex;
  width: 100%;
  input {
    padding-right: 35px;
  }
  ${CustomClose} {
    display: none;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    svg {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  &:hover {
    ${CustomClose} {
      display: flex;
    }
  }
`
