import React from 'react'
import { checkRole } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { Item, Wrap } from './tabs.style'

const Tabs = ({ tabs, active, onClick, isResource }) => {
  const store = useStore()
  const user = store.user?.[0]

  return (
    <Wrap isResource={isResource}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex' }}>
          {tabs.map(
            (item) =>
              !item.disabledFor?.includes(store.user?.[0]?.member_type) && (
                <Item
                  key={item.key}
                  active={active === item.key}
                  onClick={() => onClick(item.key, item.name)}
                >
                  {item.title}
                </Item>
              )
          )}
        </div>
        {checkRole([user?.member_type], ['admin', 'compliance_owner']) &&
          !isResource && (
            <Item
              active={active === 'reporting'}
              onClick={() => onClick('reporting', 'reporting')}
              style={{ marginRight: 0 }}
            >
              Reporting
            </Item>
          )}
      </div>
    </Wrap>
  )
}

export default Tabs
