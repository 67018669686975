import { message, Spin } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import Validation from '../../../../components/common/Validation/validation'
import {
  checkRole,
  getDirtyFields,
  getError,
} from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { PageTitle, Row } from '../../components/common/style'
import PageWrap from '../../components/Container'
import Form from './form'
import List from './list'
import { Wrap } from './style'

const EditProfile = observer(() => {
  const store = useStore()
  const { control, handleSubmit, reset, errors, formState } = useForm({
    defaultValues: {
      first_name: '',
    },
  })

  useEffect(() => {
    reset(store.user[0])
  }, [store.user])

  const text = (
    <div style={{ color: 'var(--blue)' }}>
      To edit settings and information for your business, please see{' '}
      <Link
        to={'/product-settings'}
        style={{ color: 'var(--orange)', textDecoration: 'underline' }}
      >
        Product Settings
      </Link>
    </div>
  )

  const { dirtyFields } = formState

  const onSubmit = (values) => {
    const modifiedDirtyFields = getDirtyFields(dirtyFields, values)

    if (modifiedDirtyFields) {
      api(`/users/${store.user[0].id}/`, modifiedDirtyFields, 'PATCH').then(
        (data) => {
          if (!data.errors) {
            message.success('Profile changed')
            store.setSingleValue('user', [data])
          } else {
            reset(store.user[0])
            message.error(getError(data))
          }
        }
      )
    }
  }

  return (
    <PageWrap>
      {!store.company.length || !store.user.length ? (
        <Spin />
      ) : (
        <>
          <Wrap>
            <PageTitle>Your Profile</PageTitle>
            {store.company[0]?.payment_status === 'trial_is_over' && (
              <Validation
                upperText={`Trial period ended`}
                text={''}
                styleUpperText={{ marginBottom: 0 }}
              />
            )}
            <Row style={{ marginTop: 30, alignItems: 'flex-start' }}>
              <Form control={control} errors={errors} />
              <List />
            </Row>
            {checkRole([store?.user[0]?.member_type], ['admin']) && (
              <Validation noLineThrough style={{ marginTop: 37 }} text={text} />
            )}
            <Row style={{ justifyContent: 'flex-end', marginTop: 117 }}>
              <Button
                type={'green'}
                text={'Save Profile'}
                onClick={handleSubmit(onSubmit)}
              />
            </Row>
          </Wrap>
        </>
      )}
    </PageWrap>
  )
})

export default EditProfile
