import styled from 'styled-components'
import { TableWrap as Table } from '../ResourceTable/style'

export const Layout = styled('div')`
  display: grid;
  grid-template-columns: 450px 815px;
  gap: 50px;
  margin-top: 30px;
  padding-bottom: 50px;
`

export const Text = styled('div')`
  font-size: 16px;
  font-family: var(--regular);
  color: var(--text);
`

export const TableWrap = styled(Table)`
  .ant-table-body {
    padding-right: 0;
    width: calc(100% + 10px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 10px;
      position: absolute;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bbb;
      border-radius: 5px;
    }
  }

  .ant-table-cell {
    border-right: 1px solid #c4cacc;
  }

  tbody {
    tr {
      .ant-table-cell {
        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  thead {
    .ant-table-cell {
      border-top: 1px solid #c4cacc;

      &:first-child {
        border-left: 1px solid #c4cacc;
        border-top-left-radius: 4px !important;
      }

      &:last-child {
        border-top-right-radius: 4px !important;
      }
    }
  }
`
