import { Select } from 'antd'
import { checkRole } from '../../../helpers/helpers'

export const inputs = (store, totals) => [
  {
    key: 1,
    name: 'jurisdiction',
    label: 'Jurisdiction',
    placeholder: 'Select',
    options: store.legislative_options?.jurisdictions,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 0,
    name: 'assignee',
    label: 'Compliance Owner',
    placeholder: 'All (Including Unassigned)',
    customOptions: store.members
      .filter((item) => {
        return (
          item.is_active &&
          item.have_access_to_tracking &&
          checkRole([item.member_type], ['admin', 'compliance_owner', 'owner'])
        )
      })
      .map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      )),
  },

  {
    key: 2,
    name: 'topic',
    label: 'Topics',
    placeholder: 'Select',
    options: store.legislative_options?.topics,
    showDeprecatedLabel: true,
    showDeprecated: true,
    onlyEnabled: true,
  },
  {
    key: 3,
    name: 'line_of_business',
    label: 'Line of Business',
    placeholder: 'Select',
    options: store.legislative_options?.line_of_business,
    showDeprecatedLabel: true,
    showDeprecated: true,
    isHidden: !store.company?.[0]?.is_premium,
  },
  {
    key: 3,
    name: 'status',
    label: 'Status',
    placeholder: 'Select',
    options: [
      {
        id: 'unassigned',
        name: 'Unassigned',
      },
      {
        id: 'in_progress',
        name: 'In Progress',
      },
      {
        id: 'all_tasks_completed',
        name: 'All Tasks Completed',
      },
      {
        id: 'closed',
        name: 'Closed',
      },
    ],
  },
  {
    key: 4,
    name: 'today_proximity',
    label: 'Closed Date Range',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: 30,
        name: '30 Days',
      },
      {
        id: 60,
        name: '60 Days',
      },
      {
        id: 90,
        name: '90 Days',
      },
      {
        id: 'last_quarter',
        name: 'Last Quarter',
      },
      {
        id: 'custom',
        name: 'Custom',
      },
    ],
  },
  {
    key: 4,
    name: 'is_starred',
    label: 'Priority',
    placeholder: 'Select',
    type: 'select',
    options: [
      {
        id: null,
        name: 'All',
      },
      {
        id: 'yes',
        name: 'Yes',
      },

      {
        id: 'no',
        name: 'No',
      },
    ],
  },
]
