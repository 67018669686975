import { Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import closeIcon from '../../../../../assets/images/close-grey.svg'
import deleteFileIcon from '../../../../../assets/images/delete-orange.svg'
import Button from '../../../../../components/common/Button/button'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import CalendarCustom from '../../../../../components/common/Calendar'
import Input from '../../../../../components/common/Input'
import { checkRole } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import { Cancel, Overlay, Row, Title, Wrap } from './style'

const Modal = ({
  control,
  loadingDueDate,
  onClose,
  onSubmit,
  errors,
  complete,
  taskID,
  tasks,
  loadingFile,
  complianceItem,
  redCircleDate,
  setTemporaryFiles,
  temporaryFiles,
  loading,
  activeTab,
}) => {
  const history = useHistory()
  const params = useParams()
  const store = useStore()
  const [taskFiles, setFiles] = useState()

  const disabled = !complete && store.user?.[0]?.member_type === 'member'

  const handleClose = () => {
    if (activeTab === 'myTasks') {
      history.push(`/tracking/myTasks?compliance_item=${complianceItem}`)
    } else {
      history.push(`/tracking/main?compliance_item=${complianceItem}`)
    }

    onClose()
  }

  const addFile = (e) => {
    setTemporaryFiles([...temporaryFiles, ...e.target.files])
  }

  const deleteFile = (name) => {
    const filtered = temporaryFiles.filter((item) => item.name !== name)
    setTemporaryFiles(filtered)
  }

  useEffect(() => {
    if (tasks.length > 0) {
      setFiles(getFiles(tasks, taskID))
    }
  }, [tasks])

  return (
    <>
      <Overlay onClick={onClose} />
      <Wrap>
        <img
          src={closeIcon}
          alt='close-icon'
          onClick={handleClose}
          id='close'
        />
        <Title>{complete ? 'Complete a Task:' : 'Assign a Task:'}</Title>
        {!complete && (
          <Row>
            <Input
              type={'select'}
              label={'Task Owner'}
              validation={{ required: true }}
              control={control}
              disabled={disabled}
              dropdownStyle={{ position: 'fixed', zIndex: 9999999 }}
              name={'assignee'}
              showSearch
              customOptions={store.members
                .filter((item) => item.have_access_to_tracking)
                .map(
                  (item) =>
                    item.is_active && (
                      <Select.Option value={item.id} key={item.id}>
                        {item.first_name + ' ' + item.last_name}
                      </Select.Option>
                    )
                )}
              smallLabel
              error={errors?.assignee && 'This field is required'}
            />
            <Controller
              control={control}
              name={'due_date'}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <CalendarCustom
                  handleChange={(value) =>
                    onChange(moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                  }
                  incomeValue={value}
                  loading={loadingDueDate}
                  disabled={disabled}
                  required
                  label={'Task Due Date'}
                  error={errors?.due_date && 'This field is required'}
                  redCircleDate={redCircleDate}
                />
              )}
            />
          </Row>
        )}

        <Input
          type={'text'}
          label={complete ? 'Task Comment' : 'Task Description'}
          validation={{
            required: true,
          }}
          control={control}
          disabled={disabled}
          name={'description'}
          smallLabel
          error={errors?.description && 'This field is required'}
        />
        {complete && (
          <Input
            type={'file'}
            name={'file'}
            label={'Upload Document'}
            control={control}
            styleRow={{ marginTop: 10 }}
            handleChange={(e) => addFile(e)}
            loading={loadingFile}
            multiple
            smallLabel
          />
        )}

        {temporaryFiles.map((item, index) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ButtonOrange text={item.name} key={index} />
            <img
              src={deleteFileIcon}
              alt={'delete-icon'}
              onClick={() => deleteFile(item.name)}
              style={{ marginLeft: 5, cursor: 'pointer' }}
            />
          </div>
        ))}

        {complete &&
          taskFiles?.map((item, index) => {
            return (
              <>
                <ButtonOrange
                  text={item.name}
                  key={index}
                  link={item.link}
                  defaultLink
                />
              </>
            )
          })}
        {(complete ||
          !checkRole([store.user?.[0]?.member_type], ['member'])) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 15,
            }}
          >
            <Cancel onClick={handleClose}>Cancel</Cancel>
            <Button
              text={complete ? 'Complete a Task' : 'Assign a Task'}
              onClick={onSubmit}
              loading={loading}
            />
          </div>
        )}
      </Wrap>
    </>
  )
}

export default Modal

export const getFiles = (tasks, taskID) => {
  let files = tasks.find((item) => +item.id === +taskID)?.files

  if (files?.length > 0) {
    return files.map((item) => {
      const splitedString = item.file_name.split('/')
      return {
        name: splitedString[splitedString.length - 1],
        link: item.file,
        id: item.id,
      }
    })
  }
}
