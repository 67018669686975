import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../../../../components/common/Button/button'
import Switch from '../../../../../components/common/Switch/switch'
import { useStore } from '../../../../../mobx-store/context'
import { Bottom } from '../../../../Auth/authForm.style'
import TabsNav from '../../../components/TabsNav/tabsNav'
import ComplianceTab from '../Form/complianceTab'
import { ButtonsWrap } from '../Form/form.style'
import ResourceTab from '../Form/resourceTab'

const Tabs = ({ control, loading, onSubmit, memberType, register, errors }) => {
  const [active, setActive] = useState('tracking')
  const params = useParams()
  const history = useHistory()
  const store = useStore()
  const [showFields, setShowField] = useState(false)

  const tabsProps = {
    control,
    showFields,
    memberType,
    register,
    active,
    errors,
  }

  const handleShowField = (value) => {
    if (value !== 'member') setShowField(true)
  }

  const tabs = {
    tracking: {
      button: 'ComplianceSource',
      link: '/add-team-members-profile/tracking',
      component: (
        <ComplianceTab {...tabsProps} handleShowField={handleShowField} />
      ),
    },
    resource: {
      button: 'ReSource',
      link: '/add-team-members-profile/resource',
      component: <ResourceTab {...tabsProps} />,
    },
  }

  useEffect(() => {
    if (active === 'resource') {
      setShowField(true)
    }
  }, [active])

  useEffect(() => {
    setActive(params.type || 'tracking')
  }, [params.type])

  return (
    <>
      <TabsNav
        tabs={tabs}
        active={active}
        styleWrap={{ marginTop: 30 }}
        onRedirectTo={(page) =>
          history.push(`/add-team-members-profile/${page}`)
        }
      />
      {tabs[active].component}
      <Bottom
        style={{
          maxWidth: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <ButtonsWrap>
          {active === 'tracking' && memberType.tracking !== 'member' && (
            <Switch
              text={
                <p style={{ fontSize: 14, marginRight: 15 }}>
                  Assign team member based on Topic, Jurisdiction, Line of
                  Business
                </p>
              }
              onChange={() => setShowField(!showFields)}
              active={showFields}
            />
          )}

          <Button
            text={'+Add to team'}
            style={{
              backgroundColor: '#91C03E',
              padding: '0 .5em',
              marginLeft: 'auto',
            }}
            loading={loading}
            disabled={loading}
            onClick={onSubmit}
          />
        </ButtonsWrap>
      </Bottom>
    </>
  )
}

export default Tabs
