import React from 'react'
import { useParams } from 'react-router-dom'
import Button from '../../../../../../components/common/Button/button'
import { Label } from '../../../../../../components/common/Input/input.style'
import Modal from '../../../../../../components/common/ModalNew'
import { Cancel } from '../../../../../Tracking/Right/Tasks/Modal/style'
import { varTypes } from '../static'
import { Row, Text } from './style'

const AccessModal = ({ onConfirm, onClose, member }) => {
  const params = useParams()

  const isResource = params.type === 'resource'

  const handleConfirm = () =>
    onConfirm(varTypes[params.type || 'tracking'].variable, false)

  return (
    <Modal onClose={onClose}>
      <Label>
        Remove {member.first_name} {member.last_name} From
        {isResource ? ' ReSource' : ' ComplianceSource'}?
      </Label>
      <Text>
        If you remove them from {isResource ? ' ReSource' : ' ComplianceSource'}{' '}
        you can still add them at a later time.
      </Text>
      <Row>
        <Cancel onClick={onClose}>Cancel</Cancel>
        <Button text={'Remove User'} onClick={handleConfirm} />
      </Row>
    </Modal>
  )
}

export default AccessModal
