import React from 'react'
import { Row, Wrap } from './topic.style'
import { Title } from '../authForm.style'
import Form from './Form/form'
import Logo from '../../../components/common/Logo/logo'
import SelectedPlan from '../../../components/common/SelectedPlan/selectedPlan'
import StatusBar from '../../../components/common/StatusBar/status'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../mobx-store/context'

const TopicAndJur = observer(() => {
  const store = useStore();

  const tariffs = {
    topic_tract: "TopicTrack",
    juris_tract: "JurisTrack",
    total_tract: "TotalTrack",
    select_tract: "SelectTrack",
  };

  return (
    <>
      <Row>
        <div>
          <Logo large />
          <StatusBar style={{ marginTop: "1em" }} step={4} />
        </div>
        <SelectedPlan />
      </Row>

      <Wrap>
        <Title>
          Select Your Options{" "}
          {store.company.length > 0 && (
            <span>
              {tariffs[store.company[0].tariff]}{" "}
              {store.company[0].is_premium && "PREMIUM"}
            </span>
          )}
        </Title>
        <Form />
      </Wrap>
    </>
  );
});

export default TopicAndJur;
