import React from 'react'
import { Container, Grid } from './style'
import Menu from '../Menu'

const PageWrap = ({ children, showAdding, gridStyle, style }) => {
  return (
    <Container style={style}>
      <Grid style={gridStyle}>
        <Menu showAdding={showAdding}/>
        <div style={{ paddingLeft: '50px' }}>{children}</div>
      </Grid>
    </Container>
  )
}

export default PageWrap
