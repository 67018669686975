import React from 'react'
import Moment from 'react-moment'
import closeOrange from '../../../../../../assets/images/cross-orange.svg'
import { statuses } from '../../../../../Tracking/Table/table'
import { Date, Image, Status, Title, Wrap } from './style'

const Item = ({ data, index, onDelete }) => {
  return (
    <Wrap
      style={{ backgroundColor: index % 2 === 0 && 'rgba(34, 81, 148, 0.05)' }}
    >
      <Status style={{ backgroundColor: statuses[data.status]?.[1] }} />
      <Title to={`/tracking/main/${data.id}?assignee=${data.assignee}`}>
        {data.reference_number}
      </Title>
      <Date>
        Assigned <Moment format={'M/DD/YY'}>{data.assigned_date}</Moment>
      </Date>
      <Image
        src={closeOrange}
        alt={'cross-icon'}
        onClick={() => onDelete(data.id)}
      />
    </Wrap>
  )
}

export default Item
