import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 100%;
  //padding: 0 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  border-bottom: 1px solid
    ${(p) => (p.isResource ? '#fff' : 'rgba(0, 0, 0, 0.2)')};
`

export const Title = styled('div')`
  font-family: var(--sf-d-bold);
  font-size: 0.875em;
  color: #000;
  margin-right: 0.75em;
`

export const Item = styled('div')`
  height: 1.75em;
  display: flex;
  align-items: center;
  padding: 0 0.875em;
  border-radius: 6px 6px 0 0;
  border: 1px solid #c4cacc;
  color: ${(props) => (props.active ? 'var(--text)' : 'var(--grey)')};
  font-family: ${(props) =>
    props.active ? 'var(--semiBold)' : 'var(--light)'};
  border-bottom: 0;
  cursor: pointer;
  margin-right: 0.5em;
  background-color: var(--light-grey);
`
