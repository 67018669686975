import styled from 'styled-components'

export const Row = styled('div')`
  display: flex;
  align-items: center;
`

export const PageTitle = styled('div')`
  font-family: var(--light);
  font-size: 2.5em;
  color: var(--text);
`

export const Title = styled('div')`
  font-family: var(--medium);
  font-size: 1.25em;
  color: var(--text);
`
