import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Wrap = styled('div')`
  width: 100%;
  background: rgba(34, 81, 148, 0.8);
  border-radius: 8px 0 0 8px;
  padding: 15px 0 15px 20px;
  height: 540px;
  position: sticky;
  top: 100px;
`

export const Title = styled('div')`
  display: flex;
  align-items: center;
  font-family: var(--semiBold);
  color: #fff;
  font-size: 1.25em;
  letter-spacing: -1.25px;
  margin-bottom: 1.5em;

  img {
    margin-right: 10px;
  }
`

export const Item = styled(NavLink)`
  padding-left: 10px;
  font-size: 1em;
  height: 2.188em;
  display: flex;
  align-items: center;
  color: #fff;
  font-family: var(--bold);
  border-radius: 6px 0 0 6px;
  white-space: nowrap;

  span {
    margin-right: 5px;
  }

  &:hover {
    color: #fff;
  }

  &[disabled] {
    color: #fff;
    opacity: 0.5;
  }
`
