import React from 'react'
import { InfoWrap } from './style'
import PlainText from '../../../../components/common/PlainText'

const Info = ({ leftData = [], rightData = [], style }) => {
  return (
    <InfoWrap style={style}>
      <div>
        {leftData.map((item) => (
          <PlainText key={item.key}>
            <span>{item.title}:</span> {item.text}
          </PlainText>
        ))}
      </div>
      <div>
        {rightData.map((item) => (
          <PlainText key={item.key}>
            <span>{item.title}:</span> {item.text}
          </PlainText>
        ))}
      </div>
    </InfoWrap>
  );
};

export default Info;
