import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../api'
import placeholderImage from '../../assets/images/legislatives-palceholder.svg'
import FullScreenLoader from '../../components/common/FullScreenLoader'
import Tabs from '../../components/common/Tabs/tabs'
import Container from '../../components/containers/container'
import {
  checkForRegisterPassed,
  redirectToUnfilledPage,
} from '../../helpers/helpers'
import { useStore } from '../../mobx-store/context'
import Auth from './Auth/auth'
import CreateNewComplianceItem from './CreateNew'
import Left from './Left/left'
import { Title } from './Left/left.style'
import Reporting from './Reporting'
import Right from './Right/right'
import { NotChoosen } from './Right/right.style'
import { Top, Wrap } from './tracking.style'

const tabs = [
  {
    key: 'main',
    title: 'Tracking',
    disabledFor: ['member'],
  },
  { key: 'myTasks', title: 'My Tasks' },
  { key: 'closed', title: 'Closed' },
]

const urls = {
  default: 'page_size=20&actual=actual',
  closed: 'page_size=20&status=closed',
}

const ending = {
  main: `get_page_for=legislatives`,
  myTasks: `get_page_for=tasks`,
  closed: `get_page_for=legislatives`,
}

const Tracking = observer(() => {
  const store = useStore()
  const history = useHistory()
  const params = useParams()

  const [active, setActive] = useState('main')
  const [currentLegislativeLoading, setCurrentLegislativeLoading] =
    useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [visible, setVisible] = useState(false)
  const [editing, setEditing] = useState(false)

  const loading = false
  const auth = false
  const company = store.company?.[0]
  const user = store.user?.[0]

  let search = new URL(document.location).searchParams
  let complianceItem = search.get('compliance_item')

  const isMyTasks =
    active === 'myTasks' ||
    (store.user?.[0]?.member_type === 'member' && active !== 'closed')

  const getCurrentLegislative = (id, onLoad, onError, isClicked) => {
    api(
      `/legislative/${id}/?${
        isClicked
          ? urls[active === 'closed' ? 'closed' : 'default']
          : ending[active]
      }`,
      {},
      'GET'
    ).then((data) => {
      if (!data.errors) {
        if (data.status === 'closed') {
          history.push(`/tracking/closed/?compliance_item=${data.id}`)
          setActive('closed')
        }

        onLoad && onLoad(data)
        store.setCurrentLegislative(data)
        setCurrentPage(data.page || currentPage || 1)
      } else {
        onError && onError()
        store.setCurrentLegislative(null)
      }
      setCurrentLegislativeLoading(false)
    })
  }

  const getData = () => {
    setCurrentStatus()
    if (checkForRegisterPassed(store.company[0]) && store.user.length > 0) {
      if (params.id || complianceItem) {
        getCurrentLegislative(
          params.id || complianceItem,
          (data) => {
            const status =
              data.status === 'closed'
                ? { status: 'closed' }
                : { actual: 'actual' }
            store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
              page: data.page,
              page_size: 20,
              onError: () => {
                store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
                  page: 1,
                  page_size: 20,
                  ...status,
                })
              },
              ...status,
            })
          },
          () => {
            store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
              page: 1,
              page_size: 20,
            })
          }
        )
      } else {
        if (isMyTasks) {
          store[active === 'closed' ? 'loadLegislatives' : 'loadTasks']({
            page: 1,
            page_size: checkForTrial() ? 10 : 20,
          })
        } else {
          store.loadLegislatives({
            page: 1,
            page_size: checkForTrial() ? 10 : 20,
          })
        }
      }
    }
  }

  useEffect(() => {
    if (store.company.length && checkForRegisterPassed(store.company[0])) {
      store.loadSelect('companyOptions')
    } else {
      redirectToUnfilledPage(history, store)
    }
  }, [])

  const checkForTrial = () =>
    store.company.length > 0 && store.company[0].payment_status === 'trial'

  const archived = active === 'closed'

  const setCurrentStatus = () => {
    if (archived) {
      store.setSingleValue('legislativesStatus', 'closed')
    } else {
      if (store.searchableObject?.status === 'closed') {
        store.setSingleValue('legislativesStatus', 'closed')
      }
    }
  }

  useEffect(() => {
    if (store.user?.[0]?.member_type === 'member') setActive('myTasks')
    if (store.user[0] && !store.user?.[0]?.have_access_to_tracking)
      history.push('/edit-profile')
  }, [store.user])

  useEffect(() => {
    getData()
  }, [active, store.user])

  useEffect(() => {
    if (isMyTasks) setActive('myTasks')
  }, [isMyTasks])

  useEffect(() => {
    if (params.tabName && active !== params.tabName) setActive(params.tabName)
    if (params.tabName && !tabs.find((item) => item.key === params.tabName)) {
      setActive('main')
      history.push('/tracking/main')
    }
  }, [])

  useEffect(
    () => () => {
      store.setSingleValue('currentLegislative', null)
    },
    []
  )

  useEffect(() => {
    const statuses = ['trial_is_over', 'error', 'canceled']
    if (statuses.includes(company.payment_status)) {
      history.push(
        `/company_error/${
          company.payment_status === 'trial_is_over'
            ? company.payment_status
            : 'payment_' + company.payment_status
        }`
      )
    }
  }, [company])

  const onTabChange = (key) => {
    history.push(`/tracking/${key}`)

    store.setSingleValue('currentLegislative', null)
    store.setSingleValue('legislatives', null)
    store.setSingleValue('tasks', null)

    if (key === 2) {
      store.setSingleValue('legislativesStatus', 'closed')
    } else {
      store.setSingleValue('legislativesStatus', 'actual')
    }
  }

  if (loading || !store.user[0]) return <FullScreenLoader />

  return (
    store.company.length > 0 &&
    checkForRegisterPassed(store.company[0]) &&
    !loading &&
    user.have_access_to_tracking && (
      <Container wide>
        <Top fullWidth>
          <Title>ComplianceSource</Title>
          <Tabs
            tabs={tabs}
            active={active}
            onClick={(key) => {
              if (key === active) return null
              onTabChange(key)
              setActive(key)
            }}
          />
        </Top>
        {active === 'reporting' ? (
          <Reporting />
        ) : (
          <Wrap>
            <>
              {store.user.length > 0 && (
                <Left
                  auth={auth}
                  archived={active === 'closed'}
                  active={active}
                  setActive={(key) => setActive(key)}
                  changeTab={(key) => setActive(key)}
                  getCurrentLegislative={(id) =>
                    getCurrentLegislative(id, null, null, true)
                  }
                  currentLegislativeLoading={currentLegislativeLoading}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isMyTasks={isMyTasks}
                  complianceItem={complianceItem}
                  onCreateNew={() => setVisible(true)}
                />
              )}

              {auth ? (
                <Auth />
              ) : visible ? (
                <CreateNewComplianceItem
                  onClose={() => {
                    setVisible(false)
                    setEditing(false)
                  }}
                  editing={editing}
                  getCurrentLegislative={(id) =>
                    getCurrentLegislative(id, (data) => {
                      store.loadLegislatives({
                        page: data.page,
                        page_size: 20,
                      })
                    })
                  }
                />
              ) : store.currentLegislative ? (
                <Right
                  data={toJS(store.currentLegislative)}
                  getCurrentLegislative={getCurrentLegislative}
                  currentPage={currentPage}
                  isMyTasks={isMyTasks}
                  complianceItem={complianceItem}
                  activeTab={active}
                  onEdit={() => {
                    setVisible(true)
                    setEditing(true)
                  }}
                />
              ) : (
                <NotChoosen>
                  <img src={placeholderImage} alt='placeholder' />
                  Select a Compliance Item to <br /> Begin
                </NotChoosen>
              )}
            </>
          </Wrap>
        )}
      </Container>
    )
  )
})

export default Tracking
